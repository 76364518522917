.mt5cont {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 350px;
    padding-top: 85px;
    background-color: #fcfcfc;
      background-image: url(../images/bgimgmt5.png);
  }
  section.mt5trade {
    padding: 0;
}
  .mt5cont img.img-fluid {
    width: 100%;
    max-width: 455px;
    float: right;
    height: 100%;
  }

  .assets-banner p{
    color: gray;
  }
  .assets-banner a button {
    background-color: #f18c05;
    width: 100%;
    max-width: 200px;
    color: white;
    font-size: 18px;
    font-weight: 600;
    border: none;
  }

section.assets-content-page {
    padding-top: 0;
    padding-bottom: 0px !important;
}
.asset-content1.mt-4.pt-3 div#v-pills-tab {
    background: #fafafa;
    border-radius: 4px;
    width: 100%;
    padding: 10px 10px;
}

.asset-content1.mt-4.pt-3 a {
    color: black;
    font-size: 15px;
    font-weight: 600;
    border-radius: unset !important;
    margin: 2px 0;

}

.asset-content1.mt-4.pt-3 a.active {
    color: white;
    background: none;
    font-size: 15px;
    font-weight: 600;
    background-color: #f18c20;
    border-radius: 4px !important;
}

.asset-content-box1.mt-4.pt-3 div#v-pills-tab {
    background: #fafafa;
    border-radius: 4px;
    width: 100%;
    padding: 10px 10px;
}

.asset-content-box1.mt-4.pt-3 a {
    color: black;
    font-size: 15px;
    font-weight: 600;
    border-radius: unset !important;
    margin: 2px 0;

}

.asset-content-box1.mt-4.pt-3 a.active {
    color: white;
    background: none;
    font-size: 15px;
    font-weight: 600;
    background-color: #f18c20;
    border-radius: 4px !important;
}

.currency-asset1 i {
    padding: 3px 15px;
    color: #f18c20;
    font-size: 15px;
}

.currency-asset1 small {
    text-indent: 0px;

}

.currency-asset1 p {
    display: flex;
}

/*flag page */
section.assets-flag-page {
    padding: 0;
}

.assets-icons-page .rounded-circle {
    border-radius: unset !important;
    display: flex;
    align-items: center;
    padding: 15px;
}

.assets-icons-page .testimonial-card .card-up {
    height: 120px;
    overflow: hidden;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
}

.assets-icons-page .aqua-gradient {
    background: url(../images/mani/chart1.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

}

.assets-icons-page .testimonial-card .avatar {
    width: 80px;
    margin-top: -40px;
    overflow: hidden;
    border-radius: 50%;
    height: 80px;
    background: white;
    box-shadow: 0px 0px 10px 0px #e7e7e7;
    padding: 15px;
}

.assets-icons-page .card.testimonial-card.mt-2.mb-3 {
    border: none;
    background: white !important;
    transition: .7s ease-in-out;
    margin: 30px 0 !important;
    box-shadow: -5px -5px 9px rgb(245 245 245), 5px 5px 9px rgb(94 104 121 / 30%);
}

.assets-icons-page .card.testimonial-card.mt-2.mb-3:hover {
    transform: scale(1.1);
    transition: .7s ease-in-out;
}

/*flag page end */

/*yuva style get started */

.trade .box1get h4 {
    font-size: 17px;
    font-weight: 600;
}

.trade .box1get p {
    color: gray;
}

.trade .getsbtn {
    gap: 104px;
}

.trade .getimg img.img-fluid {

    position: relative;
    right: -65px;
}

.trade .openlivebtn button.btn.btn-primary {

    background-color: #f18c20;
    border: none;
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}

.trade .opendemobtn button.btn.btn-primary {
    background-color: #909090;
    border: none;
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}

.trade .box1get img.img-fluid {
    max-width: 50px;
    max-height: 50px;
    width: 100%;
}

section.trade.getmt5.mt-4.pb-5 {
    padding: 0;
}

/*get started end */

.indices1 i {
    padding: 3px 15px;
    color: #f18c20;
    font-size: 15px;
}

.indices1 small {
    text-indent: 0px;

}

.indices1 p {
    display: flex;
}

.share-image img {
    width: 100%;
    max-width: 85%;
}

.cryptocurrencies i {
    padding: 3px 15px;
    color: #f18c20;
    font-size: 15px;
}

.cryptocurrencies small {
    text-indent: 0px;

}

.cryptocurrencies p {
    display: flex;
}

.currency-service i {
    padding: 3px 15px;
    color: #f18c20;
    font-size: 15px;
}

.currency-service small {
    text-indent: 0px;

}

.people-currency i {
    padding: 3px 15px;
    color: #f18c20;
    font-size: 15px;
}

.people-currency small {
    text-indent: 0px;

}

.currency-service p {
    display: flex;
}

/* market trade */
.product1.markettab .nav-tabs .nav-link {

    border: unset;
    font-size: 14px;
    font-weight: 600;
    color: black;
    padding: 0 5px;
    margin-bottom: 15px;
}
/* .product1.markettab.pb-5.mb-5.mt-4 {
    width: 200px;
} */
.product1.markettab .nav-tabs {
    border-bottom: unset !important;

}

.product1.markettab .nav-tabs .nav-link.active {
    color: #f18c20;
}

.product1.markettab table tr td {
    font-size: 12px;
    padding: 9px 3px;
    text-align: center;
}

section.assets-content-page .coinname a {
    color: black !important;
    font-weight: 500;
}

section.assets-content-page .val2 a {
    color: red !important;
}

section.assets-content-page .val1 a {
    color: black !important;
}

section.assets-content-page .val3 a {
    color: green !important;
}

section.assets-content-page .val4 a {
    color: red !important;
}

section.assets-content-page .val5 a {
    color: green !important;
}

section.assets-content-page a.optrade.btn {
    background-color: #f18c20;

    color: white;
    text-align: center;
    font-size: 14px;
    width: 100%;
    font-weight: 500;
}

section.assets-content-page a.downtrade.btn {
    background-color: #f18c20;

    color: white;
    text-align: center;
    font-size: 14px;
    width: 100%;
    white-space: nowrap;
    font-weight: 500;
}

section.assets-content-page .curre img.img-fluid {
    height: 200px;

    width: 100%;
    max-width: 348px;
}

section.assets-content-page .exchnage h6 {
    font-size: 14px;
}

section.assets-content-page .exchnage {
    border-bottom: 1px solid #f18c20;
}

.product1 .markettab table tr:hover {
    background-color: #eaefec;
}
.product1 .marketanlysec3 table tr td {
    padding: 2px 8px !important;
    text-align: center;
    border-bottom: 1px solid rgb(215, 214, 214) !important;
    font-size: 15px;
}

section.assets-content-page .exchnage1 h6 {
    font-size: 14px;
}

.product1 .traditems img.img-fluid {
    width: 100%;
    max-width: 30px;
}

.product1 .marketanlysec3 i.fa-brands.fa-btc {
    color: orange;
}

.product1 .marketanlysec3 i.fas.fa-long-arrow-alt-down {
    color: green;
}

.tradingins p {
    color: gray;
    font-size: 14px;
}

.product1 .tradingins li {
    list-style: none;
    font-size: 14px;
    white-space: nowrap;
    padding: 12px 0;
    text-indent: -39px;
}

.product1.markettab table tr td {
    font-size: 12px;
    padding: 9px 3px;
    text-align: center;
    white-space: nowrap;
}

/* market trade end */
.commodity-trade.mt-2 i {
    color: #f18c20;
    padding: 3px 15px;
}
.indices-work i {
    padding: 3px 15px;
    color: #f18c20;
}
.indices-work1 i {
    padding: 3px 15px;
    color: #f18c20;
}
.shares-page1 i {
    padding: 3px 15px;
    color: #f18c20;
}

@media only screen and (max-width: 992px){
    section.assets-content-page .curre img.img-fluid {
        height: auto !important;
        width: 100%;
        max-width: 100% !important;
    }
    .asset-box1.mt-4.py-4 .col-lg-6.col-md-12.col-12.col-sm-12.d-flex.align-items-center {
        display: block !important;
    }
    .curency-image {
        text-align: center;
    }
    .assetval .col-lg-12.col-md-12.col-12.d-flex.align-items-center {
        display: block !important;
    }
    .commodities-image {
        text-align: center;
    }
    .indices.image {
        text-align: center;
    }
    .crypto-image {
        text-align: center;
    }
}
@media only screen and (max-width: 768px){
    .product1.markettab.pb-5.mb-5.mt-4 {
        width: 100%;
        padding-bottom: 10px !important;
    }
    section.assets-content-page .curre img.img-fluid {
        height: auto;
        width: 100%;
        max-width: unset !important;
    }
    .asset-box1.mt-4.py-4 {
        margin: 0 !important;
        padding: 0 !important;
    }
    .people-currency.py-3 p {
        display: flex;
    }
    .people-currency i {
        align-items: center;
        display: flex;
    }
    .commodity-trade.mt-2 p {
        display: flex;
    }
    .indices-work p {
        display: flex;
    }
    .indices-work h4 {
        margin: 20px 0;
    }
    .shares-page1 p {
        display: flex;
    }
    
}



@media only screen and (max-width: 768px){
    section.trade.getmt5.mt-4.pb-5 .box1get {
        text-align: center;
    }
    .trade .d-flex.justify-content-center.getsbtn.mt-5 {
        display: block !important;
        margin: 10px 0;
    }
    section.trade.getmt5.mt-4.pb-5 .openlivebtn {
        margin: 10px 0;
    }
    section.trade.getmt5.mt-4.pb-5 .getimg {
        text-align: center;
    }
    section.trade.getmt5.mt-4.pb-5 .getimg img.img-fluid {
        position: relative;
        right: 0px !important;
    }
 
}

@media only screen and (min-width: 768px) and (max-width: 992px){
    section.trade.getmt5.mt-4.pb-5 .d-flex.justify-content-center.getsbtn.mt-5 {
        justify-content: center !important;
    }
    section.trade.getmt5.mt-4.pb-5 .box1get {
        text-align: center;
    }
}
.trade .opendemobtn button.btn.btn-primary:hover{
    background-color: #909090 !important;
  }


@media only screen and (min-width: 768px) and (max-width: 1160px){


    .asset-content1.mt-4.pt-3 div#v-pills-tab {
        background: #fafafa;
        border-radius: 4px;
        width: 100%;
    }
    /* .asset-content1.mt-4.pt-3 a.active {
        font-size: 10px;
    }
    .asset-content1.mt-4.pt-3 a {
        font-size: 10px;
    }
    .asset-content-box1.mt-4.pt-3 a.active {
        font-size: 10px;

    } */
    /* .asset-content-box1.mt-4.pt-3 a {
        font-size: 10px;
  
    } */
    .product1.markettab.pb-5.mb-5.mt-4 {
        width: 100% !important;
    }
    section.assets-content-page a.downtrade.btn {

        /* font-size: 11px; */
        white-space: inherit !important;
    }
    section.assets-content-page a.optrade.btn {
        /* font-size: 11px; */
    }
    .people-currency.py-3 p {
        display: flex;
    }
    .people-currency.py-3 i {
        display: flex;
        align-items: center;
    }
    
    .indices-work p {
        display: flex;
    }
    
}
.mt5trade .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: none;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
    list-style: none;
}
.opendemobtn button.btn.btn-primary:hover{
    background-color: #909090 ;
  }
  .marketbtn.d-block.flex-column.align-items-center a:hover {
    color: white !important;
}
.marketbtn.d-block.flex-column.align-items-center{
    margin: 10px 0px;
}
.assets-value p{
    color: gray !important;
}
.assets-value small{
    color: gray ;
}
.curency-image img {
    width: 100%;
    max-width: 400px;
}
.curency-image {
    margin: 0 auto;
}
.shares-page1 p {
    display: flex;
}


@media only screen and (max-width: 768px){
.indices-work1 p {
    display: flex;
}
}
@media only screen and (min-width: 992px) and (max-width: 1024px){
    .asset-currency-page .col-lg-2.col-md-12.col-12.col-sm-12 {
        width: 100%;
    }
    .asset-currency-page .col-lg-8.col-md-12.col-12.col-sm-12 {
        width: 100%;
    }
    .asset-currency-page .col-lg-2.col-md-12.col-12.col-sm-12 {
        width: 100%;
    }

    .asset-indices-page .col-lg-2.col-md-12.col-12.col-sm-12{
  width: 100%;
}
.asset-indices-page .col-lg-8.col-md-12.col-12.col-sm-12{
    width: 100%;
  }
  .asset-indices-page .col-lg-2.col-md-12.col-12.col-sm-12{
    width: 100%;
  }
  .asset-commodities-page .col-lg-2.col-md-12.col-12.col-sm-12{
    width: 100%;
  }
  .asset-commodities-page .col-lg-8.col-md-12.col-12.col-sm-12{
      width: 100%;
    }
    .asset-commodities-page .col-lg-2.col-md-12.col-12.col-sm-12{
      width: 100%;
    }

    .asset-shares-page .col-lg-2.col-md-12.col-12.col-sm-12{
        width: 100%;
      }
      .asset-shares-page .col-lg-8.col-md-12.col-12.col-sm-12{
          width: 100%;
        }
        .asset-shares-page .col-lg-2.col-md-12.col-12.col-sm-12{
          width: 100%;
        }
    
        .asset-cryptocurrency-page .col-lg-2.col-md-12.col-12.col-sm-12{
            width: 100%;
          }
          .asset-cryptocurrency-page .col-lg-8.col-md-12.col-12.col-sm-12{
              width: 100%;
            }
            .asset-cryptocurrency-page .col-lg-2.col-md-12.col-12.col-sm-12{
              width: 100%;
            }

    
}

