

/* ==============================================contact us============================== */


.contact.us .contactcont {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 350px;
  /* padding-top: 86px; */
  background-color: #fcfcfc;
  background-image: url(../images/bgimgmt5.png);
}
/* .contact.us section.contact.banner2 {
  padding-top: 52px;
} */
.ourteam.support p{
  color: gray;
}
p.contconpara {
  color: gray;
}
.contact.us section.contactvantage a{
  font-weight: 600;
  font-size: 17px;
}
.contact.us .contact img.img-fluid {
  width: 100%;
  max-width: 455px;
  float: right;
  /* height: 100%; */
  /* margin-right: 29px; */
  margin-top: 41px;
}
.contact.us .mapform img {
  width: 100%;
  max-width: unset !important;
  margin-top: unset !important;
}
.contact.us .contactcont p{
  color: gray;
  font-size: 18px;
  
}
.contact.us section.contactvantage.mt-5 {
  text-align: center;
}

.contact.us button.btn.getmeta5 {
  background-color: #f29531;
  width: 100%;
  max-width: 200px;
  color: white;
  font-size: 18px;
  font-weight: 600;
}




.contact.us .mapcontent.text-center p {

  /* overflow: hidden !important; */
  color: gray;
}
.contact.us .mapform img {
  width: 84%;
}
.contact.us .contactmilliva .text_box p{
  color: gray;
  margin: 0;
}
.contact.us .form-group {
  margin-bottom: 1rem;
  line-height: 2.5rem;
  
}
.contact.us .gradient-text {
  font-weight: 700;
  display: inline-block;
 color: black;
 
}
.contact.us  button.btn.more-btn {
  background: #f29531;
  color: white;
}



.contact.us .get-ready {
  padding-top: 70px;
  padding-bottom: 70px;
  text-align: center;
  /* background: antiquewhite; */
}
.contact.us .get-ready h2 {
  margin-bottom: 20px;
}
.contact.us  button.btn.btn-g-ready {
  text-transform: uppercase;
  background: #f29531;
  border-radius: 35px;
  border: 1px solid white;
  padding: 11px 18px;
  display: inline-block;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  margin-top: 10px;
  text-align: center;
  cursor: pointer;
}


.contact.us .images img {
  width: 100%;
  max-width: 455px;
}

.contact.us .our.support.team.mt-5 {
  background: #fafafa;
  /* min-height: 350px; */
  /* margin-top: 102px !important; */
  padding-top: 45px;
  padding-bottom: 45px;
}

.contact.us  .contact_us_row1 ul {
  padding: 30px 0px 0px;
  margin: 0px -12px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  list-style: none;
}
.contact.us  .contact_us_row1 ul li {
  padding: 0px 10px 36px;
  margin: 0px;
  width: 380px;
}

.contact.us .contactmilliva .text_box{
  height: 100%;
  padding: 24px 5px 22px;
  border: 1px solid #e9e9e9;
  border-radius: 25px;
  text-align: center;
}
.contact.us .contact_us_row1 ul li .text_box a {
  display: block;
  width: 100%;
  font-size: 20px;
  color:black;
  text-decoration: none;
  font-family: 'Gilroy-Medium';
}
.contact.us .contactmilliva .text_box img {
  display: block;
  margin: 0px auto 12px;
  width: 100%;
  max-width: 60px;
}
/* .pic-ctn {
  width: 100vw;
  height: 200px;
} */
.contact.us div#carouselExampleInterval {
  margin: 0 auto;
  width: 80%;
  padding: -40px;
}
@keyframes display {
  0% {
    transform: translateX(200px);
    opacity: 0;
  }
  10% {
    transform: translateX(0);
    opacity: 1;
  }
  20% {
    transform: translateX(0);
    opacity: 1;
  }
  30% {
    transform: translateX(-200px);
    opacity: 0;
  }
  100% {
    transform: translateX(-200px);
    opacity: 0;
  }
}

.contact.us .pic-ctn {
  position: relative;
  width: 100%;
  height: 300px;
  margin-top: 15vh;
  margin-left: -61px;
}

.contact.us .pic-ctn > img {
  position: absolute;
  top: -28px;
  left: calc(50% - 100px);
  opacity: 0;
  animation: display 10s infinite;
  width:32%;
}

.contact.us img:nth-child(2) {
  animation-delay: 2s;
}
.contact.us img:nth-child(3) {
  animation-delay: 4s;
}
.contact.us img:nth-child(4) {
  animation-delay: 1s;
} 
.contact.us img:nth-child(5) {
  animation-delay: 2s;
} 

.contact.us .carousel-inner img {
    width:100%;
    height: 200px;
    /* padding: 3px;
    max-width: 393px; */
}

@media (max-width: 768px) {
  .contact.us .register.text {
    margin-top: -12px !important;
}

.contact.us  ul.social-media li i {
 
  margin-top: 10px;
}
.contact.us label.form-check-label.gradient-text {
   margin-left: unset;
  text-align: initial !important;
}
/* .contact.us .contact_us_row1 ul li{
width: unset;
} */
  }

  .contact.us .box1get h5{
    font-size: 17px;
    font-weight: 600;
  }
  .contact.us .box1get p{
    color: gray;
  }
  
  .contact.us .getsbtn {
    gap: 104px;
  }
  .contact.us .getimg img.img-fluid {
   
    position: relative;
    /* right: -65px; */
  }
  .contact.us .openlivebtn button.btn.btn-primary {
    background-color: #f29531;
   
    border: none;
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
  }
  .contact.us .opendemobtn button.btn.btn-primary:hover {
    background: #909090!important;
}
  .contact.us .opendemobtn button.btn.btn-primary {
    background-color:#909090;
    border: none;
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
  }
  .contact.us .box1get img.img-fluid {
    max-width: 50px;
    max-height: 50px;
    width: 100%;
}
@media only screen and (max-width: 768px) {
  .contact.us .getimg img.img-fluid {
   
      right: unset !important;
  
  }
  .contact.us ul.social-media {
    display: block !important;
    margin: 0 auto;
    padding: 0;
}
.contact.us ul.social-media li i.fab.fa-facebook-f {
  width: 24% !important;
  margin-left: 1px !important;
}
}




.contact.us ul.social-media li i.fab.fa-facebook-f {
  background: #4285f4;
  padding: 17px;
  color: white;
  border-radius: 30px;
  width: 45%;
  text-align: center;
  font-size: 28px;
  margin-left: 87px;
  margin-top: 1px;
}
.contact.us ul.social-media li i.fa-brands.fa-twitter{
  background: #4285f4;
  padding: 17px;
  color: white;
  border-radius: 57px;
  font-size: 28px;
}
.contact.us ul.social-media li i.fa-brands.fa-instagram{
  background: #4285f4;
  padding: 17px;
  color: white;
  border-radius: 57px;
  font-size: 28px;
}
.contact.us ul.social-media li i.fab.fa-linkedin{
  background: #4285f4;
  padding: 17px;
  color: white;
  border-radius: 57px;
  font-size: 28px;
}
.contact.us ul.social-media li i.fa-brands.fa-youtube{
  background: #4285f4;
  padding: 17px;
  color: white;
  border-radius: 57px;
  font-size: 28px;
}
.contact.us ul.social-media li i.fa-brands.fa-telegram{
  background: #4285f4;
  padding: 17px;
  color: white;
  border-radius: 57px;
  font-size: 28px;
}
.contact.us ul.social-media li i.fab.fa-facebook-f:hover {
  background: white;
  color: blue;

}
.contact.us ul.social-media li i.fa-brands.fa-twitter:hover{
  background: white;
  color: blue;

}
.contact.us ul.social-media li i.fa-brands.fa-instagram:hover{
 /* background-image: url(../img/instagram.png); */
background-color: white;
color: red;

}
.contact.us ul.social-media li i.fab.fa-linkedin:hover{
  background: white;
  color: blue;

}
.contact.us ul.social-media li i.fa-brands.fa-telegram:hover{
  background: white;
  color: blue;

}
.contact.us ul.social-media li i.fa-brands.fa-youtube:hover{
  background: white;
  color: red;

}
.contact.us .media.icon.d-flex h4 {
  margin-top: 25px;
}

.contact.us ul.social-media {
  list-style: none;
  display: flex;
  gap: 17px;
}

@media only screen and (max-width: 768px){
  .contact.us  section.getmt5.mt-4.pb-5 .box1get {
      text-align: center;
  }
  .contact.us  .d-flex.justify-content-center.getsbtn.mt-5 {
      display: block !important;
      margin: 10px 0;
  }
  .contact.us  section.getmt5.mt-4.pb-5 .openlivebtn {
      margin: 10px 0;
  }
  .contact.us  section.getmt5.mt-4.pb-5 .getimg {
      text-align: center;
  }
  .contact.us  section.getmt5.mt-4.pb-5 .getimg img.img-fluid {
      position: relative;
      right: 0px !important;
  }
  .contact.us .opendemobtn button.btn.btn-primary {
    margin-top: 10px;
}
}




.contact.us i.confa svg {
  background: #f29531;
  font-size: 49px;
  margin-top: 16px;
  border-radius: 23px;
  padding: 10px;
  color: white;
}
.contact.us i.confa svg:hover{
background: white;
color: #0000ff;
}
.contact.us i.insta svg{
background: #f29531;
  font-size: 49px;
  margin-top: 16px;
  border-radius: 23px;
  padding: 10px;
  color: white;
}
.contact.us i.youtube svg{
background: #f29531;
  font-size: 49px;
  margin-top: 16px;
  border-radius: 23px;
  padding: 10px;
  color: white;
}
.contact.us i.youtube svg:hover{
background: white;
color: red;
}
.contact.us i.insta svg:hover{
background: white;
color: red;
}

@media only screen and (min-width: 768px) and (max-width: 992px){
  .contact.us   section.getmt5.mt-4.pb-5 .d-flex.justify-content-center.getsbtn.mt-5 {
      justify-content: start !important;
  }
  .contact.us .opendemobtn button.btn.btn-primary {
    margin-top: 10px;
}
}


@media only screen and (max-width: 992px) {
  .contact.us .contact.us h1 {
    text-align: center !important;
}
.contact.us button.btn.getmeta5{
  margin: 0 auto;
  display: block;
}
.contact.us .contact img.img-fluid{
  float: unset !important;
margin: 0 auto;
display: block;
}
.contact.us .inner {
  text-align: center;
}
.contact.us section.contact.banner2 {
text-align: center;
}
.media.icon.d-flex h4 {
text-align: center !important;
display: block;
margin: 0 auto;
}
.contact.us ul.social-media {
text-align: center;
display: flex;
justify-content: center;

}
.contact.us .getsmt5 {
text-align: center;
}
.contact.us button.btn.more-btn{
  display: block;
margin: 0 auto;
}
}


.contactsli5 img.img-fluid {
  width: 100%;
}

i.confa.twitter-x {
  background: #f29531;
  font-size: 49px;
  margin-top: 16px;
  border-radius: 23px;
  color: white;
  width: 49px;
  height: 49px;
  display: flex;
  align-items: center;
  justify-content: center;
}
i.confa.twitter-x img {
  height: 22px;
  margin-top: -7px;
  margin-left: 2px;
  filter: invert(1);
}
i.confa.twitter-x:hover {
  background: transparent;
}
i.confa.twitter-x:hover img {
  height: 22px;
  margin-top: -7px;
  margin-left: 2px;
  filter: invert(0);
}