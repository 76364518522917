


/* .getsmt5 p {
  text-align: center;
}
   */
  @media only screen and (min-width: 769px) and (max-width: 1400px){
    .mt5trader .getimg img.img-fluid {
      position: relative;
      right: unset !important;
     }
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1200px){
    .service-box h5 {
      font-size: 12px !important;
      white-space: nowrap !important;
  }
  }
  @media screen and (max-width: 1020px){
  
    .box1get {
      margin: 0 auto;
      display: block;
      text-align: center;
    }
  }
  
  
  @media only screen and (min-width: 769px) and (max-width: 820px){
    .mt5cont img.img-fluid{
      float: unset !important;
      margin: 0 auto;
      display: block;
      padding-bottom: 37px;
    margin-top: 25px;
    }
    .mt5desk h2 {
      margin-top: 44px;
    }
  
    .mt5mobileapp img.img-fluid{
      float: unset !important;
      margin: 0 auto;
      display: block;
    }
    .mt5ios img.img-fluid {
      
      float: unset;
      margin: 0 auto;
      display: block;
  }
  .mt5desk img.img-fluid{
    float: unset;
      margin: 0 auto;
      display: block;
  }
  .mt5fea .col-md-12.col-lg-3 {
    margin-top: 36px;
  }
  
  .mt5mac img.img-fluid{
    float: unset !important;
    margin: 0 auto;
    display: block;
  }
  }
  
  
  
  /* 
  bannersec */
  .mt5cont {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 350px;
    padding-top: 86px;
    background-color: #fcfcfc;
      background-image: url(../images/bgimgmt5.png);
  }
  
  .mt5cont img.img-fluid {
    width: 100%;
    max-width: 455px;
    float: right;
    height: 100%;
  }
  
  
  .mt5cont p{
    color: gray;
    font-size: 18px;
    text-align: left;
  }
  button.btn.getmeta5 {
    background-color: #f29531 !important;
    width: 100%;
    max-width: 200px;
    color: white;
    font-size: 18px;
    font-weight: 600;
    border: none;
  }
  
  .mt5desk img.img-fluid {
    width: 100%;
    max-width: 500px;
    height: 100%;
    float: left;
  }
  .mt5ios img.img-fluid {
    width: 100%;
    max-width: 500px;
    height: 100%;
    float: left;
  }
  
  button.btn.deskdownload {
    background-color: #f18c20;
    color: white;
    font-size: 16px;
    font-weight: 600;
    /* padding: 9px 70px; */
    text-align: center;
    text-transform: uppercase;
    border: none;
    width: 100%;
    max-width: 200px;
    height: 40px;
    
  }
  
  .mt5desk p {
    color: gray;
    text-align: justify;
    
  }
  .mt5ios p {
    color: gray;
    text-align: justify;
   
  }
  ul.fealist li {
    list-style: none;
    /* line-height: 45px; */
    padding: 12px;
    display: flex;
}  
  ul.fealist li i {
    color: #f29531;
    font-size: 19px;
}
  .mt5mobileapp img.img-fluid {
    width: 100%;
    max-width: 500px;
    height: 100%;
    float: right;
  }
  
  .mt5mac img.img-fluid {
    width: 100%;
    max-width: 500px;
    height: 100%;
    float: right;
  }
  .mt5mobileapp p {
    color: gray;
    text-align: justify;
    
  }
  
  .mt5mac p {
    color: gray;
    text-align: justify;
   
  }
  
  .mt5mobileapp {
    background-color: #fafafa;
  }
  .mt5mac{
    background-color: #fafafa;
  }
  
  .openlivebtn button.btn.btn-primary {
  
    font-weight: 0;
    font-size: 14px;
    color: #fff;
    background-color: #f18c20;
    padding: 9px 50px;
    border: solid #f18c20 2px;
    box-shadow: rgb(0, 0, 0) 0px 0px 0px 0px;
    border-radius:8px;
    transition : 1000ms;
    transform: translateY(0);
    /* display: flex; */
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    text-align: center;
  }

  .openlivebtn button.btn.btn-primary:hover{
    transition : 1000ms;
    padding: 10px 50px;
    transform : translateY(-0px);
    background-color: #fff;
    color: #f18c20;
    border: solid 2px #f18c20;
  }

  .opendemobtn button.btn.btn-primary {
    font-weight: 0;
    font-size: 14px;
    color: #fff;
    background-color: #f18c20;
    padding: 10px 50px;
    border: solid solid #7c7c7c 2px;
    box-shadow: rgb(0, 0, 0) 0px 0px 0px 0px;
    border-radius:8px;
    transition : 1000ms;
    transform: translateY(0);
    /* display: flex; */
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }
  .opendemobtn button.btn.btn-primary:hover{
    transition : 1000ms;
    padding: 10px 50px;
    transform : translateY(-0px);
    background-color: #fff  !important;
    color: #f18c20;
    border: solid 2px #bababa;
  }
  .getsbtn {
    gap: 104px;
  }
  .mt5trader .getimg img.img-fluid {
   
    position: relative;
    right: -65px;
    width: 100%;
  }
  
  
  
  
  
  .box1get img.img-fluid {
    max-width: 60px;
   
    width: 100%;
    height: 100%;
  }
  .box1get h5{
    font-size: 17px;
    font-weight: 600;
  }
  .box1get p{
    color: gray;
  }
  
  
  
  
  
  .service-fold {
    position: relative;
  }
  
  .service-box {
    height: 100%;
    padding: 20px;
    margin: 0;
    /* background: -webkit-linear-gradient(right, #D93921 0%, #D93921 100%), -webkit-linear-gradient(right, #D93921 0%, #D93921 100%);
    background: -moz-linear-gradient(right, #D93921 0%, #D93921 100%), -moz-linear-gradient(right, #D93921 0%, #D93921 100%);
    background: -o-linear-gradient(right, #D93921 0%, #D93921 100%), -o-linear-gradient(right, #D93921 0%, #D93921 100%);
    background: -ms-linear-gradient(right, #D93921 0%, #D93921 100%), -ms-linear-gradient(right, #D93921 0%, #D93921 100%);
    background: linear-gradient(right, #D93921 0%, #D93921 100%), linear-gradient(right, #D93921 0%, #D93921 100%); */
    background-position: 100% -20px, 20px 100%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px 0px;
    background-color: white;
   
    /* margin-bottom: 30px; */
    color: #000;
    text-align: center;
    display: block;
    min-height: 250px;
  }
  
  .service-fold:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 0;
    border-top: 20px solid rgb(66 133 244);
    border-left: 20px solid transparent;
    border-top: 20px solid rgb(241 140 32);
  }
  
  .service-fold:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 20px;
    width: 0;
    height: 0;
    border-bottom: 20px solid rgba(0, 0, 0, .15);
    border-right: 20px solid transparent;
  }
  
  .service-box img.img-fluid{
    width:100%;
    max-width: 60px;
  }
  
  .service-box h5 {
    text-transform: none;
  font-size: 17px;
    padding: 20px;
    font-weight: 600;
    white-space: nowrap;
  }
  
  
  .service-box p {
    color: gray;
  }

  @media screen and (max-width: 992px){
    .mt5cont img.img-fluid {
   
      padding-bottom: 37px;
      margin-top: 25px !important;
      float: unset !important;
      margin: 0 auto;
      display: block;
    }
    .mt5desk h2{
      text-align: center;
    }
    
    .mt5mobileapp h2{
      text-align:center;
    }
    
    .mt5ios h2{
      text-align:center;
    }
    .mt5mac h2{
      text-align:center;
    }
    .mt5mac {
   
      padding: 32px 0;
    }
    .mt5cont p{
     text-align: center;
    }
    .mt5cont{
      text-align:center;
    }
    .mt5desk img.img-fluid{
      margin: 0 auto;
      display: block;
    }
    .mt5mobileapp img.img-fluid{
      float: unset !important;
      margin: 12px auto;
      display: block;
    }
    .mt5ios img.img-fluid{
      float: unset !important;
      margin: 0 auto;
      display: block;
    }
    .mt5mac img.img-fluid{
      float: unset !important;
      margin: 0 auto;
      display: block;
    }
    button.btn.getmeta5{
      margin:0 auto;
      display: block;
    }
    .mt5cont h2{
      text-align: center;
    }
    .mt5cont p {
      text-align: center;
    }
    
     .mt5trader button.btn.deskdownload {
margin: 0 auto;
display: block;
     }
     
     .mt5trader .getimg img.img-fluid {
      position: relative;
      right: unset !important;
     }
     .mt5fea .col-md-12.col-lg-3 {
      margin-top: 36px;
  }


  .mt5mobileapp {
   
    padding: 32px 0;
  }
  
  ul.fealist li {
    text-indent: -29px;
    display: block !important;
}
  .getsbtn {
    gap: 12px;
  }
  .mt5desk h2 {
    margin-top: 44px;
  }
 
  .getsbtn {
    
    display: block !important;
  }
  .opendemobtn button.btn.btn-primary{
    margin-top: 20px;
  }
  
  }
 
  @media only screen and (min-width: 768px) and (max-width: 992px){
    ul.fealist {
      
      margin: 0 auto;
      width: 43%;
  }
  }
  @media only screen and (min-width: 425px) and (max-width: 767px){
    ul.fealist {
      margin: 0 auto;
      display: block;
      width: 50%;
  }
  }
 
  .getsbtn a{
color: white;
  }
  
 

  .getapara{
    text-align: center;
  }


  button.btn.deskdownload:focus{
    background-color: #f18c20 !important;
  }