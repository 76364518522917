
/* -------------------------------------------FAQ-------------------------------------- */
.Faq  section.faq-banner {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 350px;
    /* padding-top: 86px; */
    background-color: #fcfcfc;
    background-image: url(../images/bgimgmt5.png);
}

.Faq .faqtag p {
    text-align: center;
}
.Faq section.faq-banner img.img-fluid {
    width: 100%;
    max-width: 359px;
    float: right;
    height: 100%;
}
.Faq button.btn.getmeta5 {
    background-color: #f29531;
    width: 100%;
    max-width: 200px;
    color: white;
    font-size: 18px;
    font-weight: 600;
  }

  .Faq section.faq-banner p{
    color: gray;
    font-size: 18px;
  }
  

  .Faq .bitcoiva.faq.box2:hover {
    box-shadow: 0 1rem 3rem rgb(31 45 61 / 13%) !important;
    border-radius: 30px;
    background-color: var(--color-faf4) !important;
}


.Faq .bitcoiva.faq.box2 .card {
    width: 100%;
    height: auto;
    min-height: 240px;
    min-width: unset;
    transition: all 0.2s ease;
}



.Faq .bitcoiva.faq.box2 .txt-orange {
    color: #555555;
    font-size: 23px;
}

.Faq .bitcoiva.faq.box2 .mt-100 {
    margin-top: 100px;
}

.Faq .bitcoiva.faq.box2 .btn-theme {
    color: var(--color-faf1) !important;
    padding: 0.5rem 0.9rem;
    font-weight: 400;
    font-size: 0.875rem;
    display: inline-block;
    display: inline-flex;
    outline: none;
    border: none;
    cursor: pointer;
    overflow: hidden;
    z-index: 2;
    align-items: center;
    position: relative;
    cursor: pointer;
    /* -webkit-box-shadow: 0px 14px 47px 0px rgba(28, 28, 28, 0.24);
    -moz-box-shadow: 0px 14px 47px 0px rgba(28, 28, 28, 0.24); */
    /* box-shadow: 0px 3px 19px 0px rgb(28 28 28 / 24%); */
    -webkit-border-radius: 70px;
    -moz-border-radius: 70px;
    border-radius: 70px;
}

.Faq .bitcoiva.faq.box2 .bg-orange {
    background:  var(--color-faf21);
}

.Faq .bitcoiva.faq.box2 a {
    text-decoration: none !important;
    color: var(--color-faf1) !important;
}

.Faq .bitcoiva.faq.box2 .btn-theme i {
    margin-right: 10px;
}

.Faq .bitcoiva.faq button.accordion-button {

    color: unset !important;
    background-color: unset !important;
    box-shadow: unset !important;
}

.Faq .bitcoiva.faq .accordion-item:first-of-type .accordion-button {
    border-top-left-radius: unset !important;
    border-top-right-radius: unset !important;
}


.Faq .bitcoiva.faq .accordion-body {
    color: #7e7e7d;
    text-align: left;
    font-size: 15px;
    font-weight: unset;
}


.Faq .bitcoiva.faq.box2 svg {
    font-size: 45px;

    color: #f18c20;
}

.Faq .bitcoiva.faq.box2 .modal-header {
    text-align: center;
    display: unset !important;
    color: #555555;
}

.Faq .bitcoiva.faq .modal-dialog span p {
    color: #feb019;
}

.Faq .bitcoiva.faq .modal-dialog p span {
    color: #feb019;
}

.Faq .bitcoiva.faq .modal .modal-content {
    background-color: #ffffff;
    width: 60%;
    display: block;
    margin: 0 auto;
    /* 
    background-image: url(../images/popup1.png);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%; */
}

.Faq .bitcoiva.faq .modal-dialog {
    display: unset;
    width: 100%;
}

.Faq .bitcoiva.faq .modal-footer {
    display: block;
}

.Faq .bitcoiva.faq .modal-footer img.img-fluid.my-4 {
    width: 37%;
}


.Faq .bitcoiva.faq .faqbb {
    background-color: #fafafa;
    height: auto;
    min-height: 300px;
    width: 100%;
}





.Faq .bitcoiva.faq p.popupborder {
    text-align: center;
    border: 1px solid #febb39;
    padding: 10px 10px;
}

.Faq .bitcoiva.faq .modal-body {
    overflow-y: scroll;
    height: 300px;
}

.Faq .modal-body::-webkit-scrollbar {
    width: 5px;
    border-radius: 20px;
}

/* Track */
.Faq .modal-body::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.Faq .modal-body::-webkit-scrollbar-thumb {
    background: #feb019;
}

/* Handle on hover */
.Faq .modal-body::-webkit-scrollbar-thumb:hover {
    background: rgb(252, 0, 0);
}


@media only screen and (max-width: 768px) {

    .Faq .bitcoiva.faq .modal .modal-content {

        width: 98%;

    }

    .Faq .faqbcont {
        padding-top: 10px !important;
       
    }
}



.Faq .faqban p {
    color: gray;
    font-size: 23px;
    text-align: left;
}






@media only screen and (min-width: 769px) and  (max-width: 1024px){
    .Faq .faqbcont {
        padding-top: 112px;
       
    }
}

@media only screen and (min-width: 769px) and (max-width: 1023px){
    
    .Faq .faqbcont {
        padding-top: 10px;
       
    }
}


@media only screen and (max-width: 992px) {
.Faq {
    text-align: center;
}
.Faq button.btn.getmeta5{
    margin: 0 auto;
    display: block;
}
.Faq section.faq-banner img.img-fluid{
    float: unset;
}
}








.vertical-tabs ul {
    list-style: none;
}
.Faq_vert_tab_inr_title__1IcVp {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
    color: red;
}
.widget-wrap.text-center.bg-sky.rounded.py-5.pb-5.mb-5 g {
    fill: #f18c20;
}

.plateform-ico svg {
    width: 0.6em;
}