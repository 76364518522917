
.faqm2 section.faq-banner {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 350px;
    /* padding-top: 86px; */
    background-color: #fcfcfc;
    background-image: url(../images/bgimgmt5.png);
}
.faqm2 section.faq-banner img.img-fluid {
    width: 100%;
    max-width: 359px;
    float: right;
    height: 100%;
}

.faqm2 .faqsearch {
    padding: 30px;
    background-color: #fafafa;
    color: black;
}

.faqsearch .search {
    position: relative;
    /* box-shadow: 0 0 40px rgba(51, 51, 51, .1); */
  
  }

  .faqlinkbanner h1 {
    text-align: center;
}

  
  .faqsearch .search input {
    height: 100%;
    text-indent: 25px;
    border: 2px solid #d6d4d4;
    min-height: unset !important;
    
}
  
  
  
.faqsearch .search .fa-search {
  
    position: absolute;
    top: 13px;
    left: 13px;
  
  }
  
  .faqsearch .search button {
    position: absolute;
    top: 4px;
    right: 4px;
    height: 32px;
    width: 80px;
    border: none;
    background-color: #f18c20;
    border-radius: 5px;
    color: white;
    font-size: 17px;
}
  .faqsearch .search {
  height: 40px;
  }



.faqsidebar span.arrow svg {
    position: relative;
    left: 25px;
    color: #c7c7c7 !important;
    font-size: 21px !important;
}





.faqsidebar #sidebar-nav {
    width: 200px;
}

.faqsideleft {
    padding: 20px;
}

.faqsidebar h6 {
    color: var(--color-faf7) !important;
    font-size: 15px;
    margin-bottom: unset;
    /* height: 6px; */
}

.faqsidebar ul li:hover {
    color: #fdb019
}

.faqsidebar ul li svg {
    font-size: 21px;
    color: #f18c20;
}

.faqsideleft span {
    color: #ffffff;
}


.faqsidebar {
    padding: 20px 20px;
    background-color: var(--color-faf2) !important;
    /* height: 100%;
    max-height: 887px; */
}

/* .faqsidebar #pills-tab {
    
    height: 500px;
   
} */

.faqsidebar ul#pills-tab li {
    color: var(--color-faf7) !important;
    padding: 15px;
    font-weight: 600;
    /* text-transform: uppercase; */
    font-size: 10px;
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    
}

.faqsidebar ul li .fas {
    font-size: 16px
}

.faqsidebar .nav-pills .nav-link.active{
    background-color: unset !important;
}

.faqsidebar ul li:hover .fas {
    color: #fdb019
}

.faqsidebar #topnavbar {
    width: 100%
}

.faqsidebar .faqsideleft .contentscroll {
    overflow-y: scroll;
    height: 230px;
}

.faqsidebar .contentscroll::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  .faqsidebar .contentscroll::-webkit-scrollbar-track {
    background: #ffffff;
  }
  
  /* Handle */
  .faqsidebar .contentscroll::-webkit-scrollbar-thumb {
    background: #fdb019;
  }
  
  /* Handle on hover */
  .faqsidebar .contentscroll::-webkit-scrollbar-thumb:hover {
    background: #fdb019;
  }

  .faqsidebar .faqsideleft p {
    color: var(--color-faf3) !important;
}

.faqsideright img {
    width: 100%;
    max-width: 300px;
    display: block;
    margin: 0 auto;
}

.faqsideimg{

    width: 100%;
    max-width: 240px;
    display: block;
    margin: 0 auto;
}

.faqsidebar span.icon {
    padding: 0 10px;
}


.faqsidebar li.sub {
    font-size: 13px !important;
    font-weight: 500 !important;
    padding: 10px 10px !important;
    color: #9b9b9b !important;
}

.faqsidebar li.sub:hover {
    color: #fdb019 !important;
   
}

.faqsidebar ul#collapseOne {
    padding: 8px 26px;
    /* font-size: 15px !important; */
}

.faqsidebar .faqsub {
    padding: 5px 0;
}

.faqsidebar .faqsub .card {
    padding: 20px;
    background-color: var(--color-faf4) !important;
    border: unset;
}







/* 12 */
.faqsidebar .btn-12{
    border: none;
    box-shadow: none;
    line-height: 42px;
    background-color: unset;
    padding: 0;
  }
  .faqsidebar .btn-12 span {
    background: rgb(0,172,238);
    background: linear-gradient(0deg, rgb(241 140 32) 0%, rgb(241 140 32) 100%);
    display: block;
    position: absolute;
    width: 130px;
    height: 40px;
   
    border-radius: 5px;
    margin:0;
    text-align: center;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all .3s;
    transition: all .3s;
  }
  .faqsidebar .btn-12 span:nth-child(1) {
    box-shadow:
     -7px -7px 20px 0px #fff9,
     -4px -4px 5px 0px #fff9,
     7px 7px 20px 0px #0002,
     4px 4px 5px 0px #0001;
    -webkit-transform: rotateX(90deg);
    -moz-transform: rotateX(90deg);
    transform: rotateX(90deg);
    -webkit-transform-origin: 50% 50% -20px;
    -moz-transform-origin: 50% 50% -20px;
    transform-origin: 50% 50% -20px;
  }
  .faqsidebar .btn-12 span:nth-child(2) {
    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: 50% 50% -20px;
    -moz-transform-origin: 50% 50% -20px;
    transform-origin: 50% 50% -20px;
  }
  .faqsidebar .btn-12:hover span:nth-child(1) {
    box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
     7px 7px 20px 0px rgba(0,0,0,.1),
     4px 4px 5px 0px rgba(0,0,0,.1);
    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
  .faqsidebar .btn-12:hover span:nth-child(2) {
    /* box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
     7px 7px 20px 0px rgba(0,0,0,.1),
     4px 4px 5px 0px rgba(0,0,0,.1); */
   color: transparent;
    -webkit-transform: rotateX(-90deg);
    -moz-transform: rotateX(-90deg);
    transform: rotateX(-90deg);
  }

  .accordion-button::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
   
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform .2s ease-in-out;
}

.faqlinkbanner h1 {
    text-align: center;
    color: var(--color-faf7) !important;
}



.faqsidebar span a {
    color: aliceblue;
}
  
  
  @media only screen and (min-width: 280px) and  (max-width: 426px){
    .faqsidebar ul#pills-tab li {
        
        padding: 15px 0;
     
    }

    .faqsideleft {
        padding: unset;
    }

    .faqsidebar .btn-12 span {
      
        width: 100px;
       
    }

    .faqsideright img{

        display: none;
    }

    .faqsidebar .btn-12 {
      
        position: relative;
        bottom: 35px;
        padding: 20px 0;
    
    }

    .faqsidebar #sidebar-nav {
        width: 117px;
    }

    .faqsidebar .faqsub .card {
        padding: 9px;
    }

    .faqsidebar li.sub {
        font-size: 10px !important;
     
    }

    .faqsidebar ul#collapse1 {
        padding: 0 10px;
        /* font-size: 10px !important; */
    }

    .faqsidebar h6 {
     
        font-size: 12px;
        
    }

  

    

 
}



.faqsideleft h4 {
    color: var(--color-faf7) !important;
}
  


@media only screen and (max-width: 992px) {
    .faqm2 {
        text-align: center;
    }
    .faqm2 button.btn.getmeta5{
        margin: 0 auto;
        display: block;
    }
    .faqm2 section.faq-banner img.img-fluid{
        float: unset;
    }
    .faqm2 button.accordion-button.collapsed {
        padding: 8px !important;
      }

    .faqsidebar main.col.ps-md-2.pt-2 {
        width: 50%;
        /* font-size: 17px; */
    }
    .faqm2 span.name h2{
        white-space: revert !important;
    }
    .faqsidebar .faqsub li {
        font-size: 12px;
        text-align: start;
      
       
      
       
    }
    .faqm2 p {
        font-size: 12px;
    }
    
    .faqsidebar .accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
   
    font-size: 12px;
}
    }
    
    .faqm2 button.accordion-button {
        background: unset !important;
        color: black !important;
        border-color: #9b9b9b;
    }
    .faqm2 button.accordion-button:focus{
        box-shadow: none !important;
    }
   .sidemenuicon {
        color: #f18c20;
    }

    .faqm2 .accordion-header {
        margin-bottom: 0;
        border: 1px solid #f0f0f0;
        margin-top: 10px;
        border-radius: 5px;
    }

    .faqm2 span.name h2 {
        font-size: 14px;
        color: #f29531;
        text-transform: uppercase;
        white-space: nowrap;
        
    }

    .faqm2 .faqsidebar span.icon g {
        fill: #f18c20;
    }

    #style-4::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

.faqsidebar svg{
    fill: #f18c20;
    float: left;
}
@media only screen and (max-width: 768px) {

.faqsidebar .faqsub li {
     font-size: 12px;
     text-align: start;
     text-indent: -18px;
    }
    .faqsub .accordion-body p {
    text-align: start;
    }
}