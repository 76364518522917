.bg-image.hover-overlay.ripple img.img-fluid {
    width: 100%;
    height:200px;
    object-fit: cover;

}

.blogcont .card {
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  height: 100%;
  width: 100%;
  max-width: 350px;
}


/* ---------------pagination----------------------------- */
 
.blog.pagination .pagination a {
    text-decoration: none;
    color: #000;
    float: left;
    padding: 8px 16px;
  }
  .blog.pagination  .pagination1 a.active {
    background-color: #f29531;
    color: #FFF;
  }
  .blog.pagination  .pagination1 a:hover:not(.active) {
    background-color: #DDD;
  }
  .blog.pagination  .pagination2 a.active {
    background-color: #f29531;
    color: #FFF;
    border-radius: 5px;
  }
  .blog.pagination  .pagination2 a:hover:not(.active) {
    background-color: #DDD;
    border-radius: 5px;
  }
  .blog.pagination  .pagination3 a {
    transition: background-color 0.3s;
  }
  .blog.pagination  .pagination4 a:not(.active) {
    border: 1px solid #DDD;
  }
  .blog.pagination  .pagination4 a.active {
    border: 1px solid #f29531;
  }
  .blog.pagination  .pagination5 a:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  .blog.pagination  .pagination5 a:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .blog.pagination  .pagination6 a {
    margin: 0 4px;
  }
  .blog.pagination  .pagination7 a {
    font-size: 22px;
  }
 
  .blog.pagination  ul.breadcrumb {
    list-style-type: none;
    background-color: #EEE;
    padding: 8px 16px;
  }
  .blog.pagination  ul.breadcrumb li {
    display: inline-block;
  }
  .blog.pagination  ul.breadcrumb li+li:before {
    padding: 8px;
    color: #000;
    content: "/\00A0";
  }
  .blog.pagination ul.breadcrumb li a {
    color: green;
  }

  .blogcont .card:hover {
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 40%);
    transform: scale(1.05);
      }
  
      .blogcont .card{
        transition: all 0.5s ease;
      }
  
     
      a.milli {
        color: #f18c20;
    }

    @media only screen and (max-width: 992px){
      .blog.pagination .pagination a {
        
        padding: 8px 8px;
    }

    a.milli {
     
      text-align: center ;
      margin: 0 auto; 
      display: block;
  }

  .blogcont  p.card-text {
    text-align: center;
}
    }
    