@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");

:root {
  scroll-behavior: smooth;
}

body {
  font-family: "IBM Plex Sans", sans-serif;
  color: #444444;
}

a {
  color: #4154f1;
  text-decoration: none;
}

a:hover {
  color: #717ff5;
  text-decoration: none;
}

p {
  line-height: 25px;
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  .mt5fea .col-md-12.col-lg-3 {
    margin-top: 36px;
  }
  .whatsupico img.img-fluid {
   
    bottom: 43px;
    z-index: 9999;
    left: 30px !important;
  }
.millivaheader .dropdown-menu li .dropdown-item {

  padding: 0.5em 1em !important;
}
  
.pulse {
  height: 15px;
  width: 15px;
  background: linear-gradient( #f18c20, #d89c5b );
  position: absolute;
  margin: auto;
  right: 0;
  left: -37px;
  top: 14px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  color: #ffffff;
  font-size: 12px;
}
.pulse:before,
.pulse:after{
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #f18c20;
  opacity: 0.7;
  border-radius: 50%;
  z-index: -1;
}
.pulse:before{
  -webkit-animation: pulse 2s ease-out  infinite;
          animation: pulse 2s ease-out  infinite;
}
.pulse:after{
  -webkit-animation: pulse 2s 1s ease-out infinite;
          animation: pulse 2s 1s ease-out infinite;
}

@-webkit-keyframes pulse{
  100%{
      -webkit-transform: scale(2.5);
              transform: scale(2.5);
      opacity: 0;
  }
}

@keyframes pulse{
  100%{
      -webkit-transform: scale(2.5);
              transform: scale(2.5);
      opacity: 0;
  }
}


.campi .dropdown-toggle::after{

  margin-left: 1.255em;
}


.pulse1 {
  height: 15px;
  width: 15px;
  background: linear-gradient( #f18c20, #d89c5b );
  position: absolute;
  margin: auto;
  /* right: 0; */
  left: 43px;
  top: 13px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  color: #ffffff;
  font-size: 12px;
}
.pulse1:before,
.pulse1.about{
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #f18c20;
  opacity: 0.7;
  border-radius: 50%;
  z-index: -1;
}
.pulse1:before{
  -webkit-animation: pulse 2s ease-out  infinite;
          animation: pulse 2s ease-out  infinite;
}
.pulse1:after{
  -webkit-animation: pulse 2s 1s ease-out infinite;
          animation: pulse 2s 1s ease-out infinite;
}

@-webkit-keyframes pulse{
  100%{
      -webkit-transform: scale(2.5);
              transform: scale(2.5);
      opacity: 0;
  }
}

@keyframes pulse{
  100%{
      -webkit-transform: scale(2.5);
              transform: scale(2.5);
      opacity: 0;
  }
}



  .mt5cont img.img-fluid {
    padding-bottom: 37px;
    margin-top: 25px !important;
    float: unset !important;
    margin: 0 auto;
    display: block;
  }

  img.popup-images {
    width: 100%;
}

  .mt5desk img.img-fluid {
    margin: 0 auto;
    display: block;
  }

  .banner h1 {
    font-size: 24px !important;

  }

  .banner {

    bottom: 19px;

  }

  .mt5mobileapp img.img-fluid {
    float: unset !important;
    margin: 0 auto;
    display: block;
  }

  .mt5ios img.img-fluid {
    float: unset !important;
    margin: 0 auto;
    display: block;
  }

  .mt5mac img.img-fluid {
    float: unset !important;
    margin: 0 auto;
    display: block;
  }

  .mt5mobileapp {
    padding: 32px 0;
  }

  .mt5mac {
    padding: 32px 0;
  }

  ul.fealist li {
    text-indent: -15px;
  }

  .getsbtn {
    gap: 12px;
  }

  .mt5desk h2 {
    margin-top: 44px;
  }

  .millivaheader .dropdown-menu ul {
    padding: 0 !important;
  }

  a.topbtn {
    padding-top: 11px;
  }

}
@media screen and (max-width: 1280px) {
  .blink1 {
    bottom: 100px !important;
    right: 5px !important;
  }
}


@media screen and (max-width: 992px) {
  .millivaheader nav.navbar.navbar-expand-xl.navbar-light.bg-light.d-flex.align-items-center {
    padding: 0 0 !important;
  }

  .offcanvas-backdrop.show {
    opacity: 0 !important;
  }

  .offcanvas-start-xl {
    top: 68px !important;
  }

  .millivaheader button.btn.btn.navbar-toggler:hover {
    border: unset;
    background-color: unset !important;
  }

  li.nav-item.dropdown.notifyy {
    display: none;
  }

  .millivaheader button.btn.btn.navbar-toggler:focus {
    box-shadow: unset !important;
  }




}

@media (max-width: 1024px) {
  .notifyy .icon-button__badge {
    position: absolute;
    top: 10px;
    left: 109px !important;
    width: 10px;
    height: 10px;
    background: #f18c20;
    color: #ffffff !important;
    border-radius: 50%;
  }

  .notifyy .dropdown-menu {
    min-width: unset !important;
  }

  .notifyy .dropdown-menu {
    right: unset !important;
  }

  .updatenoti {
    display: block !important;
  }

  .notcont {
    padding: 6px 11px;
  }

  .millivaheader .dropdown:hover .notimenu {

    padding: 10px 10px !important;
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
@media (min-width: 1200px) {
  .offcanvas {
    visibility: visible;
    position: relative;
    background: none;
    border: none;
    justify-content: end;
  }
}

@media (max-width: 1200px) {

  /* .offcanvas{
    width: 80% !important;
  } */
  .offcanvas {
    width: 250px !important;
  }

  .offcanvas-start-xl {
    top: 0;
    left: 0;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    transform: translateX(-100%);
  }

  .offcanvas2 ul.navbar-nav.d-flex {
    display: flex;
    gap: 13px !important;
    text-align: center;
  }

  .millivaheader svg.bi.bi-x-circle {
    fill: black;

    position: relative;
    top: -5px;
  }
}

.millivaheader .navbar-nav li span {
  color: rgb(10, 9, 9);
  font-size: 1rem;
}

.millivaheader nav.navbar.navbar-expand-xl.navbar-light.bg-light.d-flex.align-items-center {
  padding: 7px 0;
  position: fixed;
  width: 100%;
  z-index: 999;
  background-color: white !important;

  -webkit-box-shadow: 11px 18px 64px 2px rgb(227 227 227);
  -moz-box-shadow: 11px 18px 64px 2px rgba(227, 227, 227, 1);
  box-shadow: 8px 3px 4px #aaaaaa1c;
  height: 72px;
  top: 0;
}

.offcanvas2 ul.navbar-nav.d-flex {
  display: flex;
  gap: 26px;
}

.offcanvas2 a.nav-link.opacc {
  color: black !important;
  padding: 5px 20px;
  border-radius: 5px;
  border: 1px solid #858585;
}

.offcanvas2 a.nav-link.signin {
  background-color: #f29531;
  color: white !important;
  padding: 5px 20px;
  border-radius: 5px;
}

.offcanvas2 a.nav-link.opacc:hover {
  background-color: #f29531;
  color: #fff !important;
}

.offcanvas2 a.nav-link.signin:hover {
  color: rgb(255, 255, 255) !important;
  background-color: black;
}

@media only screen and (min-width: 769px) and (max-width: 1400px) {
  .millivaheader .navbar-nav li a {
    font-size: 13px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1490px) {
  .notifyy .dropdown-menu {
    position: relative;
    right: -51px !important;
    width: 100%;
    min-width: 480px;
    top: 53px !important;
  }
}


@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .footer .footer-top h4 {
    font-size: 13px !important;
  }
}

span.milli {
  font-family: "arimo";
  font-weight: 700;
  font-size: 25px;
  color: black;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
  background: #f6f9ff;
  padding: 0 0 10px 0;
  font-size: 14px;
}

.footer .footer-newsletter {
  padding: 50px 0;
  background: #f6f9ff;
  border-top: 1px solid #e1ecff;
}

.footer .footer-newsletter h4 {
  font-size: 24px;
  margin: 0 0 10px 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  color: #012970;
}

.footer .footer-newsletter form {
  margin-top: 20px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 4px;
  border: 1px solid #e1ecff;
}

.footer .footer-newsletter form input[type="email"] {
  border: 0;
  padding: 8px;
  width: calc(100% - 140px);
}

.footer .footer-newsletter form input[type="submit"] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 30px;
  margin: 3px;
  background: #4154f1;
  color: #fff;
  transition: 0.3s;
  border-radius: 4px;
}

.footer .footer-newsletter form input[type="submit"]:hover {
  background: #5969f3;
}

.footer .footer-top {
  /* background: white url(../images//home/footer) no-repeat right top; */
  background-size: contain;
  border-top: 1px solid #e1ecff;
  border-bottom: 1px solid #e1ecff;
  padding: 60px 0 0px 0;
}

@media (max-width: 992px) {
  a.signinicoimg svg {
    display: block;
  }
}
@media (max-width: 280px) {
  .go-next1 {
    width: 100%;
    max-width: 121px;
    margin: 0 auto !important;
    height: 100%;
    min-height: 155px;
    box-shadow: unset !important;
  }
  
}

@media (min-width: 992px) {
  a.signinicoimg svg {
    display: none;
  }
}

@media (max-width: 992px) {
  .footer .footer-top {
    background-position: center bottom;
  }

  .millivaheader .offcanvas-body {
    position: relative;
    bottom: 20px;
  }

  .millivaheader a.navbar-brand.d-flex.align-items-center {
    display: block !important;
    margin: 0 auto;
    width: 100%;
    max-width: 70%;
}

  .homebox1get {
    display: flex !important;
    text-align: left !important;
    gap: 15px;
    margin-top: 7px;
  }

  .homebox1get h5 {
    text-align: left !important;
  }
}

.footer .footer-top .footer-info {
  margin-bottom: 30px;
}

.footer .footer-top .footer-info .logo {
  line-height: 0;
  margin-bottom: 15px;
}

.homebox1get img.img-fluid {
  width: 100%;
  max-width: 60px;
}

/* 

.footer .footer-top .footer-info .logo span {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 1px;
  color: #012970;
  font-family: "Nunito", sans-serif;
  margin-top: 3px;
} */

.footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Nunito", sans-serif;
}

.footer .footer-top .social-links a {
  font-size: 20px;
  display: inline-block;
  color: rgba(1, 41, 112, 0.5);
  line-height: 0;
  margin-right: 10px;
  transition: 0.3s;
}

.footer .footer-top .social-links a:hover {
  color: #012970;
}

.footer .footer-top h4 {
  font-size: 16px;
  font-weight: bold;
  color: #1e1e1e;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 12px;
}

.footer .footer-top .footer-links {
  margin-bottom: 30px;
}

.footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #d0d4fc;
  font-size: 12px;
  line-height: 0;
}

.footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

.footer .footer-top .footer-links ul a {
  /* color: #757575; */
  transition: 0.3s;
  display: inline-block;
  line-height: 1.3;
}

.footer .footer-top .footer-links ul a:hover {
  color: #f29531;
}

.footer .footer-top .footer-contact p {
  line-height: 26px;
}

.footer .copyright {
  text-align: center;
  padding-top: 13px;
  color: #012970;
}

.footer .credits {
  padding-top: 8px;
  text-align: center;
  font-size: 13px;
  color: #012970;
}

.millivaheader .dropdown-menu {
  /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); */
  border: 1px solid rgb(234, 234, 234);
  border-radius: 0;
  padding: 0.7em;
}

.millivaheader .dropdown:hover .mainmenus {
  display: flex;
}


.millivaheader .dropdown-menu.show {
  display: flex;
  /* position: fixed; */

  padding: 0;
}

span#navbarDropdown {
  pointer-events: none;
}

.millivaheader .dropdown-menu ul {
  list-style: none;
  padding: 0 24px;
}

.millivaheader .dropdown-menu li .dropdown-item {
  color: gray;
  font-size: 1em;
  padding: 0.5em 1em;
  cursor: pointer;
}

.millivaheader .dropdown-menu li .dropdown-item:hover {
  background-color: #f1f1f1;
}

.millivaheader .dropdown-menu li:first-child a {
  font-weight: bold;
  font-size: 1rem;
  text-transform: uppercase;
  color: #f29531;
}

.millivaheader .dropdown-menu li:first-child a:hover {
  background-color: #f1f1f1;
}

@media only screen and (min-width: 280px) and (max-width: 1024px) {
  .millivaheader .dropdown-menu.show {
    position: unset !important;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1486px) {
  .millivaheader .dropdown-menu.show {
    position: absolute !important;
  }
}

@media only screen and (max-width: 992px) {
  .millivaheader .dropdown-menu.show {
    flex-wrap: wrap;
    max-height: 350px;
    overflow-y: scroll;
  }

  .signcont {
    width: 80%;
  }

  .millilgo {
    display: none;
  }

  .social-home i.fa.fa-telegram {
    font-size: 12px;
  }

  .social-home i.fa.fa-pinterest {
    font-size: 12px;
  }

  .col-lg-5.abt-img img {
    margin: 0 auto;
    display: block;
  }

  .about h2 {
    text-align: center !important;
  }

  .about p {
    text-align: center !important;
  }

  .about button {
    margin: 0 auto !important;
    display: block;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1140px) {
  .millivaheader .dropdown:hover .dropdown-menu {
    width: 100%;
    flex-wrap: wrap;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .millivaheader .dropdown:hover .dropdown-menu {
    display: block !important;
    padding: 0;
  }

  .millivaheader .dropdown-menu ul {
    padding: 0 !important;
  }

  .millivaheader .dropdown-menu {
    padding: 0 !important;
  }
}

/******************************************BANNER*****************************************/

.banner {
  position: relative;
  padding-top: 114px;
}

.banner-texy h1 {
  font-weight: 500;
}

.banner p {
  font-size: 1.2rem;

}

.banner-texy h5 {
  line-height: 2rem;
  font-weight: 400;
  font-size: 1.2rem;
  padding: 0 80px 5px 0px;
}

.banner-texy h5 span {
  font-size: 26px;
  color: #000;
  line-height: 3.5rem;
}

button.btn.btn-primary.trade-now-but {
  font-weight: 0;
  font-size: 14px;
  color: #fff;
  background-color: #f18c20;
  padding: 10px 30px;
  border: solid #f18c20 2px;
  box-shadow: rgb(0, 0, 0) 0px 0px 0px 0px;
  border-radius: 5px;
  transition: 1000ms;
  transform: translateY(0);
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

button.btn.btn-primary.trade-now-but:hover {
  transition: 1000ms;
  padding: 10px 50px;
  transform: translateY(-0px);
  background-color: #fff;
  color: #f18c20;
  border: solid 2px #f18c20;
}

.why-choose-first {
  margin: 0 auto;
  display: block;
  width: 100%;
  text-align: center;
}

.why-choose-first p {
  padding: 2px 0 35px 0;
  width: 80%;

  margin: 0 auto;
}

.why-choose ul li p {
  text-align: center;
}

.why-choose ul li img {
  margin: 0 auto;
  display: block;
  width: 100%;
  max-width: 100px;
}

.why-choose ul li h5 {
  text-align: center;
  padding: 10px 0 0;
}

/* Slick Slider */

.slick-slide {
  margin: 0px 20px;
}

.slick-slide img {
  width: 100%;
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-track img {
  max-width: 76px !important;
  height: 76px;
  display: flex;
  margin: 0 auto !important;
  padding: 0 !important;
  width: 100%;
}

.slick-track h5 {
  text-align: center;
  margin: 0;
}

.slick-track p {
  text-align: center;
}

.about {
  background-color: #f9fafc;
  /* height: 400px; */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 10px 0 0 0;
}

.about h2 {
  text-align: left;
  color: rgb(0, 0, 0);
  padding: 83px 0 0 0;
}

.about p {
  color: rgb(0, 0, 0);
  text-align: justify;
  width: 100%;
  width: 100%;
  margin: 20px auto;
}

.col-lg-5.abt-img img {
  width: 100%;
  max-width: 467px;
  padding: 10px 0 0 0;
}

/* .slick-track img{

  -webkit-animation: myAnim 3s infinite;
    animation: myAnim 3s infinite;
}

@keyframes myAnim {
0% {
  transform: rotate(0);
}

100% {
  transform: rotate(360deg);
}
} */
/* .btn:hover {
  color: var(--bs-btn-hover-color) !important;
  background-color: #f18c1f !important;
  border-color: #f18c1f  !important;
} */
ul.beginner-design li:first-child a {
  display: none;
}

ul.beginner-design {
  padding: 0 !important;
}

button.btn.btn-primary.menu-orangebut {
  background: #f29531;
  border: navajowhite;
  margin: 10px 11px;
}

li.open-demo-but a.dropdown-item:hover {
  background-color: unset !important;
}

img.img-fluid.mx-2.partner-nav-img {
  width: 500px !important;
  max-width: 108px !important;
  height: 100% !important;
}

ul.beginner-design-part {
  padding: 10px !important;
}

img.img-fluid.mx-2.partner-nav-img1 {
  max-width: 153px;
  padding: 2px;
  margin: 25px 0 0 0;
}

img.img-fluid.mx-2.partner-nav-img2 {
  max-width: 77px;
}

ul.campaign-des {
  padding: 0 !important;
  width: 100% !important;
}

img.img-fluid.mx-2.partner-nav-img3 {
  max-width: 134px;
}

@media screen and (max-width: 768px) {
  img.img-fluid.mx-2.partner-nav-img2 {
    width: 100% !important;
    max-width: 120px;
    justify-content: center;
    display: flex;
    padding: 10px;
  }

  .footer .copyright{
    font-size: 11px;
  }
  .footer {
   
    padding: 0 0 17px 0;
   
}
  .banner {

    text-align: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  button.welcome_popup_cls {
    width: 20px !important;
    height: 20px !important;
    border-radius: 50%;
    top: 8px !important;
    right: 6px !important; 
    position: absolute;
    box-shadow: none;
    border: 1px solid #666;
    background: transparent;
    color: #fff;
    font-size: 11px !important;
    line-height: 18px !important;
}

  .account-type-home .pricing .plan button {
    margin-bottom: 10px;
  }

  .trade-assets-home .cards {
    display: block !important;
  }

  .millivaheader .dropdown-menu li .dropdown-item {

    padding: 0.5em 0em;

  }
}

.banner .col-lg-6:nth-child(1) {
  align-items: center;
  display: flex;
}

.why-choose {
  padding: 10px 0 20px 0;
  margin-top: -50px !important;
}

/* .banner::before{
  background-image: url(../img/home/graph.png);
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  content: '';
} */
button.btn.btn-primary.trade-now-but.about-but {
  margin: 19px 0;
}

/**********************************************Trade Asset*************************************************************/

.trade-asset-home-titl h2 {
  text-align: center;
  padding: 20px 0 4px 0;
}

.trade-asset-home-titl p {
  text-align: center;
}

/* .teade-asses-home img{
  height:150px;
  width:100%;
} */

.teade-asses-home .item {
  padding-left: 20px;
  padding-right: 20px;
}

.teade-asses-home .item-card {
  transition: 0.5s;
  cursor: pointer;
  height: 100%;
}

.teade-asses-home .item-card-title {
  font-size: 15px;
  transition: 1s;
  cursor: pointer;
}

.teade-asses-home .item-card-title i {
  font-size: 15px;
  transition: 1s;
  cursor: pointer;
  color: #ffa710;
}

.teade-asses-home .card-title i:hover {
  transform: scale(1.25) rotate(100deg);
  color: #18d4ca;
}

.teade-asses-home .card:hover {
  transform: scale(1.05);
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.3);
}

.teade-asses-home .card-text {
  height: 80px;
}

.teade-asses-home .card::before,
.card::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: scale3d(0, 0, 1);
  transition: transform 0.3s ease-out 0s;
  background: rgba(255, 255, 255, 0.1);
  content: "";
  pointer-events: none;
}

.teade-asses-home .card::before {
  transform-origin: left top;
}

.teade-asses-home .card::after {
  transform-origin: right bottom;
}

.teade-asses-home .card:hover::before,
.card:hover::after,
.card:focus::before,
.card:focus::after {
  transform: scale3d(1, 1, 1);
}

.teade-asses-home h2 {
  text-align: center;
  
}

/* .teade-asses-home p{
  text-align: center;
  padding: 3px 0 40px 0;
} */

.teade-asses-home {
  padding: 22px 10px;
  background-color: #f9fafc;
  height: 100%;
  margin-top: -25px !important;
}

/******************************************account-typs****************************************/
.account-type-home h1 {
  text-align: center;
  margin-top: 2rem;
}

.account-type-home p {
  text-align: center;
  margin-bottom: 4rem;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 4rem;
}

.account-type-home .pricing {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.account-type-home .pricing .plan {
  background-color: #fff;
  padding: 2.5rem;
  margin: 12px;
  border-radius: 5px;
  text-align: center;
  transition: 0.3s;
  cursor: pointer;
  border: 1px solid #f29531;
}

.account-type-home .pricing .plan h2 {
  font-size: 2rem;
  margin-bottom: 12px;
  background: #a57d24;
  background: linear-gradient(to top left,
      #a57d24 0%,
      #e4cd45 50%,
      #a57d24 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.account-type-home .pricing .plan .price {
  margin-bottom: 1rem;
  font-size: 1.8rem;
  font-weight: 500;
}

.account-type-home .pricing .plan ul.features {
  list-style-type: none;
  text-align: left;
}

.account-type-home .pricing .plan ul.features li {
  margin: 8px;
  padding: 10px 0 0;
  text-align: left;
  font-size: 1.1rem;
}

.account-type-home .pricing .plan ul.features li .fas {
  margin-right: 4px;
}

.account-type-home .pricing .plan ul.features li .fa-check-circle {
  color: #6ab04c;
}

.account-type-home .pricing .plan ul.features li .fa-times-circle {
  color: #eb4d4b;
}

.account-type-home .pricing .plan button {
  font-weight: 0;
  font-size: 14px;
  color: #fff;
  background-color: #f18c20;
  padding: 9px 50px;
  border: solid #f18c20 2px;
  box-shadow: rgb(0, 0, 0) 0px 0px 0px 0px;
  border-radius: 8px;
  transition: 1000ms;
  transform: translateY(0);
  /* display: flex; */
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  text-align: center;
}

.account-type-home .pricing .plan button:hover {
  transition: 1000ms;
  padding: 10px 50px;
  transform: translateY(-0px);
  background-color: #fff;
  color: #f18c20;
  border: solid 2px #f18c20;
}

.account-type-home .pricing .plan.popular {
  border: 2px solid #f29531;
  position: relative;
  transform: scale(1.08);
}

.account-type-home .pricing .plan.popular span {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f29531;
  color: #fff;
  padding: 4px 20px;
  font-size: 18px;
  border-radius: 5px;
}

.account-type-home .pricing .plan:hover {
  box-shadow: 5px 7px 67px -28px rgba(0, 0, 0, 0.37);
}

.account-type-home h2 {
  text-align: center;
}

.account-type-home .pricing h2 {
  text-transform: uppercase;
}

/***********************************************************get started******************************************************/

.homese .homebox1get img.img-fluid {
  max-width: 50px;
  max-height: 50px;
  width: 100%;
}

.homebox1get h5 {
  font-size: 17px;
  font-weight: 600;
}

.homebox1get p {
  color: gray;
}

/* .getimg img.img-fluid {
  position: relative;
  right: -65px !important;
} */

@media only screen and (min-width: 769px) and (max-width: 2000px) {
  /* .getimg img.img-fluid {
    position: relative;
    right: unset !important;
   } */
}

@media screen and (max-width: 1020px) {
  /* .homebox1get {
    margin: 5px auto;
    display: block;
    text-align: center;
  } */
}

.openlivebtn button.btn.btn-primary {
  background-color: #f18c20;
  border: none;
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

.opendemobtn button.btn.btn-primary {
  background-color: #909090 !important;
  border: none;
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

.homesec .getsbtn {
  gap: 10px;
}

/***********************************************************get started******************************************************/

.banner .carousel-item {
  transition: opacity 0.3s ease-in-out !important;
}

/*************************************************************mt5**********************************************************/

.mt5 .banner {
  padding: 0;
}

.col-lg-6.mt5-trade-cont {
  align-items: center;
  display: flex;
}

.mt-trade1-img img {
  width: 100%;
  height: auto;
  max-width: 553px;
  max-height: 495px;
}

img.vert-move {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

img.vert-move {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 2s infinite alternate;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-30px);
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-30px);
  }
}

.mt-sm-img1 img {
  position: absolute;
  top: 130px;
  width: 100%;
  max-width: 206px;
  height: auto;
  left: 10px;
}

.mt-sm-img2 img {
  top: 300px;
  position: absolute;
  right: 30px;
  width: 100%;
  max-width: 220px;
  height: auto;
}

.teade-asses-home img {
  width: 129px;
  height: auto;
  margin: 0 auto;
  display: block;
}

.teade-asses-home h5 {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .teade-asses-home img {
    margin: 0 auto;
    display: block;
  }
  .advantage {
    
    margin-top: -21px;
}
  .footer .footer-top .footer-links {
    margin: 8px 0 !important;
}

  .millivaheader .dropdown-menu ul li {
    font-size: 13px;
  }
}

@media screen and (max-width: 992px) {
  .millivaheader .dropdown:hover .dropdown-menu {
    display: block !important;
  }

  .millivaheader .dropdown-menu ul {
    padding: 0 !important;
  }


  /* .millivaheader .dropdown-menu {
    padding: 0 !important;
  } */
  /* .millivaheader .dropdown-menu{
    display: block !important;
  } */
}

/***************************************************************************advantage*******************************************/
.advantage .card {
  width: 100%;
  height: 100%;
  background: #fff;
  box-shadow: 0 10px 10px #ccc;
}

.advantage .card .image {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 20px 0 0 0;
}

.advantage .card .image img {
  width: 100%;
  transition: 0.5s;
}

.advantage .card:hover .image img {
  opacity: 0.5;
  transform: translateX(30%);
  /*100%*/
}

.advantage .card .details {
  position: absolute;
  top: 0;
  left: 0;
  width: 98%;
  /*100%*/
  height: 100%;

  transition: 0.5s;
  transform-origin: left;
  transform: perspective(2000px) rotateY(-90deg);
}

.advantage .card:hover .details {
  transform: perspective(2000px) rotateY(0deg);
}

.advantage .card .details .center {
  padding: 2px 8px;
  text-align: center;
  background: #fff;
  position: absolute;
  top: 49%;
  transform: translateY(-50%);
  height: 100%;
}

.advantage .card .details .center h1 {
  margin: 0;
  padding: 0;
  color: #ff3636;
  line-height: 20px;
  font-size: 20px;
  text-transform: uppercase;
}

.advantage .card .details .center h1 span {
  font-size: 14px;
  color: #262626;
}

.advantage .card .details .center p {
  margin: 10px 0;
  padding: 0;
  color: #262626;
}

.advantage .card .details .center ul {
  margin: 10px auto 0;
  padding: 0;
  display: table;
}

.advantage .card .details .center ul li {
  list-style: none;
  margin: 0 5px;
  float: left;
}

.advantage .card .details .center ul li a {
  display: block;
  background: #262626;
  color: #fff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  transform: 0.5s;
}

.advantage .card .details .center ul li a:hover {
  background: #ff3636;
}

.advantage {
  /* padding: 90px 0; */
}

.advantage .card.advan1 h2 {
  font-size: 1.5rem;
  padding: 20px 17px 0 0;
  text-align: center;
  background: #a57d24;
  background: linear-gradient(to left, #a57d24 0%, #f8eb9b 31%, #a57d24 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}

.advantage .card.advan1 p {
  padding: 20px 17px;
  text-align: center;
}

.advantage .card.advan1 .image img {
  width: 100%;
  max-width: 70px;
  margin: 0 auto;
  display: block;
  position: relative;
  top: 31px;
}

ul.desc-list {
  text-align: left;
}

ul.desc-list li {
  line-height: 19px;
  font-size: 0.9rem !important;
  margin: 4px 0 !important;
}

ul.desc-list li {
  background-image: url(../images/home/advvan-chart.png);
  background-repeat: no-repeat;
  padding: 2px 0 5px 31px;
  background-position: 0px -7px;
}

.advantage .card.advan2 p {
  padding: 20px 17px;
  text-align: center;
}

.advantage .card.advan2 .image img {
  width: 100%;
  max-width: 70px;
  margin: 0 auto;
  display: block;
  position: relative;
  top: 31px;
}

.advantage .card.advan2 h2 {
  font-size: 1.5rem;
  padding: 20px 7px;
  text-align: center;
  background: #a57d24;
  background: linear-gradient(to left, #a57d24 0%, #f8eb9b 31%, #a57d24 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}

.advantage .card.advan3 p {
  padding: 20px 17px;
  text-align: center;
}

.advantage .card.advan3 .image img {
  width: 100%;
  max-width: 70px;
  margin: 0 auto;
  display: block;
  position: relative;
  top: 31px;
}

.advantage .card.advan3 h2 {
  font-size: 1.5rem;
  padding: 20px 7px;
  text-align: center;
  background: #a57d24;
  background: linear-gradient(to left, #a57d24 0%, #f8eb9b 31%, #a57d24 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}

.advantage .card.advan4 p {
  padding: 20px 17px;
  text-align: center;
}

.advantage .card.advan4 .image img {
  width: 100%;
  max-width: 70px;
  margin: 0 auto;
  display: block;
  position: relative;
  top: 31px;
}

.advantage .card.advan4 h2 {
  font-size: 1.5rem;
  padding: 20px 7px;
  text-align: center;
  background: #a57d24;
  background: linear-gradient(to left, #a57d24 0%, #f8eb9b 31%, #a57d24 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}

.advantage .col-lg-3 {
  margin: 0 0 30px 0;
}

/****************************introducing brokker******************************************/
.indrocing-brok h4 {
  color: #f29531;
  font-weight: 400;
}

.indrocing-brok .col-lg-5.abt-img img {
  margin: 40px 0 0 0;
  max-width: unset;
}

/*************************************************************News***********************************************************/
.news-blog .why-choose-first {
  padding: 50px 0 0 0;
}

.news-blog .card {
  flex: 1;
  flex-basis: 300px;
  flex-grow: 0;

  background: #fff;
  border: 2px solid #fff;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.8, 0.5, 0.2, 1.4);
  overflow: hidden;
  position: relative;
  margin: 30px 0;
  height: 140px;
  width: 100%;
  max-width: 280px;
}

.news-blog .card img {
  width: 100%;
  height: 100%;
  transition: all 0.5s cubic-bezier(0.8, 0.5, 0.2, 1.4);
  margin: 0 !important;
  max-width: 100% !important;
}

.news-blog .descriptions {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #fff;
  width: 100%;
  height: 100%;
  transition: all 0.7s ease-in-out;
  padding: 20px;
  box-sizing: border-box;
  clip-path: circle(0% at 100% 100%);
}

.news-blog .card:hover .descriptions {
  left: 0px;
  transition: all 0.7s ease-in-out;
  clip-path: circle(75%);
}

.news-blog .card:hover {
  transition: all 0.5s cubic-bezier(0.8, 0.5, 0.2, 1.4);
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
  transform: scale(0.97);
}

.news-blog .card:hover img {
  transition: all 0.5s cubic-bezier(0.8, 0.5, 0.2, 1.4);
  transform: scale(1.6) rotate(20deg);
  filter: blur(3px);
}

.news-blog .card h5 {
  color: #f29531;
  margin: 0px;
  font-size: 1.2rem;
}

.news-blog .card p {
  line-height: 24px;
  height: 70%;
}

.news-blog .card button {
  width: fit-content;
  height: 40px;
  cursor: pointer;
  border-style: none;
  background-color: #ff3838;
  color: #fff;
  font-size: 15px;
  outline: none;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.4);
  transition: all 0.5s ease-in-out;
}

.news-blog .card button:hover {
  transform: scale(0.95) translateX(-5px);
  transition: all 0.5s ease-in-out;
}

.news-blog {
  padding: 50px 0;
}

.card.blog-home2 {
  position: relative;
  left: 112px;
  top: 0;
}

.news-blog .col-lg-7 {
  align-items: center;
  display: block;
  position: relative;
  top: 22px;
  padding: 0 55px;
}

.why-choose-first.news-blog-title h2 {
  text-align: left;
  padding: 10px 0 10px 0;
}

/*****************************************************go-next***************************************************/
.go-next1 img {
  width: 100%;
  max-width: 122px;
  height: auto;
  margin: 0 auto;
  display: block;
}

.go-text1 h4 {
  text-align: center;
  position: relative;
  top: 21px;
}

.go-next {
  padding: 30px 0 20px 0;
}

.go-next1 {
  background: #f9fafc;
  padding: 21px 0 0 0;
  border-radius: 20px;
  box-shadow: 0 5px 3px #ccc;
  width: 100%;
  max-width: 339px;
  margin: 0 auto;
}

.go-text1 {
  padding: 0 0 34px 0;
  margin: 10px 0;
}

.go-text1:hover {
  background: #c7a74c;
  /* Old browsers */
  background: -moz-linear-gradient(-45deg,
      #c7a74c 0%,
      #f8eb9b 50%,
      #a57d24 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg,
      #c7a74c 0%,
      #f8eb9b 50%,
      #a57d24 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg,
      #c7a74c 0%,
      #f8eb9b 50%,
      #a57d24 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#c7a74c', endColorstr='#a57d24', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */

  color: #806e3b;
  border-radius: 0 35px 0 10px;
  transition: 0.5s;
}

/****************************************************brand-logo**********************************************/
.accslider {
  width: 90%;
  margin: 0 auto;
}

.accsli1 img.img-fluid {
  width: 100%;
  max-width: 140px;
}

.fastsectrade.brand-home {
  padding: 20px 0 0 0;
}

/*****************************************************************foooter-logo*******************************************/
.footer-logo {
  background: #eff3fc;
  padding: 17px 0 10px 0;
}

.footer-logo p {
  padding: 0px 129px 0 26px;
}

.social-home ul li {
  list-style: none;
}

.social-home ul li a {
  padding: 10px;
}

.social-home {
  float: right;
  margin: 4% 0 0 0;
}

.social-home ul li a i.fa.fa-facebook {
  background: radial-gradient(ellipse farthest-corner at right bottom,
      #e8ca3c 0%,
      #ffe135 8%,
      #ffc854 30%,
      #ffd471 40%,
      transparent 80%),
    radial-gradient(ellipse farthest-corner at left top,
      #ffffff 0%,
      #ffffac 8%,
      #ffb300 25%,
      #fff35d 62.5%,
      #fad47f 100%);

  padding: 14px;
  border-radius: 6px;
  color: #000;
}

.social-home ul li a i svg {
  background: radial-gradient(ellipse farthest-corner at right bottom, #e8ca3c 0%, #ffe135 8%, #ffc854 30%, #ffd471 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #ffffff 0%, #ffffac 8%, #ffb300 25%, #fff35d 62.5%, #fad47f 100%);
  padding: 14px;
  border-radius: 6px;
  fill: #000;
  height: 45px;
  width: 40px;
}

.social-home ul li a i.fa.fa-facebook:hover {
  background: #3a559f;
  color: #fff;
}

.social-home ul li a i.fa.fa-twitter {
  background: radial-gradient(ellipse farthest-corner at right bottom,
      #e8ca3c 0%,
      #ffe135 8%,
      #ffc854 30%,
      #ffd471 40%,
      transparent 80%),
    radial-gradient(ellipse farthest-corner at left top,
      #ffffff 0%,
      #ffffac 8%,
      #ffb300 25%,
      #fff35d 62.5%,
      #fad47f 100%);

  border-radius: 6px;
  color: #000;
  padding: 14px;
}

.social-home ul li a i.fa.fa-twitter:hover {
  background: #1cb7eb;
  color: #fff;
}

.social-home ul li a i.fa.fa-instagram {
  background: radial-gradient(ellipse farthest-corner at right bottom,
      #e8ca3c 0%,
      #ffe135 8%,
      #ffc854 30%,
      #ffd471 40%,
      transparent 80%),
    radial-gradient(ellipse farthest-corner at left top,
      #ffffff 0%,
      #ffffac 8%,
      #ffb300 25%,
      #fff35d 62.5%,
      #fad47f 100%);

  padding: 14px;
  border-radius: 6px;
  color: #000;
}

.social-home ul li a i.fa.fa-instagram:hover {
  background: #f09433;
  background: -moz-linear-gradient(45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%);
  background: -webkit-linear-gradient(45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%);
  background: linear-gradient(45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
  color: #fff;
}

.social-home ul li a i.fa.fa-telegram {
  background: radial-gradient(ellipse farthest-corner at right bottom,
      #e8ca3c 0%,
      #ffe135 8%,
      #ffc854 30%,
      #ffd471 40%,
      transparent 80%),
    radial-gradient(ellipse farthest-corner at left top,
      #ffffff 0%,
      #ffffac 8%,
      #ffb300 25%,
      #fff35d 62.5%,
      #fad47f 100%);
  padding: 14px;
  border-radius: 6px;
  color: #000;
}

.social-home ul li a i.fa.fa-telegram:hover {
  background: #007ebb;
  color: #fff;
}

.social-home ul li a i.fa.fa-paper-plane {
  background: radial-gradient(ellipse farthest-corner at right bottom,
      #e8ca3c 0%,
      #ffe135 8%,
      #ffc854 30%,
      #ffd471 40%,
      transparent 80%),
    radial-gradient(ellipse farthest-corner at left top,
      #ffffff 0%,
      #ffffac 8%,
      #ffb300 25%,
      #fff35d 62.5%,
      #fad47f 100%);
  padding: 14px;
  border-radius: 6px;
  color: #000;
}

.social-home ul li a i.fa.fa-paper-plane:hover {
  background: #626262;
  color: #fff;
}

.social-home ul li a i.fa.fa-youtube-play {
  background: radial-gradient(ellipse farthest-corner at right bottom,
      #e8ca3c 0%,
      #ffe135 8%,
      #ffc854 30%,
      #ffd471 40%,
      transparent 80%),
    radial-gradient(ellipse farthest-corner at left top,
      #ffffff 0%,
      #ffffac 8%,
      #ffb300 25%,
      #fff35d 62.5%,
      #fad47f 100%);
  padding: 14px;
  border-radius: 6px;
  color: #000;
}

.social-home ul li a i.fa.fa-youtube-play:hover {
  background: #fc2121;
  color: #fff;
}

.footer-bottom ul li {
  list-style: none;
}

.footer-bottom ul {
  display: flex;
  padding: 0;
  gap: 4px;
}

.footer-bottom ul li a {
  /* padding: 0 8px; */
  color: #757575;
}

.footer-bottom ul li a:hover {
  color: #f29531;
}

.risk-footer p span {
  font-weight: 500;
}

.risk-footer p a {
  color: #f29531;
}

.footer-bottom {
  border-top: 1px solid #d5d9df;
  padding: 18px 0;
}

/***********************************************************************rate-numner*********************************/
.milliva-rated h3 {
  text-align: center;
}

.milliva-rated p {
  text-align: center;
}

.milliva-rated {
  padding: 30px 0;
  background-color: #f6f7f8;
}

/***************************************************mt5***********************************************/
.mt5 {
  padding: 50px 0 30px 0 !important;
}

@media screen and (max-width: 768px) {
  .banner-texy h1 {
    text-align: center;
    font-size: 2rem;
    padding: 0px 0 20px 0;
  }

  .social-home ul li a i svg {
    
    height: 40px;
    width: 40px;
}


  .mt5 {
    padding: 26px 0 10px 0 !important;
    text-align: center;
    width: 100%;
  }

  .go-next1 {
    width: 100%;
    max-width: 137px;
    margin: 0 auto !important;
    height: 100%;
    min-height: 155px;
    box-shadow: unset !important;
  }

  .go-text1 {
    
    position: relative;
    top: 9px;
}
.homesec h2{
  font-size: 20px;
}
.homesec p{
  font-size: 1rem;
}
  

  .go-text1 h4 {
   
    font-size: 14px;
}
.go-next1 img{
  max-width: 86px;
}

  .footer .col-lg-2.col-6 {
    width: 50% !important;
  }

  .ios {
    display: none;
  }

  .windowss {
    display: none;
  }

  .why-choose-first p {
    width: 100%;
  }

  .account-type-home p {
    width: 100%;
  }

  .banner-texy h5 {
    padding: 0;
  }

  .news-blog {
    padding: unset;
  }



  .banner-texy h5 span {
    line-height: 1;
    text-align: center;
  }

  .banner-texy h5 {
    text-align: center;
  }



  .advantage .card {
    width: 100% !important;
    height: 100%;
    margin: 0 auto;
    display: block;
    max-width: 93%;
  }

  .advantage .col-lg-3 {
    padding: 10px 0 19px 0;
  }

  .card.blog-home2 {
    left: 0;
  }

  .footer-logo p {
    width: 100%;
    text-align: center;
    padding: 14px 0;
  }

  .footer-logo.footer-info a.navbar-brand img {
    margin: 0 auto !important;
    display: block;
  }

  .account-type-home .pricing .plan {
    padding: 0;
    margin: 29px 0 51px 0;
  }

  .mt-sm-img1 img {
    top: 0;
    width: 100%;
    max-width: 40% !important;
  }

  .mt-sm-img2 img {
    top: 120px;
    max-width: 52%;
  }

  .indrocing-brok h2 {
    text-align: center;
  }

  .indrocing-brok h4 {
    text-align: center;
  }

  .indrocing-brok p {
    text-align: center;
  }

  .advantage {
    padding: 0;
  }

  .news-blog .col-lg-7 {
    padding: 0;
  }

  .news-blog .col-lg-7 h2 {
    text-align: center;
  }

  .news-blog .col-lg-7 h4 {
    text-align: center;
    padding: 0 20px;
  }

  .news-blog .col-lg-7 p {
    text-align: center;
    padding: 0 20px;
    font-size: 17px;
  }

  .go-next1 {
    margin: 0 0 32px 0;
  }

  .fastsectrade.brand-home p {
    text-align: center;
  }

  .news-blog .slick-slide {
    width: 100% !important;
    margin: 0 auto;
  }

  .news-blog .card {
    margin: 30px auto;
  }

  .accsli1 img.img-fluid {
    margin: 0 auto;
    display: block;
  }

  .advantage .card.advan1 h2 {
    text-align: center;
    font-size: 1.3rem;
    padding: 20px 20px 0;
  }

  .account-type-home .pricing {
    display: block;
    width: 88%;
    margin: 0 auto;
  }

  .account-type-home .pricing h2 {
    padding: 18px 0 0 0;
  }

  .account-type-home .pricing .plan.popular span {
    width: 71%;
  }

  .account-type-home {
    padding: 0 !important;
  }

  .slick-prev,
  .slick-next {
    display: none !important;
  }

  .go-next {
    padding: 20px 0 0 0 !important;
  }

  .footer-top .col-lg-2.col-6.footer-links {
    display: block !important;
    width: 100%;
    margin: 20px auto;
  }

  .social-home i.fa.fa-linkedin {
    font-size: 12px;
  }

  .social-home i.fa.fa-instagram {
    font-size: 12px;
  }

  .social-home i.fa.fa-paper-plane {
    font-size: 12px;
  }

  .social-home i.fa.fa-youtube-play {
    font-size: 12px;
  }

  .social-home i.fa.fa-facebook {
    font-size: 12px;
  }

  .social-home .twitter svg {
    font-size: 12px;
  }
 
  .social-home ul li a {
    
      padding: 0 10px 0 0;
  
  }

  .social-home {
    float: unset;
    justify-content: center;
    /* display: flex !important; */
  }

  .social-home ul {
    padding: 0;
    justify-content: center;
  }

  .about h2 {
    padding: 10px 0;
    text-align: center;
  }

  .about p {
    text-align: center;
  }

  .millivaheader .btn:hover {
    color: var(--bs-btn-hover-color);
    background-color: #f28d20;
    border-color: #f6ad5e;
  }

  .millivaheader button.btn.btn-danger.navbar-toggler.border-3.px-2 {
    outline: unset !important;
  }

  .advantage .card .details .center ul li {
    float: unset;
  }

  .banner-texy h2 {
    text-align: center;
  }
}

@media screen and (max-width: 1200px) {
  .go-next1 {
    margin: 20px 0;
  }

  .home-mt-sec {
    padding: unset !important;

  }

  .advantage .card .details .center ul li {
    float: unset;
  }

  .card.blog-home2 {
    left: 0;
  }
}

@media screen and (max-width: 992px) {
  .banner-texy h1 {
    text-align: center;
  }

  button.btn.btn-primary.trade-now-but {
    margin: 25px auto !important;
    display: block;
  }




  .banner-texy h2 {
    text-align: center;
  }

  .indrocing-brok h2 {
    text-align: center;
  }

  .indrocing-brok h4 {
    text-align: center;
  }

  .indrocing-brok p {
    text-align: center;
  }

  .indrocing-brok button {
    margin: 0 auto !important;
    display: block !important;
  }
}

h1 {
  background: linear-gradient(to left, #0c0101 5%, #ef973a 70%, #bebbbb 60%);
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;

  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2.5s linear infinite;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.valuesec {
  gap: 12px;
}

span.cval {
  color: red;
  font-size: 13px;
}

span.cval1 {
  color: green;
  font-size: 13px;
}

a.topbtn {
  color: #f18c20;
  font-size: 35px;
  position: fixed;
  right: 15px;

  bottom: 13px;
}

.go-next a {
  color: #000;
}

.social-home ul li a i.fa.fa-pinterest {
  background: radial-gradient(ellipse farthest-corner at right bottom,
      #e8ca3c 0%,
      #ffe135 8%,
      #ffc854 30%,
      #ffd471 40%,
      transparent 80%),
    radial-gradient(ellipse farthest-corner at left top,
      #ffffff 0%,
      #ffffac 8%,
      #ffb300 25%,
      #fff35d 62.5%,
      #fad47f 100%);

  padding: 14px;
  border-radius: 6px;
  color: #000;
}

.social-home ul li a i.fa.fa-pinterest:hover {
  background: #ec4242;
  color: #fff;
}

h6.ios-home {
  position: relative;
  top: 18px;
}

.ios-mac-line {
  position: relative;
  top: 13px;
  left: -32px;
  font-size: 2rem;
  color: #898989;
  font-weight: 300;
}

.appdownloadicons {
  justify-content: center;
}

@media screen and (max-width: 992px) {
  .appdownloadicons {
    height: unset !important;
  }

  img.img-fluid.mx-3.mac-lap {
    display: none !important;
  }

  img.img-fluid.mx-3.ios-mobi {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .appicon1 img {
    float: left;
    margin: 0 !important;
  }

  .banner .banner-texy p {
    text-align: center;
    font-size: 18px;
  }

  .banner p {
    padding: unset !important;
  }

  .col-lg-5.abt-img img {
    display: none;
  }

  .about p {
    margin: unset !important;
  }

  .homeget {
    display: none;
  }

  .why-choose {
    margin-top: -50px !important;
  }

  .mt5-trade1 .banner {
    padding: unset !important;
    display: none;
  }
  .getmt5.mt-4.pb-5.pt-4 {
    margin: 0 !important;
    padding-bottom: 27px !important;
}

.getmt5.mt-4.pb-5.pt-4 .row {
  margin: 0 !important;
  
}

  .banner-img.mt-trade1-img {
    display: none;

  }
  .account-type-home p{
    margin-bottom: unset !important;
  }
  

  .banner-texy button.btn.btn-primary.trade-now-but {
    margin: 0 auto !important;
    display: block;
  }
}

img.img-fluid.mx-3.ios-mobi {
  float: left;
  margin: 0 !important;
  padding: 6px;
}

img.img-fluid.mx-3.mac-lap {
  float: right;
  margin: 0 !important;
  padding: 6px;
}

.meta5.home-mt5-sec {
  width: 100%;
  margin: 0 !important;
}

.meta5.home-mt5-sec img.img-fluid.mx-3 {
  margin: 0 !important;
}

.meta5.home-io-mc {
  width: 100%;
}

.home-io-mc .appicon1 img {
  margin: 0 !important;
}

@media only screen and (max-width: 1300px) and (min-width: 992px) {
  .home-mt-sec .appdownloadicons {
    border: unset !important;
  }

  .home-mt-sec .appdownloadicons:hover {
    background-color: unset;
  }

  .home-mt-sec .appdownloadicons .appicon1 img {
    display: none;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 992px) {
  .mt-sm-img2 img {
    top: 237px;
    right: -13px;
  }
}

.home-mt-sec .col-md-12.col-lg-4 {
  padding: 0 6px;
}

.home-mt-trader {
  text-align: left;
}

/* .mt5-trade1 {
  padding: 20px 0;
} */

.home-mt-sec {
  padding: 10px 0;
}

.bannerhead h1 {
  font-size: 2rem;

}

.banner .banner-texy {
  position: relative;
  top: -30px;
}

.banner .carousel-control-next,
.carousel-control-prev {
  position: unset;
}

.flip-box-front h3 {
  padding: 10px 0;
  font-size: 1.5rem;
}

.appdownloadicons:hover {
  background-color: #f3f3f3;
}

.getsbtn a:hover {
  color: #f18c20;
}

.acctype3 h5 {
  text-transform: uppercase;
}

p.getapara.algo-join {
  display: block;
}

.phyadd {
  font-size: 8px;
}

::-webkit-scrollbar {
  width: 3px;
  height: 0px;
}

::-webkit-scrollbar-track {
  background: #f18c20;
}

::-webkit-scrollbar-thumb {
  background: #b3b3b3;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

span.handleput {
  cursor: pointer;
}

span.handleput:hover {
  color: #f18c20;
}

.sidebaroffcanvas {
  position: fixed !important;
  background: #fff !important;
}

.millilogin input {
  border-radius: unset;
  height: 45px;

}

.millilogin .form-select {
  border-radius: unset;
  height: 45px;
}

.millilogin {
  width: 100%;
  margin: 0 auto;
  max-width: 85%;
}

.millibtn button.btn {
  width: 100%;
  border: none;
  background-color: #f18c20;
  color: white;
  font-size: 17px;
  font-weight: 600;
  /* max-width: 300px; */


}

.millireg button.btn {
  width: 100%;
  border: none;
  background-color: #ffa661;
  color: white;
  font-size: 17px;
  font-weight: 600;
  /* max-width: 300px; */


}

.millinlinks a {
  color: #f18c20;
  font-size: 14px;
}

.referclt label {
  color: #f18c20;
}

.millilogin .form-check .form-check-input {
  height: 17px;
  border-radius: unset;
}


.offcanvas-header .btn-close:focus {
  box-shadow: unset !important;
}

.offcanvas-header .btn-close:hover {
  background-color: #acacac;
  border-radius: 50%;

}

.bannerimg1 {
  width: 100%;
  max-width: 500px;
  float: right;
}
.bannerimgfinal {
  width: 100%;
  max-width: 500px;
  float: right;
  transform: perspective(1500px) rotateY(15deg);
  border-radius: 1rem;
  box-shadow: rgba(243, 243, 243, 0.25) 0px 25px 50px -12px;
  transition: transform 1s ease 0s;
}
.bannerimgfinal:hover{
  transform: perspective(3000px) rotateY(5deg);
}



.newmenu {
  background-color: #f18c20;
  color: white !important;
  padding: 0px 12px;
  border-radius: 5px;
}
.newmenu1 {
  background-color: #050374;
  color: white !important;
  padding: 0px 12px;
  border-radius: 5px;
}
.millivaheader button.btn.btn.navbar-toggler.border-3.px-2 {
  border: none;
}

.notifyy .dropdown-toggle::after {
  content: unset !important;
}

.notifyy .icon-button__badge {
  position: absolute;
  top: 10px;
  left: 21px;
  width: 10px;
  height: 10px;
  background: #f18c20;
  color: #ffffff !important;
  border-radius: 50%;
}

.notifyy .dropdown-menu {
  position: relative;
  right: -96px;
  width: 100%;
  min-width: 320px;



}


.nottitle {
  width: 100%;
  border-bottom: 1px solid rgb(221, 221, 221);
}

.nottitle h6 {
  color: gray;
}

.updatenoti {
  gap: 44px;
  color: #000;
}

.millivaheader .dropdown:hover .notimenu {
  display: block !important;
}

.notimenu a:hover {
  color: #f18c20;
}

.notimenu span {
  color: gray !important;
}

.rcw-conversation-container>.rcw-header {
  background-color: rgb(220, 220, 220) !important;
}

.rcw-widget-container {
  right: 40px !important;
}

.rcw-launcher {
  background-color: #fc9b32 !important;
}

/* bannersec */


.rcw-launcher{
  background-color:#fc9b32 !important;
}



button.welcome_popup_cls {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  top: 10px;
  right: 10px;
  position: absolute;
  box-shadow: none;
  border: 1px solid #666;
  background: transparent;
  color: #fff;
  font-size: 20px;
  line-height: 38px;
}
.addbb .modal-content{
width: 60% !important;

margin: 0 auto !important;
display: block !important;

}
.risk-footer p {
  font-size: 10px;
  margin-bottom: 3px;
}
.mt5-trade1 a.carousel-control-prev {
  display: none !important;
}
.mt5-trade1 a.carousel-control-next{
  display: none !important;
}

.whatsupico img.img-fluid {
  width: 100%;
  max-width: 57px;
  position: fixed;
  left: 41px;
  bottom: 14px;
}

/*sugadhevan*/

/* a.twitter-x-footer {
  background: radial-gradient(ellipse farthest-corner at right bottom, #e8ca3c 0%, #ffe135 8%, #ffc854 30%, #ffd471 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #ffffff 0%, #ffffac 8%, #ffb300 25%, #fff35d 62.5%, #fad47f 100%);
  padding: 14px;
  border-radius: 6px;
  color: #000;
  display: flex;
  height: 44px;
  width: 41px;
  align-items: center;
  justify-content: center;
}
a.twitter-x-footer img {
  width: 15px;
}

a.twitter-x-footer:hover {
  background: #1cb7eb;
  
}
a.twitter-x-footer:hover img {
  filter: invert(1);
  
} */


/* footer */

.blink1 .main-button span {
  position: relative;
  z-index: 5;
  color: #fff;
}
.blink1 .main-button {
  background-image: linear-gradient(to right, rgba(255, 153, 102, 100), rgba(255, 94, 98, 100));
  border: 0;
  padding: 15px 15px;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  font-family: 'Assistant';
  border-radius: 1px;
  cursor: pointer;
  position: relative;
  z-index: 3;
}

.blink1 {
  position: fixed;
  
  bottom: 363px;
  right: 51px;
  z-index: 999;
}
.blink1 .main-button:after {
  background-color: #ff5e62;
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 1px;
  animation-name: blink1;
  animation-duration: 1.3s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
  z-index: 4;
  animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
@keyframes blink1 {
  0% {
    transform: scale3d(1, 1, 1);
    opacity: 0.8;
 }
  100% {
    transform: scale3d(1.1, 1.4, 1.1);
    opacity: 0;
 }
}


.newmenu3 {
  background-color: #050374;
  color: white !important;
  padding: 0px 12px;
  border-radius: 5px;
}

.blink {
  animation: blink 2s steps(5, start) infinite;
  -webkit-animation: blink 1s steps(5, start) infinite;
}
@keyframes blink {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink {
  to {
    visibility: hidden;
  }
}

.badge-notification{

  background-color: #f18c20;
}


.chatbot{
  bottom: 95px !important;
}


.VIpgJd-ZVi9od-ORHb-OEVmcd{
  box-shadow: none !important;
  border-bottom: unset !important;
}
.VIpgJd-ZVi9od-ORHb{
  display: none !important;
}

iframe#\:1\.container {
  opacity: 0 !important;
  z-index: -1 !important;
}