.mt5cont {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 350px;
    /* padding-top: 86px; */
    background-color: #fcfcfc;
      background-image: url(../images/bgimgmt5.png);
  }
  .mt5cont img.img-fluid {
    width: 100%;
    max-width: 455px;
    float: right;
    height: 100%;
  }
  section.mt5trade {
    padding-top: 0;
    padding-bottom: 0 !important;
}
  .mt5cont p{
    color: gray;
  }
  button.btn.getmeta5 {
    background-color: #f18c20;
    width: 100%;
    max-width: 200px;
    color: white;
    font-size: 18px;
    font-weight: 600;
}
button.btn.getmeta5:hover{
    color: white !important;
}
  /* section.mt5trade p {
    font-size: unset !important;
} */
section.deposit-withdraw-content {
    padding-top: 0;
    padding-bottom: 20px !important;
}
.deposit-content2.text-center.mt-3.py-4 {
    background: #f7f7f7;
    box-shadow: -5px -5px 9px rgb(232 232 232), 5px 5px 9px rgb(94 104 121 / 30%);
    height: auto;
    min-height: 100%;
    border-radius: 5px;
    padding: 25px;
}
.deposit-content2.text-center.mt-3.py-4:hover {
    background: #1b193a;
    color: white;
}

.deposit-payment1 ul#ex1 {
    border-radius: 4px;
    padding: 5px;
    background: white;
    box-shadow: 0px 0px 4px 0px #999;
}
.deposit-payment1 .nav-tabs {
    --bs-nav-tabs-border-width: unset !important;
    --bs-nav-tabs-border-color: unset !important;
    --bs-nav-tabs-border-radius: unset !important;
    --bs-nav-tabs-link-hover-border-color:unset !important;
    --bs-nav-tabs-link-active-color: unset !important;
    --bs-nav-tabs-link-active-bg: unset !important;
    --bs-nav-tabs-link-active-border-color:unset !important;

}
.deposit-payment1 .nav-tabs li.nav-item {
    margin: 0px 5px;
}
.deposit-payment1 .nav-tabs a{
    background: #ececec;
    padding: 10px 0;
    border-radius: 4px;
    color: black;
    font-weight: 600;
}
.deposit-payment1 .nav-tabs a.active{
    background-color: #f18c20;
    color: white;
    font-weight: 600;
}
.deposit-payment1 ul#ex1 li a:hover {
    background: #f18c20;
    color: white;
}
.deposit-payment-new button.accordion-button.collapsed {
    background: #fff;
    border-radius: 4px !important;
}

.deposit-payment-new .accordion-item {
    border-radius: 4px !important;
    margin: 15px 0 !important;
    padding: 5px 0;
    border: none;
}
.deposit-payment-new button.accordion-button.collapsed.active {
    background: #f8f8f8;
    border-radius: 4px !important;
    border-top-right-radius: unset !important;
    border-top-left-radius:unset !important;
}
.deposit-payment-new .accordion-button:not(.collapsed) {
    color: #f18c20!important;
    background-color:#fafafa !important;
    box-shadow:unset !important;
}
.deposit-payment-new .accordion-button:focus {
    z-index: 3;
    border-color:unset !important;
    outline: 0;
    box-shadow: unset !important;
}
.deposit-payment-new button.accordion-button.collapsed img {
    width: 30px;
}
.deposit-payment-new button.accordion-button.collapsed span {
    padding: 0 15px;
    font-weight: 600;
}
.deposit-payment-new .accordion-button img:focus{
    width: 30px !important;
}
.deposit-payment-new .accordion-button img.active{
    width: 30px !important;
}
.deposit-payment-new img {
    width: 30px;
}
.deposit-payment-new span {
    padding: 0 15px;
    font-weight: 600;
}
.payment2 h6 {
    font-weight: 600;
    font-size: 18px;
    padding: 5px 0px;
}

.payment3 h6 {
    font-weight: 600;
    font-size: 18px;
    padding: 5px 0px;
}
.deposit-payment-new .accordion-body {
    padding-bottom: 0 !important;
}
.payment1.mt-3.py-4 {
    padding-bottom: 0px !important;
}
section.make-deposit {
    padding-top: 0 !important;
    background: #100f25;
    color: white !important;
    padding-bottom: 60px !important;
}
section.deposit-withdraw-content {
    padding-top: 0;
    padding-bottom: 0px !important;
    background: #f7f7f7;
}

.make-deposit2.text-center.mt-3.py-3 {
    border-radius: 5px;
    min-height: 300px;
    background: #0e0c2e;
    /* box-shadow: -5px -5px 9px rgb(30, 30, 30), 5px 5px 9px rgb(70 70 70 / 30%); */
}
.make-deposit2.text-center.mt-3.py-3 p {
    padding: 10px;
}
.make-deposit2.text-center.mt-3.py-3 svg {
    margin: 15px 0;
}
section.make-withdrawal {
    padding-top: 0 !important;
    background: #f7f7f7;
    padding-bottom: 60px;
}
.make-withdrawal1.mt-4.py-3.text-center {
    padding: 20px;
    background: #f7f7f7;
    border-radius: 5px;
    min-height: 246px;
    box-shadow: -5px -5px 9px rgb(232 232 232), 5px 5px 9px rgb(94 104 121 / 30%);
    transition: .7s ease-in-out;
}
.make-withdrawal1.mt-4.py-3.text-center:hover {
    transform: scale(1.1);
    transition: .7s ease-in-out;
}
/*yuva style get started */

.trade .box1get h4 {
    font-size: 17px;
    font-weight: 600;
}

.trade .box1get p {
    color: gray;
}

.trade .getsbtn {
    gap: 104px;
}

.trade .getimg img.img-fluid {

    position: relative;
    right: -65px;
}

.trade .openlivebtn button.btn.btn-primary {

    background-color: #f18c20;
    border: none;
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}

.trade .opendemobtn button.btn.btn-primary {
    background-color: #909090;
    border: none;
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}

.trade .box1get img.img-fluid {
    max-width: 50px;
    max-height: 50px;
    width: 100%;
}

section.trade.getmt5.mt-4.pb-5 {
    padding: 0;
}

/*get started end */


@media only screen and (max-width: 768px){
.deposit-payment1 ul#ex1 {
    display: block !important;
}
.deposit-payment1 .nav-tabs li.nav-item {
    margin: 10px 5px;
}

}

@media only screen and (max-width: 992px){
    section.getmt5.mt-4.pb-5 .box1get {
        text-align: center;
    }
    section.deposit-withdraw-content {
        text-align: center;
        display: flex;
    }
    .deposit-fees-fast .col-lg-4.col-md-12.col-12.col-sm-12 {
        margin-top: 20px; 
        
   
    }
    .make-withdrawal1.mt-4.py-3.text-center:hover{
        transform: unset;
      transition: unset
    }
}

@media only screen and (max-width: 768px){
    section.trade.getmt5.mt-4.pb-5 .box1get {
        text-align: center;
    }
    .trade .d-flex.justify-content-center.getsbtn.mt-5 {
        display: block !important;
        margin: 10px 0;
    }
    section.trade.getmt5.mt-4.pb-5 .openlivebtn {
        margin: 10px 0;
    }
    section.trade.getmt5.mt-4.pb-5 .getimg {
        text-align: center;
    }
    section.trade.getmt5.mt-4.pb-5 .getimg img.img-fluid {
        position: relative;
        right: 0px !important;
    }
 
}

@media only screen and (min-width: 768px) and (max-width: 992px){
    section.trade.getmt5.mt-4.pb-5 .d-flex.justify-content-center.getsbtn.mt-5 {
        justify-content: center !important;
    }
    section.trade.getmt5.mt-4.pb-5 .box1get {
        text-align: center;
    }
}
.trade .opendemobtn button.btn.btn-primary:hover{
    background-color: #909090 !important;
  }

@media only screen and (min-width: 768px) and (max-width: 992px){
    .deposit-content2.text-center.mt-3.py-4 {
        min-height: 310px;
    }
    .make-deposit2.text-center.mt-3.py-3 {
        min-height: 320px;
    }
    .make-withdrawal1.mt-4.py-3.text-center {
        min-height: 305px;
    }
}
@media only screen and (min-width: 991px) and (max-width: 1200px){
    .deposit-content2.text-center.mt-3.py-4 {
        min-height: 260px;
    }
    .make-withdrawal1.mt-4.py-3.text-center {
        min-height: 270px;
    }
    section.deposit-withdraw-content {
       
      
         text-align: center; 
    }

}
section.make-withdrawal img {
    width: 100%;
    margin: 25px 0;
    max-width: 60px;
}

.deposit-value p{
    color: gray;
}
.deposit-value small{
    color: gray;
}
.make-deposit2.text-center.mt-3.py-3 p {
    color: white !important;
}

.deposit-fees-fast img {
    width: 100%;
    max-width: 80px;
    height: 100%;
}

.deposit-fees-fast2 img {
    width: 100%;
    max-width: 80px;
    height: 100%;
}