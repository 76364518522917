.Economic.calendar .economicbanner {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 350px;
  padding-top: 40px;
  background-color: #fcfcfc;
  background-image: url(../images/bgimgmt5.png);
}

.Economic.calendar .economicbanner img.img-fluid {
  width: 100%;
  max-width: 455px;
  float: right;
  height: 100%;

  /* margin-top: 39px; */
}

section.calenderadvandage p {
  color: gray;
}
.Economic.calendar .economicbanner p {
  color: gray;
  font-size: 18px;
}
.Economic.calendar .economicbanner button.btn.getmeta5 {
  background-color: #f29531;
  width: 100%;
  max-width: 200px;
  color: white;
  font-size: 18px;
  font-weight: 600;
}
.Economic.calendar section.calenderadvandage.mt-5 {
  background: #fafafa;
  padding-top: 60px;
  padding-bottom: 60px;
  margin-top: -105px !important;
}

.Economic.calendar .box1get img.img-fluid {
  max-width: 50px;
  max-height: 50px;
  width: 100%;
}
.Economic.calendar .openlivebtn button.btn.btn-primary {
  background-color: #f29531;

  border: none;
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}
.Economic.calendar .opendemobtn button.btn.btn-primary:hover {
  background-color: #909090 !important;
}
.Economic.calendar .opendemobtn button.btn.btn-primary {
  background-color: #909090;
  border: none;
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}
.Economic.calendar .box1get h5 {
  font-size: 17px;
  font-weight: 600;
}
.Economic.calendar .box1get p {
  color: gray;
}

.Economic.calendar .getsbtn {
  gap: 104px;
}
.Economic.calendar .getimg img.img-fluid {
  position: relative;
  /* right: -65px; */
}
@media only screen and (max-width: 768px) {
  .Economic.calendar .getimg img.img-fluid {
    right: unset !important;
  }
  .Economic.calendar .economicbanner img.img-fluid {
    margin-right: unset;
    margin-top: 16px;
  }
}

.Economic.calendar .curre img.img-fluid {
  height: 200px;

  width: 100%;
  max-width: 348px;
}
.Economic.calendar .markettab .nav-tabs .nav-link {
  border: unset;
  font-size: 14px;
  font-weight: 600;
  color: black;
  padding: 0 11px;
  margin-bottom: 15px;
}

.Economic.calendar .markettab .nav-tabs {
  border-bottom: unset !important;
}
.Economic.calendar .markettab .nav-tabs .nav-link.active {
  color: #f29531;
}

.Economic.calendar .markettab table tr td {
  font-size: 12px;
  padding: 9px 3px;
  text-align: center;
  white-space: nowrap;
}
.Economic.calendar .coinname a {
  color: black !important;
  font-weight: 500;
}
.Economic.calendar .val2 a {
  color: red !important;
}
.Economic.calendar .val1 a {
  color: black !important;
}
.Economic.calendar .val3 a {
  color: green !important;
}
.Economic.calendar .val4 a {
  color: red !important;
}
.Economic.calendar .val5 a {
  color: green !important;
}
.Economic.calendar a.optrade.btn {
  background-color: #f29531;

  color: white;
  text-align: center;
  font-size: 14px;
  width: 100%;
  font-weight: 500;
  border: none;
}

.Economic.calendar a.downtrade.btn {
  background-color: #909090;

  color: white;
  text-align: center;
  font-size: 14px;
  width: 100%;
  white-space: nowrap;
  font-weight: 500;
  border: none;
}
.Economic.calendar a.downtrade.btn:hover {
  background-color: #909090 !important;
}

.Economic.calendar .forexanalytical .nav-tabs .nav-link {
  border: unset;
}
.Economic.calendar .forexanalytical .nav-tabs {
  border-bottom: unset !important;
  gap: 18px;
}

.Economic.calendar .exchnage h6 {
  font-size: 14px;
}
.Economic.calendar .exchnage {
  border-bottom: 1px solid #4285f4;
}
.Economic.calendar .markettab table tr:hover {
  background-color: #eaefec;
}
.Economic.calendar .marketanlysec3 table tr td {
  padding: 2px 16px !important;
  text-align: center;
  border-bottom: 1px solid rgb(215, 214, 214) !important;
}
.Economic.calendar .exchnage1 h6 {
  font-size: 14px;
}
.Economic.calendar .traditems img.img-fluid {
  width: 100%;
  max-width: 30px;
}

.Economic.calendar .marketanlysec3 i.fa-brands.fa-btc {
  color: orange;
}
.Economic.calendar .marketanlysec3 i.fas.fa-long-arrow-alt-down {
  color: green;
}
.Economic.calendar .tradingins p {
  color: gray;
  font-size: 14px;
}
.Economic.calendar .tradingins li {
  list-style: none;
  font-size: 14px;
  /* white-space: nowrap; */
  padding: 12px 0;
  text-indent: -39px;
  color: #000;
}
.Economic.calendar .tradingins li:hover {
  color: #f18c20;
}

@media only screen and (max-width: 768px) {
  .Economic.calendar section.getmt5.mt-4.pb-5 .box1get {
    text-align: center;
  }
  .Economic.calendar .d-flex.justify-content-center.getsbtn.mt-5 {
    display: block !important;
    margin: 10px 0;
  }
  .Economic.calendar section.getmt5.mt-4.pb-5 .openlivebtn {
    margin: 10px 0;
  }
  .Economic.calendar section.getmt5.mt-4.pb-5 .getimg {
    text-align: center;
  }
  .Economic.calendar section.getmt5.mt-4.pb-5 .getimg img.img-fluid {
    position: relative;
    right: 0px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .Economic.calendar
    section.getmt5.mt-4.pb-5
    .d-flex.justify-content-center.getsbtn.mt-5 {
    justify-content: start !important;
  }
}

@media only screen and (max-width: 992px) {
  .Economic.calendar {
    text-align: center;
  }
  ul.traditems.d-block.justify-content-center.align-items-center a {
    display: flex;
  }
  .Economic.calendar .economicbanner button.btn.getmeta5 {
    display: block;
    margin: 0 auto;
  }
  .Economic.calendar .economicbanner img.img-fluid {
    display: block;
    margin: 0 auto;
    float: unset;
  }
}

p.ecnomicpara {
  color: gray;
}

.Economic.calendar .table-responsive {
  /* overflow-x: auto; */
  -webkit-overflow-scrolling: touch;
  overflow-x: unset;
}

span.label-w6JJhLCp.label-wAvQLR3C.end-w6JJhLCp.bottom-w6JJhLCp.snap-w6JJhLCp.js-copyright-label {
  display: none !important;
}
