.affiliate-value .mt5cont {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 350px;
    /* padding-top: 86px; */
    background-color: #fcfcfc;
      background-image: url(../images/bgimgmt5.png);
  }
  .affiliate-value .mt5cont img.img-fluid {
    width: 100%;
    max-width: 455px;
    float: right;
    height: 100%;
  }
  .affiliate-value section.mt5trade {
    padding-top: 0;
}
.affiliate-value .mt5cont p{
    color: gray;
  }

button.btn.getmeta5 {
    background-color: #f18c20;
    width: 100%;
    max-width: 200px;
    color: white;
    font-size: 18px;
    font-weight: 600;
}
button.btn.getmeta5:hover{
    color: white !important;
}
.affilaite-content1.text-center.p-3 h2 {
    text-transform: capitalize;
    padding: 10px 0;
    font-weight: 600;

}
.affiliate-content2.text-center h2{
    text-transform: capitalize;
    padding: 10px 0;
 
}
.affiliate-content4.mt-3 i {
    padding: 3px 15px;
    color: #f18c20;
    font-size: 15px;
}
.affiliate-content4.mt-3 small {
    text-indent: 0px;
    display: flex;
}
.affiliate-content4.mt-3 p {
    display: flex;
}
.affiliate-box1 {
    /* padding: 10px; */
}
section.affiliate-program-box {
padding: 0 !important;
}
.affiliate-content4.mt-3 {
    overflow-y: scroll;
    height: 400px;
}

.affiliate-content3.mt-3 img {
    border-radius: 3px;
}
  
   .affiliate-content4.mt-3::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: rgb(224, 224, 224); 
  }
  
  .affiliate-content4.mt-3::-webkit-scrollbar-thumb {
    background: #f18c20;
  }
  section.affliate-key {
    padding: 0 !important;
}
.afiliate-key2.mt-3.py-3 {
    background: white;
    border-radius: 4px;
    margin: 10px 0;
    box-shadow: 0px 0px 10px 0px #ececec;
    padding: 30px;
    text-align: center;
}
section.affliate-key {
    padding-bottom: 40px !important;
    padding-top: 10px !important;
}
.afiliate-key2.mt-3.py-3 h4 {
    color: #f18c20;
    font-size: 28px;
    font-weight: 600;
}

section.affiliate-product1 {
    padding-top: 0 !important;
    /* background: url(../images/mani/affiliatebanner.png);

    background-size: cover;
    background-repeat: no-repeat; */
    background-color: #fcfcfc;
    /* padding-bottom: 30px; */
}

.affi-product1.mt-3 button {
    background: #f18c20!important;
    color: white !important;
    font-weight: 600;
    padding: 10px 30px;
    border: none;
}
.affiliate-product3.mt-3.py-3 h4 {
    font-size: 28px;
    font-weight: 600;
}
.affiliate-content3 img.img-fluid {
  max-width: 500px;
    width: 100%;
}

@media only screen and (max-width: 992px){
    .affipage {
       
        margin-top:15px!important;
    } 
    .affiliate-content3 img.img-fluid {
      margin: 0 auto;
      display: block;
      }
      
    .affi.text-start.col-lg-6.col-md-12 {
        margin-top: 13px;
    }
    .affiliate-product3.mt-3.py-3 p {
        padding: 0 !important;
    }
.affiliate-product3.mt-3.py-3 {
    text-align: center;
}
.affiliate-product3.mt-3.py-3 p {
    padding: 0 !important;
}

.affi-product1.mt-3.d-flex.justify-content-end.px-5.mx-5 {
margin: 0 !important;
padding: 0 !important;
}
.affi-product1.mt-3 button {
width: 100% !important;
}

}


@media only screen and (min-width: 768px) and (max-width: 992px)  {
    .afiliate-key2.mt-3.py-3 span {
        font-size: 12px;
    }
    .affiliate-product3.mt-3.py-3 p {
        padding:0px !important;
    }

    
}

.affiliate-value p{
    color: gray !important;

}
.affiliate-value small{
    color: gray !important;

}

.affipage {
    border: 1px solid #f18c20;
    border-radius: 4px;
    padding: 10px;
    min-height: 150px;
    height: 100%;
}
.affiliate-product3 img.img-fluid {
    width: 100%;
    max-width: 60px;
    
}

.affiliate-product1 .become-image img {
    /* position: relative;
    top: 40px; */
    width: 100%;
    max-width: 500px;
}
@media only screen and (max-width: 992px){
.affiliate-product1 .become-image img {
    position: relative;
    top: -21px;
}
}
@media only screen and (min-width: 1400px) and (max-width: 1500px) {
.affiliate-product1 .become-image img {
    position: relative;
    top: 29px;
}
}