/* ============================Risk trade============================== */

.Risk-trade p {
  color: grey;

}

section.Risk-banner p {
  font-size: 18px;
}

.Risk-trade .Risk-trade-banner {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 350px;
  padding-top: 86px;
  background-color: #fcfcfc;
  background-image: url(../images/bgimgmt5.png);
}

.Risk-trade .Risk-trade-banner img.img-fluid {
  max-width: 455px;
  float: right;
  width: 100%;
}

.Risk-trade .Risk-trade-banner p {
  color: gray;

}

.Risk-trade button.btn.getmeta5 {
  background-color: #f29531;
  width: 100%;
  max-width: 200px;
  color: white;
  font-size: 18px;
  font-weight: 600;
}

/* =========================risk-trade===================== */
.Risk img {
  width: 100%;
  max-width: 450px;
  height: 100%;
  float: right;
}

.risk-text {
  margin-top: 120px;
}

.Risk-trade .risk-management {
  padding-bottom: 20px;

}

.Risk-trade .risk-management h5 {
  margin-top: 20px;
  margin-bottom: 10px;
}

.Risk-trade .risk-manage img {
  width: 100%;
}

/* =======================get startted==================== */
.Risk-trade .box1get h5 {
  font-size: 17px;
  font-weight: 600;
}

.Risk-trade .box1get p {
  color: gray;
}

.Risk-trade .getsbtn {
  gap: 104px;
}

.Risk-trade .getimg img.img-fluid {
  right: -65px;
  position: relative;
}

.Risk-trade .openlivebtn button.btn.btn-primary {

  background-color: #f29531;
  border: none;
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}

.Risk-trade .openlivebtn button.btn.btn-primary:hover {
  transition: 1000ms;
  padding: 10px 50px;
  transform: translateY(0px);
  background-color: #fff;
  color: #f18c20;
  border: solid 2px #f18c20;
}
.Risk-trade .opendemobtn button.btn.btn-primary {
  background-color: #909090;
  border: none;
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}
.Risk-trade .opendemobtn button.btn.btn-primary:hover {
  transition: 1000ms;
  padding: 10px 50px;
  transform: translateY(0px);
  background-color: #fff !important;
  color: #f18c20;
  border: solid 2px #bababa;
}

.Risk-trade .box1get img.img-fluid {
  max-width: 50px;
  max-height: 50px;
  width: 100%;
}



/* ==========================get-started media=========================== */

@media only screen and (max-width: 768px) {
  .Risk-trade section.getmt5.mt-4.pb-5 .box1get {
    text-align: center;
  }

  .Risk-trade .d-flex.justify-content-center.getsbtn.mt-5 {
    display: block !important;
    margin: 10px 0;
  }

  .Risk-trade section.getmt5.mt-4.pb-5 .openlivebtn {
    margin: 10px 0;
  }

  .Risk-trade section.getmt5.mt-4.pb-5 .getimg {
    text-align: center;
  }

  .Risk-trade section.getmt5.mt-4.pb-5 .getimg img.img-fluid {
    position: relative;
    right: 0px !important;
  }

  .Risk-trade .risk-text {
    margin-top: 20px;
  }
}


@media only screen and (min-width: 1024px) {

  /* .risk-text {
  margin-top: 50px;
} */
  .Risk img {
    margin-top: 20px;
  }
}


@media only screen and (min-width: 768px) and (max-width: 992px) {
  .Risk-trade section.getmt5.mt-4.pb-5 .d-flex.justify-content-center.getsbtn.mt-5 {
    justify-content: center !important;
  }

  .Risk-trade .getsmt5 {
    text-align: center;
  }

}

@media only screen and (max-width: 992px) {
  .Risk-trade button.btn.getmeta5 {
    margin: 0 auto;
  }

  section.Risk-trade {
    text-align: center;
  }
  .Risk img {
    margin: 0 auto;
    display: block;
    float: unset !important;
}

  .Risk-trade .Risk-trade-banner img.img-fluid {

    float: unset;


  }
}

@media only screen and (min-width: 769px) and (max-width: 2000px) {
  .Risk-trade .getimg img.img-fluid {
    position: relative;
    right: unset !important;
  }
}

@media screen and (max-width: 1020px) {

  .Risk-trade .box1get {
    margin: 0 auto;
    display: block;
    text-align: center;
  }
}