.ourproducts-section {
    padding-top: 100px;
}


.product-items:hover{
    transform: scale(1.1);
}
.product-items {
    background: #ffff;
    height: 100%;
    max-height: 387px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 10px 10px;
    transition: transform 0.5s;
}

.product-items span {
    color: #f18c20;
    font-weight: 600;
    margin: 0 8px;
}

.product-items img {
    width: 100%;
    max-width: 200px;

}

.product-names {
    gap: 10px;
    padding: 5px 5px;
}

.price-value {
    font-weight: 600;
    color:#000;
}


.vertical-line {
    width: 0px;
    height: 100%;
    margin: 0 8px;

    border: 1px solid #bebbbb;
}


@media only screen and (max-width: 768px) {
    .ourproducts-section .col-lg-3 {
        margin-bottom: 15px;
    }

}