
@media screen and (max-width: 768px) {
    [data-aos-delay] {
      transition-delay: 0 !important;
    }
 
  
  .promotiontrade img.img-fluid{
    float: unset !important;
    margin: 0 auto;
    display: block;
  }
 
  }
  @media only screen and (min-width: 992px) and (max-width: 1400px){
    .promorefer p {
     
      position: relative;
      top: 13px;
  }
  .promorefer .card{
    padding: unset !important;
  }
  }
 


  @media only screen and (min-width: 769px) and (max-width: 820px) {
    .promotrade .col-md-12.col-lg-3 {
      margin-top: 26px;
  }
  .procontbanner img.img-fluid {
    float: unset !important;
      margin: 10px auto;
      display: block;
    }
    .promotiontrade img.img-fluid{
      float: unset !important;
      margin: 0 auto;
      display: block;
    }
    .contesttradesec img.img-fluid{
      float: unset !important;
      margin: 0 auto;
      display: block;
    }
      
    
  }
  @media only screen and (min-width: 769px) and (max-width: 1280px){
    .promo-box h4 {
      white-space: unset !important;
    }
  
  } 
  
  
  /* bannersec */
  
  .procontbanner {
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      min-height: 350px;
      padding-top: 86px;
      background-color: #fcfcfc;
        /* background-image: url(../img/bgimgmt5.png); */
        /* padding-bottom: 55px; */
    }
    .procontbanner img.img-fluid {
      width: 100%;
      max-width: 455px;
      float: right;
      height: 100%;
  }
  
    .procontbanner p{
      color: gray;
      font-size: 18px;
      
    }
  
    .procontbanner button.btn {
      background-color: #f18c20;
      width: 100%;
      max-width: 200px;
      color: white;
      font-size: 18px;
      font-weight: 600;
      height: 40px;
    }
    .startrefer1:hover{
      background-color: #f18c20;
      color: white;
      border: none;
    }
    .tradepromo{
      background-image: url(../images/promotions/bgimgoromotions.png);
      width: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      height: auto;
      min-height:300px;
      background-color: #fefcfc;
    }
    .promoforex .container-fluid{
  padding: 0;
    }
    a.reg {
      position: relative;
      top: -102px;
      background-color: #f18c20;
      color: white;
      padding: 8px 26px;
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      border-radius: 5px;
  }
  
  .promorefer .card{
    /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .13), 0 1px 1px 0 rgba(0, 0, 0, .11); */
    height: 100%;
    background-size: cover;
    width: 100%;
    min-height: 200px;
    background-image: url(../images/promotions/referbgimg.png);
  
  background-repeat: no-repeat;
  width: 100%;
  padding: 36px 41px;
  display: block;
  position: relative;
  
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
 
  }
  .promorefer p {
    color: gray;
    font-weight: 500;
    font-size: 18px;
  }
  .startrefer1{
  
  background-color: #f18c20;
    color: white;
    padding: 8px 26px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
  }
  
  .seccontact .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
   border-color: white !important;
   border: white !important;
  
  }
  .seccontact ul#ProductTab{
    border-bottom: unset !important;
    gap: 20px;
  }
  .seccontact .nav-tabs .nav-link{
    color: black;
  }
  
  .seccontact .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    
    border-color: white !important;
    color: black;
  }
  .socialcont {
    gap: 70px;
  }
  
  .socialcont i  {
    background-color: #f18c20;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    /* position: relative; */
    /* top: 21px; */
    text-align: center;
    line-height: 77px;
    color: white;
    font-size: 30px;
  }
  .contsecform p {
    color: gray;
    /* font-size: 18px; */
   
  }
  button#Description-tab {
    border-bottom: 2px solid #f18c20 !important;
    
    color:black;
    font-size: 18px;
    font-weight: 500;
    width: 200px;
    text-align: center;
    
  }
  button#Reviews-tab {
    color: black;
    font-size: 18px;
    font-weight: 500;
    width: 200px;
    text-align: center; 
  }
  button#Reviews-tab.active{
    border-bottom: 2px solid #f18c20 !important;
  }
  .seccontact .form-check-input:checked[type=checkbox] {
    
    border-radius: 0px;
    width: 33px;
    height: 18px;
  }
  a.forgot {
    text-align: center;
    margin: 0 auto;
    display: block;
    color: #4285f4;
  }
  a.forgot:hover{
    color: #4285f4 !important;
  }
  .seccontact select {
    width: 100%;
    
    padding: 0.375rem 0.75rem;
    border-color: #ced4da;
    border-radius: 5px;
  }
  
  .seccontact button.btn.btn-primary {
    margin: 0 auto;
    display: block;
    width: 100%;
    max-width: 200px;
    background-color: #f18c20;
    border: none;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
  }
  .regbtn{
    background-color: #f18c20;
    color: white;
    padding: 8px 26px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    border:none;
   
    
  }
  
  .regbtn:hover{
    background-color: #f18c20;
    color: white;
  }
    .promo-fold {
      position: relative;
    }
    .promo-box {
      height: 100%;
      padding: 20px;
      margin: 0;
  
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px 0px;
      background-color: white;
    
      color: #000;
      text-align: center;
      display: block;
      min-height: 250px;
      position: relative;
      top: -159px;
      border-radius: 0 0 0 5px;
    }
    .promo-fold:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 0;
      border-top: 20px solid rgb(242 149 49);
      border-left: 20px solid transparent;
    }
    
    .promo-fold:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 20px;
      width: 0;
      height: 0;
      border-bottom: 20px solid rgba(0, 0, 0, .15);
      border-right: 20px solid transparent;
    }
    
    .promo-box img.img-fluid{
      width:100%;
      max-width: 67px;
    }
    
    .promo-box h5 {
      
      font-size: 17px;
      padding: 13px 0;
      /* white-space: nowrap; */
      text-align: center;
  }
  .promo-box p{
    color: gray;
  }
  
  .promotiontrade img.img-fluid {
    width: 100%;
    max-width: 500px;
    float: right;
    height: 100%;
  }
  .promotiontrade p {
    color: gray;
    /* font-size: 18px; */
   
  }
  .promotiontrade p small {
    color: gray;
    
  }
  .contesttradesec img.img-fluid {
    width: 100%;
    max-width: 500px;
    float:left;
    height: 100%;
  }
  .contesttradesec p {
    color: gray;
    /* font-size: 18px;
    text-align:left; */
  }
  .contesttradesec p small {
    color: gray;
    
  }
  .seccontact input#flexCheckDefault {
    width: 43px;
    border-radius: 2px;
  }

  @media screen and (max-width: 992px){
    .promotiontrade img.img-fluid {
      margin: 0 auto;
      display: block;
      float: unset !important;
      
    }
    .seccontact input#flexCheckDefault {
      width: 100%;
      border-radius: 2px;
      max-width: 16px;
  }
    
    .socialcont {
      display: block !important;
      text-align: center;
  }
    .procontbanner{
      text-align: center;
    }
    .procontbanner p{
      text-align: center;
    }
    .procontbanner img.img-fluid {
      float: unset !important;
        margin: 10px auto;
        display: block;
      }
    .contesttradesec img.img-fluid{
      float: unset !important;
      margin: 0 auto;
      display: block;
    }
    .promotrade .col-md-12.col-lg-3 {
      margin-top: 26px;
  }
  .mt5fea .col-md-12.col-lg-3 {
    margin-top: 36px;
}
.promotions h2{
  text-align: center;
}
.promotions p{
  text-align: center;
}
.promotions .regbtn{
  margin: 0 auto;
  display: block;
  width: 100%;
    padding: 10px 10px;
  
}
.promorefer .card{
  background-image: unset !important;
  background-color: #e0e0e0;
}

.iconintro1{
  border-right: unset;
}
.socialcont i{
  margin-top:13px;

}
  }
    @media screen and (max-width: 768px) {
      .socialcont i svg {
        
        margin: 18px auto;
        display: block;
    }
    
    .seccontact .form-check-input:checked[type=checkbox] {
      border-radius: 0px;
      width: 105px;
    }
    .startrefer1{
   width:100%;
    }
    .promo-box h4{
      white-space: unset !important;
    }
  
  
  .tradepromo{
    background-size: cover;
  }
  a.reg{
    width: 100%;
  }
  .regbtn{
    margin: 0 auto;
    display: block;
    width: 100%;
  }
  .promotions h2{
    text-align:center;
  }


.contsecform p{
text-align: center;
}
    }
  
  
    select:focus-visible {
      outline: 0;
    }

    .seccontact label.form-check-label{
      color: gray;
  }


  .seccontact select.option{
    max-height: 100%;
    overflow-y: scroll;
    
   
   
  }