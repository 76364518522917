

/* ---------------------------------------------------------- 
# About us
------------------------------------------------------------- */

.About-us .bannercont {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 350px;
  /* padding-top: 86px; */
  background-color: #fcfcfc;
  background-image: url(../images/bgimgmt5.png);
}


.About-us .bannercont img.img-fluid {
width: 100%;
max-width: 455px;
float: right;
height: 100%;
/* margin-right: 148px; */
/* margin-top: -119px; */

}
/* .girlimage {
    width: 100%;
    max-width: 600px;
} */

.About-us  .bannercont p{
  color: gray;
  font-size: 18px;
}
.About-us  button.btn.getmeta5 {
  background-color: #f29531;
  width: 100%;
  max-width: 200px;
  color: white;
  font-size: 18px;
  font-weight: 600;
}

.About-us .images img {
  width: 100%;
  /* height: 300px; */
 float: left;
 max-width: 500px;
}
.About-us .inner2 img {
  display: flex;
  margin-top: -39px;
  margin-left: -95px;
  width: 100%;
  max-width: 586px;
}
.About-us .page_top_text {
    /* padding: 72px; */
    background-color: #fafafa;
    color: black;
    min-height: 312px;
}
.About-us .page_top_text .inner {
    position: relative;
}
.About-us .page_top_text .page_top_text_content {
    max-width: 785px;
    position: relative;
    z-index: 2;
   
}
.About-us .inner {
    max-width: 1240px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0 auto;
}
@media only screen and (max-width: 500px){

  .license-details{
    text-align: left;
  }

  .license-details h5{
    text-align: left;
  }
}
@media only screen and (max-width: 768px) {

  .About-us .chart.card {
  
    margin: 0 auto;
}

.About-us .images img {
    margin-top: -18px;
    margin-left: -7px;
    width: 100%;
}
}

.About-us .Milliva.Values._text_content {
  padding-top: 50px;
  padding-bottom: 50px;
 
}
.About-us .Milliva.Values._text {
  background: #fafafa;
}
.About-us .services-block-four.how {
    padding: 30px;
    margin-bottom: 15px;
    background: #fff;
}


.About-us .about2 {
  background-color: #fafafa;
  margin-bottom: -30px;
  padding-top: 52px;
}

.About-us .about2 .inner2 {
  padding: 80px 20px 70px;
  position: relative;
  display: flex;
  justify-content: right;
}
.About-us .about_text {
  color: black;
  max-width: 785px;
  margin-left: 60px;
  margin-top: 91px;
  text-align: unset;
}
.About-us .about_text h1{
    font-size: 42px;
}
.About-us .about_text p {
  line-height: 1.5;
 
  color: gray;
}




.About-us section.flow.chart {
  /* background: #fafafa; */
  padding-top: 17px;;
  padding-bottom: 70px;
}

.About-us .About-us ul{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 2rem;
  list-style: none;
}
.About-us .icon h4 {
  font-weight: bold;
  margin-top: 10px;
}



.About-us .card h5 {
  /* font-size: 16px; */
 
  color: black;
  text-align: center;
  
  margin-top: 10px;
}
.About-us .card .content{
  padding: 1rem;
  padding-bottom: 2rem;
  font-size: 0.9em;
  text-align: justify;
} 

/* .About-us section.flow.chart img {
  width: 100%;
  margin-top: -45px;
  max-width: 67px;
  margin: 0 auto;
} */
.About-us button.btn.btn-secondary {
  background: #f29531;
  border: 1px;
 
  font-size: 16px;
  width: 100%;
  max-width: 200px;
  height: 40px;
  font-weight: 600;
}



.About-us .get-ready {
  padding-top: 70px;
  padding-bottom: 70px;
  text-align: center;
  /* background: antiquewhite; */
}
.About-us .get-ready h2 {
  margin-bottom: 20px;
}
.About-us  button.btn.btn-g-ready {
  text-transform: uppercase;
  background: #f29531;
  border-radius: 35px;
  border: 1px solid white;
  padding: 11px 18px;
  display: inline-block;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  margin-top: 10px;
  text-align: center;
  cursor: pointer;
}


.About-us .box1get img.img-fluid {
  max-width: 50px;
  max-height: 50px;
  width: 100%;
}
.About-us .openlivebtn button.btn.btn-primary {

  background-color: #f29531;
  border: none;
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}
.About-us .opendemobtn button.btn.btn-primary:hover {
  background: #909090!important;
}
.About-us .opendemobtn button.btn.btn-primary {
  background-color:#909090;

  border: none;
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}
.About-us .box1get h5{
  font-size: 17px;
  font-weight: 600;
}
.About-us .box1get p{
  color: gray;
}

.About-us .getsbtn {
  gap: 104px;
}
.About-us .getimg img.img-fluid {
 
  position: relative;
  /* right: -65px; */
}
@media only screen and (max-width: 320px) {
  .About-us .getimg img.img-fluid {
   
      right: unset !important;
  
  }
  /* .About-us .bannercont img.img-fluid{
    margin-top: unset !important;
  } */
  .About-us .opendemobtn button.btn.btn-primary {
    margin-top: 10px;
}
}

.About-us .images.mt-5 img {
  width: 100%;
 
}

@media only screen and (max-width: 992px) {
.About-us {
  text-align: center !important;
}
.About-us .images img {
  width: 100%;
  margin: 0 auto;
  display: block;
  float: unset !important;
}
.About-us .bannercont p{
text-align: center;
}
.About-us button.btn.getmeta5 {
margin: 0 auto;
display: block;
}
.About-us .bannercont img.img-fluid{
float: unset;

}


.About-us .card .content{
text-align: unset;
}
}

.About-us .services-block-four.how {
  padding: 30px;
  margin-bottom: 15px;
  background: #fff;
}
.About-us .special .services-block-four {
  /* box-shadow: 0 2px 27px 0 rgb(154 161 171 / 18%); */
  border-radius: 3px;
  height: 100%;
  border: 1px solid #f2f2f2;
}
.About-us .mission-text p {
  text-align: justify;
  height: 100%;
  color: gray;
}
.About-us .vission-text p {
  text-align: justify;
  /* margin-right: -1px; */
  color: gray;
}
.About-us .vission-image h4 {
  padding-right: 9px;
}
.About-us .mission-image h4 {
  padding-right: 9px;
}
.About-us .now {
  /* margin-left: 71px; */
  height: 100%;
  width: 108%;
}


.About-us .chart.card {
  /* max-height: 360px; */
  height: 100%;
  width: 100%;
  text-align: center;
}
.About-us .vission-image img {
  margin-top: 10px;
}
.About-us .mission-image img {
  margin: 0 auto;
  display: block;
}
.About-us .mission-image h5 {
text-align: center;
}
@media only screen and (max-width: 768px){
  .About-us section.getmt5.mt-4.pb-5 .box1get {
      text-align: center;
  }
  .About-us .d-flex.justify-content-center.getsbtn.mt-5 {
      display: block !important;
      margin: 10px 0;
  }
  .About-us section.getmt5.mt-4.pb-5 .openlivebtn {
      margin: 10px 0;
  }
  .About-us section.getmt5.mt-4.pb-5 .getimg {
      text-align: center;
  }
  .About-us section.getmt5.mt-4.pb-5 .getimg img.img-fluid {
      position: relative;
      right: 0px !important;
  }
  .About-us .special .services-block-four {
     height: unset !important; 
}
 
}






@media only screen and (min-width: 768px) and (max-width: 992px){
  .About-us section.getmt5.mt-4.pb-5 .d-flex.justify-content-center.getsbtn.mt-5 {
      justify-content: center !important;
  }
  .About-us .opendemobtn button.btn.btn-primary {
    margin-top: 10px;
}
.About-us .chart.card {
  margin: 0 auto;
  max-width: 600px;
}
}

@media screen and (max-width: 1024px){
  
  .About-us .special .services-block-four {
    height: 100%;
}
.About-us .chart2 .flow .face{
  width: unset !important;
}
}

@media screen and (max-width: 992px){
  .About-us .services-block-four.how.d-flex {
display: block !important;
}
.About-us .girlimage img {
  margin-top: unset !important;
  padding: 38px;
}
}



.About-us .chart2 {
  /* width: 1000px; */
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-top: 34px;
  padding-bottom: 34px;
}
.About-us .chart2 h5 {
  text-align: center;
  margin-top: 20px;
  color: black;
  font-weight: 600;
  font-size: 17px;
}
.About-us .chart2 .flow{
  position: relative;
  cursor: pointer;
  margin-top: -18px;
}



.About-us .chart2 .flow .face {
  width: 100%;
  height: 200px;
  transition: 0.5s;
  /* margin-top: 49px; */
}
.About-us .chart2 .flow .face.face1{
  position: relative;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transform: translateY(100px);
  margin-top: 30px;
}

.About-us .chart2 .flow:hover .face.face1 {
  background: #f5f5f5;
  transform: translateY(0);
}

.About-us .chart2 .flow .face.face1 .content{
  
  transition: 0.5s;
}

.About-us .chart2 .flow:hover .face.face1 .content{
  opacity: 1;
}

.About-us .chart2 .flow .face.face1 .content img {
  max-width: 67px;
}

.About-us .chart2 .flow .face.face1 .content h3{
  margin: 10px 0 0;
  padding: 0;
  color: #fff;
  text-align: center;
  font-size: 1.5em;
}

.About-us .chart2 .flow .face.face2{
  position: relative;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transform: translateY(-100px);
 
}
.About-us .content {
   font-size: 14px; 
  text-align: center;
  color: gray;
}
.About-us .chart2 .flow:hover .face.face2{
  transform: translateY(0);
}

.About-us .chart2 .flow .face.face2 .content p{
  margin: 0;
  padding: 0;
}

.About-us .chart2 .flow .face.face2 .content a{
  margin: 15px 0 0;
  display:  inline-block;
  text-decoration: none;
  font-weight: 900;
  color: #333;
  padding: 5px;
  border: 1px solid #333;
}

.About-us .chart2 .flow .face.face2 .content a:hover{
  background: #333;
  color: #fff;
  
}
 @media screen and (max-width: 1024px){
  .About-us .chart2 {
     width:unset;
    /* position: relative; */
     display:unset;
    justify-content: space-between;
    text-align: -webkit-center;
}
} 
.About-us .page_top_text p{
  color: gray;
}

.About-us .Milliva.Values._text p{
  color: gray;
}

.About-us .content p{
  color: gray;
}

@media only screen and (max-width: 992px) {

.About-us .about_text {
    margin-left:unset !important;

}
}

.license li{
  list-style: none;
  padding: 5px 0;
}
.license span{
  font-weight: 600;
  color: #f18c20;
}

