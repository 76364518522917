.document .documentbanner {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 350px;
  /* padding-top:86px; */
  background-color: #fcfcfc;
  background-image: url(../images/bgimgmt5.png);
}

.document .documentbanner img.img-fluid {
  width: 100%;
  max-width: 455px;
  float: right;
  height: 100%;
}
.document .documentbanner p {
  color: gray;
  font-size: 18px;
}
.document .documentbanner button.btn.getmeta5 {
  background-color: #f29531;
  width: 100%;
  max-width: 200px;
  color: white;
  font-size: 18px;
  font-weight: 600;
}

.document ul.invesment {
  line-height: 2.5rem;
  margin-left: -14px;
}

.document section.document.invesmet {
  background-image: url(../images/legal/legadoc.png);
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.document section.document.invesmet {
  padding-top: 40px;
  padding-bottom: 70px;
  text-align: center;
  /* background: antiquewhite; */
}
.document section.document.invesmeth2 {
  margin-bottom: 20px;
}
.document button.btn.btn-g-ready {
  text-transform: uppercase;
  background: #f29531;
  border-radius: 35px;
  border: 1px solid white;
  padding: 11px 18px;
  display: inline-block;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  margin-top: 10px;
  text-align: center;
  cursor: pointer;
}
.document .Capital.market h2 {
  color: #f29531;
}
.document .Capital.market p {
  color: white;
  margin-top: 20px;
}
.document .Capital.limited h2 {
  color: #f29531;
}
.document .Capital.limited p {
  color: white;
  margin-top: 20px;
}
.document .registerimage img {
  border: 2px solid white;
  border-radius: 50px;
  padding: 21px;
  /* height: 100px; */
  width: 100px;
}
/* .legal-icon {
    padding-left: 155px;
} */
/* .document .legal-icon {

    display: flex;
    padding-left: 80px;
} */
.document .legal1 {
  gap: 47px;
}
.document .registerimage p {
  color: white;
  font-size: 13px;
  width: 80%;
  text-align: center;
  margin: 0 auto;
  display: block;
}
.document .services h5 {
  font-size: 17px;
}
.document button.btn.btn-primary {
  background: #f29531;
  border: #f29531;
  width: 100%;
  max-width: 205px;
  height: 40px;
  font-size: 16px;
  font-weight: 600;
}
.document section.documentation {
  background: #fafafa;
  padding-top: 50px;
  padding-bottom: 50px;
}
.document .member-text {
  /* font-size: 17px; */
  font-weight: 600;
}

@media only screen and (max-width: 768px) {
  .document section.document.invesmet {
    display: inline-table;
  }
  .document .documentbanner img.img-fluid {
    margin-right: 52px;
    margin-top: unset;
  }
}

@media only screen and (max-width: 992px) {
  .document section.legaldocument h1 {
    text-align: center;
  }
  .document section.legaldocument p {
    text-align: center;
  }
  .document .documentbanner button.btn.getmeta5 {
    margin: 0 auto;
    display: block;
  }
  .document .documentbanner img.img-fluid {
    display: block;
    margin: 0 auto;
    float: unset;
    padding-bottom: 12px;
  }
  .document section.AML\/MLTPA.mt-5.mb-5 {
    text-align: center;
  }
}

.document section.AML\/MLTPA.mt-5.mb-5 {
  text-align: center;
}

.AML\/MLTPA-text p {
  color: gray;
}
.international p {
  color: gray;
}

.legalbtn {
  background: #f29531;
  border: #f29531;
  width: 100%;
  max-width: 205px;
  height: 40px;
  font-size: 16px;
  font-weight: 600;
}
.legalbtn:hover {
  background-color: #f18c20;
  color: white;
}

.legalbtn:focus{
  background-color: #f18c20;
}


.regulation-content p{
  color: gray;
}