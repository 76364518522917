section.Sub-Broker {
    padding-top: 86px;
}

.Broker h2 {
    margin: 12px auto;
    display: block;
    text-align: center;
}.Broker p {
    text-align: center;
    width: 50%;
    margin: 10px auto;
}
.Broker button {
    margin: 10px auto;
    display: block;
    background-color: #f29531;
    color: white !important;
    padding: 8px 20px;
    border-radius: 5px;
    border: navajowhite;
}
.Broker button a {
    color: white;
}
section#features {
    background: #fafafa;
    padding: 20px;
}
#features .card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: unset;
    border: unset;
}
.counter-wrapper h2 {
    -webkit-text-fill-color: transparent;
    background: #a57d24;
    background: linear-gradient(to top left,#a57d24,#e4cd45 50%,#a57d24);
    -webkit-background-clip: text;
    font-size: 2rem;
    margin-bottom: 12px;
    text-align: center;
    display: block;
}
#features .card p {
    margin: 4px;
    font-weight: 500;
    color: #444;
}
.IB-ranking h2 {
    text-align: center;
}
.IB-ranking p {
    text-align: center;
}
.commission a {
    /* color: aquamarine; */
    background-color: #f29531;
    color: white !important;
    padding: 5px 20px;
    border-radius: 5px;
    margin: 0 14px;
}
.commission .seccontact .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: black !important;
    padding: 5px 20px !important;
    border-radius: 5px !important ;
    border: 1px solid #858585 !important;
}
.trading-section.nav.nav-tabs.tab-body-header.rounded.d-inline-flex.mb-4.nav {
    border-bottom: unset !important;
    width: 100%;
    justify-content: center;
}
.commission .tab-content.tab-content {
    margin: 0 auto;
    display: block;
    text-align: center;
    background: #fafafa;
    height: auto;
    grid-column-gap: 32px;
    grid-row-gap: 0px;
    align-items: center;
    padding: 30px;
}
.Premium h2 {
    -webkit-text-fill-color: transparent;
    background: #a57d24;
    background: linear-gradient(to top left,#a57d24,#e4cd45 50%,#a57d24);
    -webkit-background-clip: text;
    font-size: 2rem;
    margin-bottom: 12px;
    text-align: center;
    display: block; 
}
.milliva-offer .card {
    width: 70%;
    margin: 0 auto;
    padding: 12px;
    background: #f9fafc;
    border-radius: 20px;
    box-shadow: 0 5px 3px #f1f3f4;
    border: 1px solid #ececee;
}
@media only screen and (max-width: 767px) {
    .Broker p {
        width: 100%;
    }
    .milliva-offer .card {
        width: 100%;
    }
    #features .card p {
        text-align: center;
    }
    .trading-section.nav.nav-tabs.tab-body-header.rounded.d-inline-flex.mb-4.nav {
        justify-content: space-around;
    }
    .commission .nav-item.nav-item {
        margin: 7px 0;
    }
    .becomeibinsub p {
        /* text-align: center; */
    }
    .parainb br {
        display: none;
    }
    section#features {
        padding: 0 !important;
    }
    .milliva-offer img {
        margin: 0 auto !important;
        display: block;
        text-align: center;
    }
    .ib-trade
    {
        border-right: unset;
    } 
}
.milliva-offer img {
    display: block;
    height: auto;
    margin: 0 auto;
    max-width: 122px;
    width: 100%;
    transform: translate(-2px,-5px)rotate(-7deg);
    height: 138px;
    max-width: 245px;
    object-fit: contain;
}


.promotionrules .read-more-target,
.read-more-trigger_opened {
    display: none;
}

.promotionrules .read-more-state:checked~.read-more-wrap .read-more-target,
.read-more-state:checked~.read-more-trigger_opened {
    display: block;
   
}

.promotionrules .read-more-state:checked~.read-more-trigger_closed {
    display: none;
}
.promotionrules label.read-more-trigger_closed {
  color: #000;
  border: 1px solid #f18c20;
  width: 100%;
  max-width: 150px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 600;
  margin: 0 auto;
  display: block;
}
.promotionrules label.read-more-trigger_opened{
  color: #000;
  border: 1px solid #f18c20;
  width: 100%;
  max-width: 150px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 600;
  margin: 0 auto;
 
}
.spedupjoureny.mt-4 p.text-center {
  font-size: 17px;
  font-weight: 600;
}
.spedupjoureny h4{
color: #f18c20;
}
ul.rulespromo li {
  list-style: none;
  padding: 7px 5px;
}
ul.rulespromo {
  padding: 0;
}
ul.winpromo li{
  padding: 7px 5px;
  list-style: none;

}
ul.winpromo{
  padding: 0;
}
.deliveryli li{
  list-style: none;
  padding: 7px 5px;

}
.deliveryli{
  padding: 0;
}

i.fa.fa-check.mx-2 {
  color: #f18c20;
}
.winpromo p{
font-size: 17px;
font-weight: 500;
}
.promotionrules.mt-5 {
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 15px 10px;
  width: 90%;
  margin: 0 auto;
}
.iconintro3{
    /* display: block !important; */
    text-align: center;
}
.iconintro3 {
    border: unset !important;
}
@media screen and (max-width: 992px){
  .promotionrules label.read-more-trigger_opened{
    margin-top: 15px;
  }
  .ib-trade{
    /* display: none !important; */
    text-align: center;
  }


}
.milliva-offer .card h5 {
    color: #f18c20;
    font-size: 26px;
    font-weight: 600;
}
.milliva-offer .card p {
    color: #000;
    display: block;
    font-size: 17px;
    font-weight: 500;
}

.payouts img.img-fluid {
    width: 100%;
    max-width: 60px;
    margin: 0 24px;
}

@media only screen and (min-width: 1000px) and (max-width: 1200px) {


.payouts img.img-fluid {
    margin: 0 !important;
}
}
.ib-trade
{
    border-right: 2px solid #d7d1d1;
} 
.sign12
{
  
     
        /* display: block !important; */
        margin: 5px auto;
       
        text-align: center;
 
}
.sign12{
    gap:0 !important;  
  }

  .iboffpage .strikeout-image {
    height: auto;
    left: 52%;
    opacity: .7;
    position: absolute;
    top: 30%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    width: 70%;
    z-index: 1;
}   