@media screen and (max-width: 768px) {
    [data-aos-delay] {
      transition-delay: 0 !important;
    }
    
  
  }
  
  
  @media screen and (max-width: 992px){
    .marketanalybb h1{
      text-align: center;
    }
    .marketanalybb p{
      text-align: center;
    }
    .marketanalybb img.img-fluid{
      margin: 20px auto;
      float: unset !important;
      display: block;
    }
    .mt5fea .col-md-12.col-lg-3 {
      margin-top: 36px;
  }
  .forexanalytical .nav-tabs{
    display: block !important;
    margin: 0 auto;
  }
  button#Topanaly-tab{
    margin-top: 20px;
  }
  .curre img.img-fluid{
    width: 100%;
    max-width: unset !important;
  }
  .forexanal .col-lg-6 {
    margin-top: 9px;
}
.article1 .card{
  margin-top: 20px !important;
}
  }

  
  button.btn.getmeta5 {
    background-color: #f18c20;
    width: 100%;
    max-width: 200px;
    color: white;
    font-size: 18px;
    font-weight: 600;
  }
  
  
  .curre img.img-fluid {
    height: 200px;
   
    width: 100%;
    max-width: 348px;
  }
  .markettab .nav-tabs .nav-link {
    
    border: unset;
    font-size: 14px;
    font-weight: 600;
    color: black;
    padding: 0 11px;
    margin-bottom: 15px;
  }
  
  .markettab .nav-tabs {
    border-bottom: unset !important;
   
  }
  .markettab .nav-tabs .nav-link.active{
    color: #f18c20;
  }
  
  .markettab table tr td {
    font-size: 12px;
    padding: 9px 3px;
    text-align: center;
    white-space: nowrap !important;
  }
 
  .coinname a{
    color: black !important;
    font-weight: 500;
    
  }
  .val2 a{
    color: red !important;
   
  }
  .val1 a{
    color: black !important;
  }
  .val3 a{
    color: green !important;
  }
  .val4 a{
    color: red !important;
  }
  .val5 a{
    color: green !important;
  }
  a.optrade.btn {
    background-color: #f18c20 !important;
    
    color: white;
    text-align: center;
    font-size: 14px;
    width: 100%;
    font-weight: 500;
    border: none;
  }
  
  a.downtrade.btn{
    background-color: #909090 !important;
    
    color: white;
    text-align: center;
    font-size: 14px;
    width: 100%;
   
    font-weight: 500;
    border: none;
  }
  
  .forexanalytical .nav-tabs .nav-link {
    border: unset;
  }
  .forexanalytical .nav-tabs{
    border-bottom: unset !important;
    gap: 18px;
  }
  
  
  /* .marketanalybb{
    height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      min-height: 350px;
      padding-top: 86px;
      background-color: #fcfcfc;
      background-image: url(../img/bgimgmt5.png);
  } */
  /* button#Forexanaly-tab {
    background-color: whitesmoke;
    color: black;
   
  } */
  
  button#Topanaly-tab {
    color: white;
    font-size: 16px;
    font-weight: 600;
    background-color: #f18c20 !important;
    border-radius: 5px;
    background-color: whitesmoke;
    width: 100%;
  }
  button#Topanaly-tab.active{
    background-color: #f18c20 !important;
    color: white;
  }
  button#Forexanaly-tab{
    background-color: #909090 !important;
    color: white;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    width: 100%;
    
  }
  

  
  .forexanal a{
    text-decoration: none;
    outline: none;
    color: black;
  }
  .forexanal .card-flyer {
    border-radius: 5px;
  }
  .forexanal .card-flyer .image-box{
    background: #ffffff;
    overflow: hidden;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 5px;
  }
  .forexanal .card-flyer .image-box img{
    -webkit-transition:all .9s ease; 
    -moz-transition:all .9s ease; 
    -o-transition:all .9s ease;
    -ms-transition:all .9s ease; 
    width: 100%;
    height: 170px;
  }
  .forexanal .card-flyer:hover .image-box img{
    opacity: 0.7;
    -webkit-transform:scale(1.15);
    -moz-transform:scale(1.15);
    -ms-transform:scale(1.15);
    -o-transform:scale(1.15);
    transform:scale(1.15);
  }
  .forexanal .card-flyer .text-box{
    text-align: center;
  }
  .forexanal .card-flyer .text-box .forexanalybox {
    padding: 12px 18px;
    height: 100%;
    min-height: 255px;
  }
  .forexanal .card-flyer{
    background: #FFFFFF;
  
    -webkit-transition: all 0.2s ease-in;
    -moz-transition: all 0.2s ease-in;
    -ms-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }
  .forexanal .card-flyer:hover{
    background: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    -webkit-transition: all 0.2s ease-in;
    -moz-transition: all 0.2s ease-in;
    -ms-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
    /* margin-top: 50px; */
  }
  .forexanal .card-flyer .text-box p {
  
    margin-bottom: 0px;
    padding-bottom: 0px;
   
   
    color: #000000;
    text-align: left;
    font-weight: 500;
    font-size: 25px;
  }
  .forexanal .card-flyer .text-box h5 {
    margin-top: 0px;
    margin-bottom: 4px;
   
    font-size: 18px;
    color: gray;
    text-align: left;
    overflow-wrap: break-word;
  }
  
  .article1 .card {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  display: block;
    margin: 0;
    padding: 24px 20px;
    border-radius: 9px;
    height: 100%;
    min-height: 200px;
    border: none;
      
  }
  .article1 small{
    color: #A5A5A5;
  }
  
  .article1 h6 {
    overflow: hidden;
    max-height: 58px;
  }
  
  .forextab2 a{
  color: black;
  }
  
  
  .bloscontents a{
    text-decoration: none;
    outline: none;
    color: black;
  }
  .bloscontents .card-flyer {
    border-radius: 5px;
  }
  .bloscontents .card-flyer .image-box{
    background: #ffffff;
    overflow: hidden;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 5px;
  }
  .bloscontents .card-flyer .image-box img{
    -webkit-transition:all .9s ease; 
    -moz-transition:all .9s ease; 
    -o-transition:all .9s ease;
    -ms-transition:all .9s ease; 
    width: 100%;
    height: 170px;
  }
  .bloscontents .card-flyer:hover .image-box img{
    opacity: 0.7;
    -webkit-transform:scale(1.15);
    -moz-transform:scale(1.15);
    -ms-transform:scale(1.15);
    -o-transform:scale(1.15);
    transform:scale(1.15);
  }
  
  .bloscontents .card-flyer .text-box .blogbox {
  padding: 20px;
  }
  
  a.btn.forex {
    border: none;
    background-color: #f18c20;
    padding: 3px 7px;
    border-radius: 5px;
    color: white;
    font-size: 14px;
  }
  .bloscontents .card-flyer{
    background: #FFFFFF;
  
    -webkit-transition: all 0.2s ease-in;
    -moz-transition: all 0.2s ease-in;
    -ms-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    height: 92%;
  }
  .bloscontents .card-flyer:hover{
    background: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    -webkit-transition: all 0.2s ease-in;
    -moz-transition: all 0.2s ease-in;
    -ms-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
    /* margin-top: 50px; */
  }
  .blogbox p {
    overflow: hidden;
    max-height: 50px;
    color: gray;
  }
  .bloscontents.card-flyer .text-box h5 {
    margin-top: 0px;
    margin-bottom: 4px;
   
    font-size: 18px;
    color: gray;
    text-align: left;
    overflow-wrap: break-word;
  }
  .exchnage h6 {
    font-size: 14px;
  }
  .exchnage{
    border-bottom: 1px solid #ebebeb;
  }
  .markettab table tr:hover{
    background-color:#eaefec;
  }
  .marketanlysec3 table tr td {
    padding: 2px 16px !important;
    text-align: center;
    border-bottom: 1px solid rgb(215, 214, 214) !important;
    font-size: 12px;
  }
  .exchnage1 h6 {
    font-size: 14px;
  }
  .traditems img.img-fluid{
    width: 100%;
    max-width: 30px;
  }
  
  .marketanlysec3 i.fa-brands.fa-btc {
    color: orange;
  }
  .marketanlysec3 i.fas.fa-long-arrow-alt-down {
    color: green;
  }
  .tradingins p {
    color: gray;
    font-size: 14px;
  }
  .tradingins li {
    list-style: none;
    font-size: 14px;
    /* white-space: nowrap; */
    padding: 12px 0;
    text-indent: -39px;
    color: #000;
    
  }
  .tradingins li:hover{
    color:#f18c20;
  }
  
    
  /* 
  bannersec */
  .marketanalybb {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 350px;
    padding-top: 86px;
    background-color: #fcfcfc;
      background-image: url(../images/bgimgmt5.png);
  }
  .marketanalybb img.img-fluid {
    width: 100%;
    max-width: 455px;
    float: right;
    height: 100%;
  }
  
  .marketanalybb p{
    color: gray;
    font-size: 18px;
    
  }
  button.btn.getmeta5 {
    background-color: #f18c20 !important;
    width: 100%;
    max-width: 200px;
    color: white;
    font-size: 18px;
    font-weight: 600;
    border: none;
    height: 40px;
  }

  .marketanalytical .table-responsive {
    overflow-x: unset !important;
    
}
i.btc svg {
  color: #f18c20;
}@media only screen and (min-width: 992px) and (max-width: 1400px){
  .marketanalytical .table-responsive {
    overflow-x: scroll !important;
}
.marketanlysec3{
  overflow-x: scroll !important;
}
.exchnage{
  justify-content: space-between !important;
}
}


@media only screen and (min-width: 321px) and (max-width: 701px) {
  ul.traditems {
    margin: 0 auto;
    width: 100%;
    white-space: nowrap;
    max-width: 50%;
}
}
@media only screen and (min-width: 702px) and (max-width: 992px){
  ul.traditems {
    margin: 0 auto;
    width: 100%;
    white-space: nowrap;
    max-width: 30%;
}
}