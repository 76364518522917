section.sign-in-page {
    padding: 0 !important;
}
.sign-in-banner {
    padding: 60px 0;
}
.sign-in-banner button {
    color: white !important;
    text-transform: uppercase;
    background: #000;
    border: none;
    width: 30%;
    height: 40px;
    font-weight: 600;
}
.sign-in-banner p {
    padding: 0 5px;
    font-size: 17px;
}

/* section.sign-in-page {
    background:url(../img/Open-demo-images/banner3.jpg);
    height: auto;
    
    background-position: center;
    background-repeat: no-repeat;   
    background-size: cover;
} */
section.sign-in-content button {
    width: 100%;
    margin: 8px 0;
    border: none;
}
section.sign-in-content button.btn.btn-primary.btn-lg.btn-block {
    background: #f18c20 ;
}
section.sign-in-content input:focus{
    box-shadow: none;
    border: 1px solid #f18c20 ;
}
section.sign-in-content .card {
    background: #fafafa;
    border-radius: 4px !important;
    border: 1px solid #d8d8d8;
}

section.sign-in-content input {
    font-size: 15px !important;
}
section.sign-in-content {
    padding-top: 0 !important;
    background: url(../images/mani/map1.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 50px !important;
} 
.sign-in-icons.d-flex.py-3 img {
    width: 20px;
}

.sign-in-icons.d-flex.py-3 span {
    padding: 0 15px;
}
.sign-in-icons.d-flex.py-3 h6 {
    padding: 8px;
    border-radius: 4px;
    margin: 0 15px;
    text-align: center;
    position: relative;
    bottom: 15px;
    border: 1px solid rgb(207, 207, 207);
}
.sign-in-icons.d-flex.py-3 {
    margin: 0 auto;
}
.sign-in-google.ms-auto img {
    position: relative;
    left: 6px;
    width: 18px !important;
}
.sign-in-google img {
    position: relative;
    left: 10px;
    bottom: 1px;
}
.sign-in-facebook img {
    width: 18px !important;
    position: relative;
    left: 5px;
    bottom: 1px;
}





@media only screen and (max-width: 768px){
    section.sign-in-content label.form-check-label.px-2 {
        font-size: 13px;
    }
    section.sign-in-content .form-check.d-flex.justify-content-start.mb-4 a {
        font-size: 13px;
    }
    .sign-in-google h6 {
        display: flex;
    }
    .sign-in-facebook h6 {
        display: flex;
    }
    .sign-in-icons.d-flex.py-3 h6 {
        margin: 0 6px;

    }
}