.blogcontent {
    padding-top: 100px;
}
ul.d-flex.blogbread {
    gap: 45px;
}
ul.d-flex.blogbread li {
    list-style: none;
}
.blogimg1 img.img-fluid {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 400px;
}
.mtapps li{
    list-style: none;
    padding: 10px;
}
.tradplat .card {
    padding: 10px 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

ul.mtapps {
    padding: 0;
}
.tradplat {
    text-align: center;
}
a.linkbtn {
    background-color: #f18c20;
    padding: 6px 27px;
    color: white;
    border-radius: 5px;
}
.recentblog img.img-fluid {
    height: auto;

    max-height: 122px;
}
.recentblog .card{
    height: 100%;
}
.likebtn a{
    color: #ffff !important;
}
.blogcontent a{
    color: #f18c20;
}
.recentblog p{
    color: black !important;
}
.prenext p{
    color: gray;
}

.bbblog img.img-fluid {
    width: 100%;
    max-width: 327px;
}
.btnlive{
    gap:20px ;
}
.btnlive button.btn.btn-primary {
    border: none;
    background-color: #f18c20;
}
.millilink {
color: black !important;
}
.millilink:hover{
    color: #f18c20 !important;
}

@media only screen and (min-width: 768px) and (max-width: 992px){
    .tradplat {
        
        margin-top: 20px;
    }
}
