section.live-account-page {
    padding: 0 !important;
}
.live-account-banner {
    padding: 60px 0;
}
.live-account-banner button {
    color: white !important;
    text-transform: uppercase;
    background: #000;
    border: none;
    width: 30%;
    height: 40px;
    font-weight: 600;
}
.live-account-banner p {
    padding: 0 5px;
    font-size: 17px;
}

.live-page1.mt-5.py-3 {
    background: #fafafa;
    width: 60%;
    margin: 0 auto;
    padding: 30px;
    border-radius: 4px;
    border: 1px solid #d8d8d8;
}
.live-button1.text-center.py-3 button {
    margin: 0 10px;
}

  .live-button1.text-center button {
    width: 30%;
    background: #f18c20;
    border: none;
    font-weight: 600;
}
.live-button1.text-center button:focus {
    background: #f18c20;
}
.live-button2.text-center button:focus {
    background: #909090;
}
.live2 {
    background: #909090 !important;
}
.live2:hover{
    background-color:#909090 !important ;
}
.live-icon.text-center.mt-3 i {

    padding: 10px;
    color: white;
    font-size: 30px;
    position: relative;
    top: 7px;
}
.live-icon.text-center.mt-3 {
    background: #f18c20;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin: 0 auto;
}
  .live-page1.mt-5.py-3 input:focus {
    box-shadow: none;
    border: 1px solid #f18c20;
  }
  .live-page1.mt-5.py-3 select:focus {
    box-shadow: none;
    border: 1px solid #f18c20;
  }

  section.open-live-page.pb-5 {
    padding: 0 !important;
    /* background: url(../img/Open-demo-images/back2.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; */
}
section.open-live-page.pb-5 {
    padding-bottom: 50px !important;
    background: url(../images/mani/map1.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.input-group-append {
    cursor: pointer;
  }


  @media only screen and (max-width: 768px){

    .live-page1.mt-5.py-3 {
        width: 100%;
    }
    .live-button1.text-center.py-3 button {
        display: block;
        width: 100%;
        margin: 10px 0;
    }
    .affiliate-product3.mt-3.py-3 p {
        padding: 0 !important;
    }
.affiliate-product3.mt-3.py-3 {
    text-align: center;
}
}