.mt5cont {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 350px;
    /* padding-top: 86px; */
    background-color: #fcfcfc;
      background-image: url(../images/bgimgmt5.png);
  }
  .mt5cont img.img-fluid {
    width: 100%;
    max-width: 455px;
    float: right;
    height: 100%;
  }

  section.mt5trade {
    padding-top: 0;
}
  .mt5cont p{
    color: gray;
  }
  button.btn.getmeta5 {
    background-color: #f18c20;
    width: 100%;
    max-width: 200px;
    color: white;
    font-size: 18px;
    font-weight: 600;
}
button.btn.getmeta5:hover{
    color: white !important;
}
section.segregate-acc1 {
    padding-top: 0 !important;
    /* padding-bottom: 50px; */
}

.segregate-content1 i {
    padding: 3px 15px;
    color: #f18c20;
    font-size: 15px;
}
.segregate-content1 small {
    text-indent: 0px;
    display: flex;
}
.segregate-content1 p {
    display: flex;
    text-align: justify;
}

.segregate-content1 h2 span {
    color: #f18c20;
}
.segregate-content2 i {
    padding: 3px 15px;
    color: #f18c20;
    font-size: 15px;
}
.segregate-content2 small {
    text-indent: 0px;
    display: flex;
}
.segregate-content2 p {
    display: flex;
    text-align: justify;
}
.segregate-divider {
    padding-bottom: 40px;
}
.segregate-value p{
    color: gray;

}
.segregate-value small{
    color: gray;
}
@media only screen and (max-width: 992px){
.segregate-image.p-3 {
    padding: 0 !important;
    margin: 0 auto !important;
    text-align: center;
}
.segregate-content2 {
    text-align: center;
}
}




.segregate-content1 {
    text-align: center;
}