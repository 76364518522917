/* bannersec */
.ibprogrambg {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 350px;
    padding-top: 106px;
    background-color: #fcfcfc;
      background-image: url(../images/ibpromotions/promotionbg.png);
  }
  .strikeout-container {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .strikeout-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 81%;
    height: auto;
    opacity: 0.7; /* Adjust this value to control the opacity of the strikeout image */
  }
  
  .ibprogrambg img.img-fluid {
    width: 100%;
    max-width: 455px;
    float: right;
    height: 100%;
  }
  .ibprogrambg span{
    color: #fff;
  }
 
 
  .ibprogrambg p{
    color: #fff;
    font-size: 18px;
    text-align: left;
  }
  button.btn.getmeta5 {
    background-color: #f29531 !important;
    width: 100%;
    max-width: 200px;
    color: white;
    font-size: 18px;
    font-weight: 600;
    border: none;
  }

  .spedupjoureny svg{
    margin: 0 auto;
    display: block;
  }
  .weekwinners {
    background-color: #f7f7f7;
    padding: 15px 10px;
    height: 100%;
}
.weekwinners.mt-5 img.img-fluid {
  width: 100%;
  max-width: 150px;
  margin: 0 auto;
  display: block;
}
.tradewin button.btn {
  margin: 0 auto;
  display: block;
  color: white;
  font-size: 17px;
  font-weight: 600;
  border: none;
  background-color: #f18c20;
  width: 100%;
  max-width: 200px;
}


.promotionrules .read-more-target,
.read-more-trigger_opened {
    display: none;
}

.promotionrules .read-more-state:checked~.read-more-wrap .read-more-target,
.read-more-state:checked~.read-more-trigger_opened {
    display: block;
   
}

.promotionrules .read-more-state:checked~.read-more-trigger_closed {
    display: none;
}
.promotionrules label.read-more-trigger_closed {
  color: #000;
  border: 1px solid #f18c20;
  width: 100%;
  max-width: 150px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 600;
  margin: 0 auto;
  display: block;
}
.promotionrules label.read-more-trigger_opened{
  color: #000;
  border: 1px solid #f18c20;
  width: 100%;
  max-width: 150px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 600;
  margin: 0 auto;
 
}
.spedupjoureny.mt-4 p.text-center {
  font-size: 17px;
  font-weight: 600;
}
.spedupjoureny h4{
color: #f18c20;
}
ul.rulespromo li {
  list-style: none;
  padding: 7px 5px;
  display: flex;
}
ul.rulespromo {
  padding: 0;
}
ul.winpromo li{
  padding: 7px 5px;
  list-style: none;
display: flex;
}
ul.winpromo{
  padding: 0;
}
.deliveryli li{
  list-style: none;
  padding: 7px 5px;

}
.deliveryli{
  padding: 0;
}

i.fa.fa-check.mx-2 {
  color: #f18c20;
}
.winpromo p{
font-size: 17px;
font-weight: 500;
}
.promotionrules.mt-5 {
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 15px 10px;
  width: 90%;
  margin: 0 auto;
}
@media screen and (max-width: 992px){
  .promotionrules label.read-more-trigger_opened{
    margin-top: 15px;
  }

}