.mt5cont {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 350px;
    /* padding-top: 86px; */
    background-color: #fcfcfc;
      background-image: url(../images/bgimgmt5.png);
  }
  .mt5cont img.img-fluid {
    width: 100%;
    max-width: 455px;
    float: right;
    height: 100%;
  }
  section.mt5trade {
    padding-top: 0;
}

  .mt5cont p{
    color: gray;

  }
  button.btn.getmeta5 {
    background-color: #f18c20;
    width: 100%;
    max-width: 200px;
    color: white;
    font-size: 18px;
    font-weight: 600;
}
button.btn.getmeta5:hover{
    color: white !important;
}
section.bonus-content {
    padding: 0;
}
.bonus-content1.mt-5.text-center {
    background: #f7f7f7;
    box-shadow: -5px -5px 9px rgb(253 253 253), 5px 5px 9px rgb(166 166 166 / 30%);
    margin: 40px 0;
    border-radius: 5px;
    min-height: 168px;
}
.bonus-content1.mt-5.text-center h1 {
    color: #f18c20;
    font-weight: bold;
    padding: 10px;
    font-size: 45px;
}
.bonus-content1.mt-5.text-center.d-flex.align-items-center p {
    font-size: 25px;
    position: relative;
    top: 5px;
    font-weight: bold;
    color: #f18c20;
}
section.bonus-steps {
    padding-top: 0;
    padding-bottom: 30px !important;
  
}
.bonus-step-new h2 {
    color: #f18c20;
}
.bonus-step1{
    background: #f7f7f7;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 5px;
    transition: .7s ease-in-out;
}
.bonus-step1:hover{
    transition: .7s ease-in-out;
    transform: scale(1.1);
    background-color: #fff;
    color: black;

}

section.bonus-profit-page {
    padding-top: 0;
    padding-bottom: 40px !important;
}
.bonus-profit1 {
    background: white;
    border-radius: 5px;
    box-shadow: 2px 2px 23px 2px #ececec;
    padding: 20px;
    min-height: 592px;
}

.bonus-profit2 {
    padding: 0 5px;
}
.bonus-profit-box1.d-flex {
    background: #4285f4;
    padding: 0px;
    border-radius: 3px;
}
.bonus-profit-box1.d-flex i {
    color: white;
}
.bonus-profit-box1.d-flex img {
    width: 25px;
}
.bonus-icons.d-flex.align-items-center i {
    padding: 0 8px;
}
.bonus-profit-box1.d-flex span {
    padding: 0px 10px;
    color: white;
}
.bonus-profit-box1.d-flex p {
    padding: 0px 10px;
    color: white;
}
.bonus-profits-box {
    width: 60%;
    margin: 0 auto;
    background: white;
    box-shadow: 0px 0px 10px 0px #ececec;
    padding: 0px;
}
.bonus-profit2.c1 {
    background: #f2f2f2;
    border: 1px solid rgb(221, 221, 221);
}
.bonus-profit2.c2.d-flex {
    border-bottom: 1px solid #d4d4d4;
}
.bonus-c3 {
    position: relative;
    top: 6px;
}
.bonus-profit3.mt-5.pt-5.d-flex img {
    width: 22px;
    padding: 3px;
}
.bonus-profit3.mt-5.pt-5.d-flex.justify-content-between.text-center p {
    padding: 2px;
    font-size: 12px;
    color: grey;
}
.bonus-profit3.mt-5.pt-5.d-flex.justify-content-between.text-center {
    background: #f8f8f8;
    padding: 0 !important;
    margin-top: 140px !important;
}
.bonus-profit3.mt-5.pt-5.d-flex.justify-content-between.text-center i {
    color: grey;
    font-size: 12px;
}
.bonus-c3 i {
    color: grey;
    padding: 0 5px;
}
.bonus-c3 h6 {
    font-size: 15px;
}

.bonus-c3 p {
    color: grey;
    font-size: 14px;
    font-weight: 600;
}
.bonus-profits-box span {
    font-weight: 600;
    font-size: 14px;
}
.bonus-profit1.mt-3.py-3 h4 {
    color: #f18c20;
    font-weight: 600;
}
.bonus-content2 button {
    background: #f18c20;
    border: none;
    font-weight: 600;
    font-size: 16px;
    margin: 0 auto;
    display: block;
}
section.start-trade-bonus {
    padding-top: 0;
    padding-bottom: 60px;
}
.bonus-earn.text-center {
    padding: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background: #f7f7f7;
    border-radius: 5px;
    min-height: 130px;
    margin-top: 20px;
    transition: .7s ease-in-out;
    height: 100%;
}
.bonus-earn.text-center:hover{
    transition: .7s ease-in-out;
    transform: scale(1.1); 
    background: #f9f9f9;
}
.bonus-earn.text-center button {
    background: #f18c20;
    color: white;
    border: none;
    width: 100%;
    border-radius: 5px;
    font-weight: 600;
}
.bonus-earn.text-center button:hover{
    color: white !important;
}
.bonus-content2 button:hover {
    background: #f18c20;
}
.bonus-step1.mt-3.py-3 {
    background: #000423;
    color: white;
}

@media only screen and (max-width: 992px){
.bonus-content1.mt-5.text-center.d-flex.align-items-center {
    display: block !important;
}
.bonus-content1.mt-5.text-center.d-flex.align-items-center p {
    position: relative;
    font-size: 16px !important;
    top: -15px !important;
}
.bonus-content1.mt-5.text-center.d-flex.align-items-center {
    margin: 20px 0!important;
}
.bonus-step1 {
    text-align: center;
    margin-top: 74px !important;
}
.bonus-image {
    margin: 0 auto;
}
.bonus-content2 {
    margin: 0 auto;
}
.bonus-content1 {
    text-align: center !important;
    margin: 20px auto;
}
.bonus-content2.px-5 {
    padding: 0 !important;
    margin: 0 auto;
}
.bonus-profits-box {
    width: 100%;
}
.bonus-content1.mt-5.text-center.d-flex.align-items-center {
    min-height: 210px;
}
.bonus-content1.mt-5.text-center.d-flex.align-items-center.newbonus img {
    margin: 10px auto;
}
.bonus-step1:hover {
    transition:none !important;
    transform:none !important;

}
.bonus-content2 img {
    display: block;
    margin: 0 auto;
}
.bonus-content1 img {
    display: block;
    margin: 0 auto;
}
.bonus-waves1 {
    margin: 10px 0px;
}
.bonus-content2 img {
    margin: 0 auto !important;
}
.bonus-creation.mt-2 {
    text-align: center;
}
.bonus-earn.text-center {
    min-height: 230px;
}
.bonus-content1 p img {
    margin: 0 auto !important;
}
.bonus-value p {
    display: block !important;
}
.bonus-content1 {
  
    padding: 10px 10px;
}
}

@media only screen and (min-width: 768px) and (max-width: 992px) {

    .bonus-content2.px-5 {
        padding: 0 !important;
        margin: 0 auto;
    }
    .bonus-profits-box {
        width: 100%;
    }
    .bonus-content1.mt-5.text-center.d-flex.align-items-center p {
        font-size: 14px;

    }
    .bonus-earn.text-center button {
        background: #f18c20;
        color: white;
        border: none;
        padding: 8px 30px;
        border-radius: 5px;
        font-weight: 600;
        font-size: 13px;
    }

    .bonus-content2 button {
        background: #f18c20;
        border: none;
        font-weight: 600;
        
    }
    
    
}
.bonus-content1.mt-5.text-center img {
    width: 100%;
    max-width: 190px;
}

.bonus-earn.text-center img {
    width: 100%;
    max-width: 60px;
}

@media only screen and (max-width: 992px){
    .bonus-image.px-5 {
        padding: 0 !important;
        margin: 0 auto;
        text-align: center;
    }
    .bonus-content1 p {
       
        text-align: center;
    }
    .bonus-content2 button {
        margin-top: 10px;
    }
    
    
    .bonus-content2.px-5 button {
        margin: 10px 0;
    }
    .bonus-step1.mt-3.py-5 .col-lg-6.col-md-12.col-12.col-sm-12.d-flex.align-items-center {
        display: block !important;
    }
    .bonus-content1 {
        text-align: center;
    }
    .bonus-step1.mt-3.py-5 .col-lg-3.col-md-12.col-12.col-sm-12.d-flex.align-items-center{
        display: block !important;
    }
    .bonus-content2 {
        text-align: center;
    }
    
}




.bonus-value p{
    color: gray;
    display: flex;
    /* text-align: center; */
}
.bonus-value small{
    color: gray;
}
.bonus-content2 img {
    width: 100%;
    margin: 5px auto;
    display: block;
    max-width: 60px;
}
.bonus-content1 p img {
    width: 100%;
    /* right: 10px; */
    /* position: relative; */
    max-width: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .bonus-content2.px-5 {
        padding-right: 0 !important;
    }
.start-trade-bonus p {
       
        font-size: 15px;
        
    }
}

.bns-con2 {
    text-indent: unset !important;
}
.bonus-earn.text-center h5 {
    font-size: 17px;
    font-weight: 600;
}


@media only screen and (max-width: 768px) {
.bonus-earn.text-center:hover{
    transition: unset !important;
    transform: unset !important; 

}
}


.bonus-content2 button:focus{
    background-color: #f18c20 !important;
}