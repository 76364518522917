.mt5cont {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 350px;
    padding-top: 86px;
    background-color: #fcfcfc;
      background-image: url(../images/bgimgmt5.png);
  }
  
  .mt5cont img.img-fluid {
    width: 100%;
    max-width: 455px;
    float: right;
    height: 100%;
  }
  
  
  .mt5cont p{
    color: gray;
    font-size: 18px;
    
  }
  button.btn.getmeta5 {
    background-color: #f29531 !important;
    width: 100%;
    max-width: 200px;
    color: white;
    font-size: 18px;
    font-weight: 600;
    border: none;
  }


  .pricing-table{
    box-shadow: 0px 0px 18px #ccc;
    text-align: center;
    /* padding: 30px 0px; */
    border-radius: 5px;
    position: relative;
  
 }
 .pricing-table .head {
     border-bottom:1px solid #eee;
     padding-bottom: 50px;
     transition: all 0.5s ease;
     padding-top: 12px;
     background: linear-gradient(to right, #f18c20 0%, #c0813e 100%);
 }
 .pricing-table:hover .head{
    border-bottom:1px solid #f18c20;
    
 }
 
 .pricing-table .head .title{
     margin-bottom: 20px;
     font-size: 20px;
     font-weight: 700;
     color: white;
 }
 
 .pricing-table .content .price{
  background: linear-gradient(to right, #f18c20 0%, #c0813e 100%);
     width: 100px;
     height: 100px;
     margin: auto;
     
     border-radius: 50%;
     border: 5px solid #fff;
     box-shadow: 0px 0px 10px #ccc;
     margin-top: -50px;
      transition: all 0.5s ease;
      
 }
 .pricing-table:hover .content .price{
     transform: scale(1.2);
  
 }
 .pricing-table .content .price h6 {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  line-height: 86px;
  text-align: center;
}
 .pricing-table .content ul {
  list-style-type: none;
  margin-bottom: 20px;
  padding-top: 10px;
  padding: 20px 0;
}
 
.pricing-table .content ul li {
  
  font-size: 14px;
  color: #555;
  border-bottom: 1px solid #efeeee;
  padding: 13px 0;
}
 
 .pricing-table .content .sign-up{
  background: linear-gradient(to right, #f18c20 0%, #ec963a 100%);
     border-radius: 40px;
     font-weight: 500;
     position: relative;
     display: inline-block;
 }
 
 
 .pricing-table .btn {
   color: #fff;
  
   display: inline-block;
   text-align: center;
   font-weight: 600;
   -webkit-transition: all 0.3s linear;
   -moz-transition: all 0.3 linear;
   transition: all 0.3 linear;
   border: none;
   font-size: 14px;
   text-transform: capitalize;
   position: relative;
   text-decoration: none;
     margin: 2px;
     z-index: 9999;
     text-decoration: none;
     border-radius:50px;
  
 }
 
 .pricing-table .btn:hover{
   box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
 }
 
 .pricing-table .btn.bordered {
   z-index: 50;
   color: #333;
 }
 .pricing-table:hover .btn.bordered{
   color:#fff !important;
 }
 .pricing-table.gprice-single{
  transition: all .4s;
 }
 .pricing-table.gprice-single:hover{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
  transform: scale(1.05);
 }
 .pricing-table .btn.bordered:after {
   background: #fff none repeat scroll 0 0;
   border-radius: 50px;
   content: "";
   height: 100%;
   left: 0;
   position: absolute;
   top: 0;
   -webkit-transition: all 0.3s linear;
   -moz-transition: all 0.3 linear;
   transition: all 0.3 linear;
   width: 100%;
   z-index: -1;  
   -webkit-transform:scale(1);
   -moz-transform:scale(1);
   transform:scale(1);
 }
 .pricing-table:hover .btn.bordered:after{
   opacity:0;
   transform:scale(0);
 }

 .accountypes ul li{
  list-style: none;
  padding: 11px 10px;
  border-bottom: 1px solid #eeeaea;

 }
 .accountypes ul {
  
  position: relative;
  top: 196px;
}

.acctype p{
  color: gray;
}


@media only screen and (max-width: 992px){
  .accountypes ul {
  
    position: relative;
    top: unset !important;
  }
  .pricing-table.gprice-single {
   
    margin-top: 63px;
}

.accsli1 img.img-fluid{
  margin: 0 auto;
  display: block;
}
.accounttype p{
  text-align: center;

}
.accounttype h2{
  text-align: center;
}
.appdownloadicons {
  
  margin-top: 13px;
}
  
}

.accounttype p{
  color: gray;

}

.accsli1 img.img-fluid {width: 100%;max-width: 140px;}

.meta5 img.img-fluid {
  width: 100%;
  max-width: 70px;
 
 
}

.meta5 {
  margin: 0 auto;
  width: 80%;
}
.accslider {
  width: 90%;
  margin: 0 auto;
}

.acctype3 {
  background-color: #fafafa;
  padding-bottom: 44px;
}

.stand {
  background-color: #f2f2f2;
 
 
  border-left: 6px solid #fa7914;
 
  padding: 10px 10px;
}

.ecn{
  background-color: #f2f2f2;
 
  
  border-right: 6px solid #fa7914;
 
  padding: 10px 10px;
}

.premi{
  background-color: #f2f2f2;
  border-color: black;
  border-left: 6px solid #fa7914;
 
  padding: 10px 10px;
}

.appdownloadicons {
  border: 1px solid #e4dfdf;
  border-radius: 10px;
  padding: 8px 8px;
  height: 100%;
}
@media only screen and (min-width: 992px) and (max-width: 1200px){
  .accountypes ul {
    position: relative;
    top: 181px;
  }
  .accountypes ul li {
   font-size: 12px;
   padding: 10px 10px;
}
.pricing-table .content ul li{
  font-size: 12px;
  
}
}

@media only screen and (min-width: 1200px) and (max-width: 1400px){
  .accountypes ul li {
   
    padding: 14px 10px;
    
    font-size: 12px;
}
.accountypes ul {
  position: relative;
  top: 197px;
}
}
.getapara{
  text-align: center;
}