.loyalprogram {
    padding-top: 107px;
}

.rewardssection button.btn {
    height: 45px;
    background-color: #f18c20;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    max-width: 150px;

}

.rewardssection {
    background-color: #f8f8f8;
    height: 100%;
    padding: 10px 73px;
    width: 80%;
    margin: 0 auto;
    max-height: 317px;
}

.loyalprogram .card-profile {
    margin-top: 30px;
    text-align: center;
}

.loyalprogram .card {
    display: inline-block;
    position: relative;
    width: 100%;
    margin-bottom: 30px;
    border-radius: 6px;
    color: rgba(0, 0, 0, 0.87);
    background: #fff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    border: none;
    height: 100%;
    max-height: 400px;
}

.loyalprogram .card .card-image {
    height: 60%;
    position: relative;
    overflow: hidden;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: -30px;
    border-radius: 6px;
    box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.loyalprogram .card .table {
    padding: 15px 30px;
}

.loyalprogram .category {
    position: relative;

    margin: 15px 0;
}



.loyalprogram .card .card-image img.img-fluid {
    width: 100%;
    max-width: 234px;
}

.card .ftr {
    margin-top: 15px;
}

.deamt {
    color: #f18c20;
    font-weight: 600;
    font-size: 20px;
}

.loyalprogram .card-profile p {
    font-size: 18px;
}


.clients {
    background-color: #f5f5f5;
    padding: 15px;
    border-left: 3px solid #f18c20;
}

.clients-des .row {
    width: 50%;
    margin: 0 auto;
}

.clients-des p {
    font-size: 20px;
}

.clno h4 {
    background-image: linear-gradient(to right, #b8931b 0, #ca8822 22%, #b58621 45%, #918b41 50%, #c8b824 55%, #d0973f 78%, #a8913b 100%);
    color: transparent;
    -webkit-background-clip: text;
    font-size: px;
}

.loyaltycontent img {
    width: 100%;
    max-width: 60px;
}

.rewardssection img.img-fluid {
    width: 100%;
    max-width: 250px;
}

@media screen and (max-width: 992px) {

    .rewardssection {

        padding: 10px 18px;
        width: 100%;
        margin: 0 auto;
        max-height: 500px;
    }

    .rewardssection h1 {
        font-size: 24px;
        text-align: center;
    }

    .rewardssection p {
        font-size: 14px;
        white-space: nowrap;
        text-align: center;

    }

    .rewardssection button.btn {
        
 
        margin: 0 auto;
        display: block;
    }

    .loyalprogram .card{
        max-height: unset !important;
        height: auto !important;
    }

    .rewardssection img.img-fluid {
       
        margin: 0 auto;
        display: block;
    }
    .clients-des .row {
        width: 100%;

    }

    .getrewards {
        display: flex;
        gap: 18px;


    }

    .rewardcont {
        width: 80%;
    }

    .getrewards h5 {

        text-align: start;
    }

    .getrewards p {
        text-align: start;
    }
    
    /* .loyal-rewards .col-lg-3 {
        margin-bottom: 58px;
    } */

    .clients-des .col-md-12.col-lg-6 {
        margin-top: 15px;
    }
    .loyalprogram .card .table {
       padding: 0 !important;
    }

    .loyalprogram .card .card-image {
        box-shadow: unset !important;
    }
}

@media screen and (max-width: 1280px) {
    .loyalprogram .category {
        font-size: 16px;
    }
}

.iphoneor {
    color: #f18c20;
    font-weight: 500;
}