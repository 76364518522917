/* bannersec */

.pipcaltrade {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 350px;
  padding-top: 86px;
  background-color: #fcfcfc;
  background-image: url(../images/bgimgmt5.png);
}
.pipcaltrade img.img-fluid {
  width: 100%;
  max-width: 455px;
  float: right;
  height: 100%;
}

.pipcaltrade p {
  color: gray;
  font-size: 18px;
}
button.btn.getmeta5 {
  background-color: #f29531;
  width: 100%;
  max-width: 200px;
  color: white;
  font-size: 18px;
  font-weight: 600;
  height: 40px;
}
/* mainsec */
.pipcalsacc button.accordion-button {
  background-color: #f4f4f4 !important;
  color: black !important;
  font-size: 18px;
}
.pipcalsacc button.accordion-button:focus {
  box-shadow: unset !important;
}
.pipcalsacc.accordion-button::before {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%2016%2016'%3E%3Cpath%20fill='none'%20stroke='#343a40'%20stroke-linecap='round'%20stroke-linejoin='round'%20 stroke-width='2' %20d='M2%205l6%206%206-6'/%3E%3C/svg%3E");
  transform: scale(0.7) !important;
}
.pipcalsacc .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}

.pipcalsacc ul li {
  list-style: none;
  padding: 9px 0px;
}

.pipcalsacc .nav-tabs .nav-link {
  border: unset;
  color: black;
  font-weight: 500;
}

.pipcalsacc .nav-tabs {
  border-bottom: unset !important;
}
.pipcalsacc .nav-tabs .nav-link.active {
  color: #f29531;
}

.pipcalsacc i.fa.fa-cog.fs-9 {
  color: #8b8686;
  font-size: 13px;
}
button.btn.btn-calc {
  border: none;
  background-color: #f18c20;
  color: white;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  max-width: 200px;
}
button.btn.btn-calc:focus {
  background-color: #f18c20;
}
.btnpip1 a.pip {
  background-color: #f18c20;
  color: white;
  font-size: 16px;

  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  width: 100%;

  padding: 8px 9px;
}
.btnpip2 a.pip {
  background-color: #909090;
  color: white;
  font-size: 16px;

  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  width: 100%;

  padding: 8px 9px;
}
.pipcalsacc .card {
  border: 1px solid #ebebeb;

  border-radius: unset;
}

.formpip input#form3Example1 {
  width: 100%;
  max-width: 150px;
}

.formpip select.form-select {
  width: 150px;

  position: relative;
  top: 31px;
}

.pipvalsize {
  gap: 41px;
  color: gray;
}

.profitpip {
  gap: 47px;
}
.profitpip h6 {
  color: gray;
}

.forexcalcc .card {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.parasecpip p {
  text-align: justify;
  font-size: 18px;
  color: gray;

  width: 96%;
  margin: 0 auto;
  display: block;
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 992px) {
  .pipcaltrade img.img-fluid {
    margin-top: 30px;
  }
  .forexcal img.img-fluid {
    margin: 0 auto;
    display: block;
    width: 100%;
  }
  .profitpip {
    display: block !important;
  }
  .pipcaltrade {
    text-align: center;
  }
  .pipcaltrade p {
    text-align: center;
  }
  .pipcaltrade img.img-fluid {
    margin: 0 auto;
    float: unset !important;
    display: block;
  }
  .pipcalsacc label.form-label {
    white-space: nowrap;
  }
  .btnpip {
    display: block !important;
  }
  .btnpip2 a.pip {
    margin-top: 20px;
  }
  .forexcalcc {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .pipcalsacc .card {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1100px) {
  .btnpip {
    gap: 12px;
  }
}

.pipcalculator .form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #000;
  opacity: 1; /* Firefox */
}

.pipc a {
  display: none !important;
}
/* p.pips {
  margin-top: -74px;
} */
