/* ==================================banner==================================== */
.Negativebalance .img-fluid {
  width: 100%;
  height: auto;
  max-width: 455px;
}

.Negativebalance h5 {
  font-size: 17px;
}

.Negativebalance .n-balance {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 350px;
  padding-top: 86px;
  background-color: #fcfcfc;
  background-image: url(../images/bgimgmt5.png);
}

.Negativebalance .n-balance img.img-fluid {
  float: right;

}

.Negativebalance .n-balance p {
  color: gray;
}

.Negativebalance button.btn.getmeta5 {
  background-color: #f29531;
  width: 100%;
  max-width: 200px;
  color: white;
  font-size: 18px;
  font-weight: 600;
}


/* ==========================bannernext========================== */

.Negativebalance section.nb-balance {
  margin-top: 60px;
  text-align: initial;
}

.Negativebalance p {
  color: gray;
}

.Negativebalance .user-avatar img {
  width: 50px;
  height: 50px;
  float: right;
  background: #d2e4f4;
  border-radius: 25px;
}

.Negativebalance .user-logo img {
  width: 50px;
  height: 50px;
  float: right;
  margin-right: -40px;
  margin-top: 10px;
}

.Negativebalance .message-2 p {
  font-size: 15px;
}

.Negativebalance .message-2 {
  background: aliceblue;
  padding: 10px;
  float: right;
  width: 80%;
  border-radius: 10px;
  margin-right: -150px;
}


/* -============================================nb-cards================================= */
section.nb-cards {
  text-align: initial;
}

.Negativebalance .nb-image1.card {
  height: 90%;

  text-align: center;
  padding: 15px;
}

.Negativebalance .nb-image1.card img {
  width: 100%;
  margin: 0 auto;
  max-width: 60px;
}

.Negativebalance .n-balance-line1 {
  background: #f8f8f8;
  padding-top: 30px;
  

}


.Negativebalance .nb-image1.card:hover {
  box-shadow: 8px 3px 10px 4px #bebbbb;
}

.Negativebalance .n-balance-line1 p {
  margin-top: 10px;
}

.Negativebalance .n-balance-line2 {
  margin-top: 40px;
}

/* =======================get startted==================== */
.Negativebalance .box1get h4 {
  font-size: 17px;
  font-weight: 600;
}

.Negativebalance .box1get p {
  color: gray;
}

.Negativebalance .getsbtn {
  gap: 104px;
}

.Negativebalance .getimg img.img-fluid {

  position: relative;

}

.Negativebalance .openlivebtn button.btn.btn-primary {

  background-color: #f29531;
  border: none;
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

.Negativebalance .opendemobtn button.btn.btn-primary {
  background-color: #909090;
  border: none;
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

.Negativebalance .box1get img.img-fluid {
  max-width: 50px;
  max-height: 50px;
  width: 100%;
}


/* ==========================get-started media=========================== */

@media only screen and (max-width: 768px) {
  .Negativebalance section.getmt5.mt-4.pb-5 .box1get {
    text-align: center;
  }

  .Negativebalance .user-logo img {
    margin-top: -190px;
  }

  .Negativebalance .d-flex.justify-content-center.getsbtn.mt-5 {
    display: block !important;
    margin: 10px 0;
  }

  .Negativebalance section.getmt5.mt-4.pb-5 .openlivebtn {
    margin: 10px 0;
  }

  .Negativebalance section.getmt5.mt-4.pb-5 .getimg {
    text-align: center;
  }

  .Negativebalance section.getmt5.mt-4.pb-5 .getimg img.img-fluid {
    position: relative;
    right: 0px !important;
  }
}

@media only screen and (min-width: 590px) and (max-width: 768px) {
  .Negativebalance .user-logo img {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 992px) {

  .Negativebalance .user-logo img {
    float: inherit;
    margin-right: unset;

  }
  .Negativebalance .n-balance img.img-fluid {
    float: unset !important;
  
  }
  
  .Negativebalance .message-1 {
    width: 80%;
  }

  .Negativebalance .message-2 {
    float: right;
    border-radius: unset;
    margin-right: unset;
  }

  .Negativebalance {
    text-align: center;
  }

  .Negativebalance .user-avatar img {
    border-radius: 25px;
    margin-top: 20px;
    margin-bottom: -47px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .Negativebalance section.getmt5.mt-4.pb-5 .d-flex.justify-content-center.getsbtn.mt-5 {
    justify-content: center !important;
  }

  .Negativebalance .getsmt5 {
    text-align: center;
  }

}



@media only screen and (min-width: 769px) and (max-width: 1400px) {
  .Negativebalance .getimg img.img-fluid {
    display: block;
    margin: 0 auto;
    right: unset !important;
  }
}

@media screen and (max-width: 1020px) {

  .Negativebalance .box1get {
    margin: 0 auto;
    display: block;
    text-align: center;
  }
}