.notfound-button button {
  background: #c6a55e;
  border: none;
  color: white;
  padding: 10px 30px;
  font-weight: 600;
}
.notfound-button button:hover {
  color: white;
  background: #c6a55e;
}
.notfound-button button:focus {
  color: white !important;
  background: #c6a55e !important ;
}
