.checkout-section {
    padding-top: 70px;
}


.checkout-section select {
    height: 40px;
    border-radius: 5px;
    border-color: #dee2e6;
}

.checkout-section .form-control:focus {
    box-shadow: unset;
    border: 1px solid #f18c20;
}

.checkout-section p {
    color: gray;
}

.checkout-section .input-group-text {
    border-radius: 0;
}

.placebtn {
    background-color: #f18c20;
    border: none;
    border-radius: 5px;
    width: 100%;
    max-width: 200px;
}

@media only screen and (max-width: 768px) {
    .custom-control.custom-checkbox {
        display: flex;
        align-items: baseline;
    }

}