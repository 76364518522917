.headset {
    height: 11rem;
}

#cart-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.free-shipping {

    color: #fff;
    font-size: 0.65rem;
    background-color: #191649;
    border: 0;
    border-radius: 0.9rem;
    padding: 0.3rem 0.4rem;
    margin-bottom: 0.5rem;
}

.stock i {
    color: #f18c20;
    font-size: 8px;
}

.add-to-cart {
    width: 100%;
    height: 2.7rem;
    max-width: 193px;
    font-size: 1rem;
    background-color: #f18c20;
    color: #fff;
    font-weight: 400;
    border: 0;
    border-bottom: 0.3rem solid #f9b707;
    border-radius: 0.4rem;
    margin-bottom: 1rem;
    cursor: pointer;
}

.add-to-cart:active {
    border-bottom: 0;
}

.stock {
    font-weight: 700;
    text-decoration: none;
    margin-bottom: 2rem;
    align-items: center;
    display: flex;
    gap: 7px;
}



.cart-section .cart-button {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

.add-to-cart-2,
.add-to-wishlist {

    font-size: 1rem;
    font-weight: 400;
    height: 2.7rem;
    width: 11rem;
    background-color: #fff;
    border: 0.1rem solid #d6d5d9;
    border-radius: 0.2rem;
    cursor: pointer;
}

.add-to-cart-2 i,
.add-to-wishlist i {
    margin-right: 0.5rem;
}

.add-to-cart-2:hover,
.add-to-wishlist:hover {
    border: 0.1rem solid #a7a7aa;
}

.cart-section {
    padding-top: 100px;
}

.add-cart {
    width: 100%;
    max-width: 80%;
    margin: 0 auto;
    display: block;
    background-color: #ffffff;
    -webkit-box-shadow: 0px 15px 11px -6px #cdd4e6;
    box-shadow: 0px 15px 11px -6px #cdd4e6;
    display: flex;
    align-items: flex-start;
    padding: 3rem;
    gap: 2rem;
    border-radius: 0.5rem;
}

@media only screen and (max-width: 768px) {
    .add-cart {
        display: block;
        max-width: 100% !important;
        padding: 10px;
    }

    .cart-section .cart-button {
        display: block;
      
        margin: 0 auto;
    }
    .cart-section .add-to-cart-2{
        margin-bottom: 10px;
        
    }
}