.mt5cont {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 350px;
    /* padding-top: 86px; */
    background-color: #fcfcfc;
      background-image: url(../images/bgimgmt5.png);
  }
  .mt5cont img.img-fluid {
    width: 100%;
    max-width: 455px;
    float: right;
    height: 100%;
  }
  section.mt5trade {
    padding-top: 0;
}
/* section.mt5trade p {
    font-size: unset !important;
} */
  .mt5cont p{
    color: gray;
  }
  button.btn.getmeta5 {
    background-color: #f18c20;
    width: 100%;
    max-width: 200px;
    color: white;
    font-size: 18px;
    font-weight: 600;
}
button.btn.getmeta5:hover{
    color: white !important;
}
.trading-image.p-4.px-5 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
section.trade-fees-content {
    padding-top: 0 !important;
    padding-bottom: 0px !important;
}
section.trade-fees-content a {
    color: #4285f4;
    font-size: 13px;
}
.trade-content1.mt-3 i {
    font-size: 11px;
    color: #fbbc05;
    padding: 0 5px;
}
.trade-content1.mt-3 span {
    font-size: 13px;
    color: black;
}
.trading-image.p-4.px-5 img {

    margin: 0 auto !important;
    display: block;
    padding: 30px;
}
section.trade-spreads-page {
    padding-top: 0 !important;
    background: #fafafa;
    padding-bottom: 50px;
}
.trade-spread-content1 button {
    background: #f18c20 !important;
    border: none;
    color: white !important;
    font-weight: 600;
    padding: 10px;
}


.trade-spread-content1 button:hover{
    color:white !important;
}



section.trade-video-page {
    padding: 0 !important;
    background-color: #100f25;
}

.comision-page2.mt-3.py-3 i {
    /* padding: 3px 15px; */
    color: #f18c20;
    font-size: 15px;
}
.comision-page2.mt-3.py-3 small {
    text-indent: 0px;
    display: flex;
}
.comision-page2.mt-3.py-3 p {
    display: flex;
}
.trade-spread-image.mt-3 img {
    float: right;
}
/* section.trade-fees-content p {
    font-size: 14px;
}
section.trade-spreads-page p {
    font-size: 14px;
}
section.trade-video-page p {
    font-size: 14px;
} */
section.trade-comision {
    padding-top: 0 !important;
    padding-bottom: 40px !important;
}
section.trade-stocks {
    padding-top: 0 !important;
    background:url(../images/mani/comision1.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 20px !important;
}
section.trade-price {
    padding-top: 0 !important;
    padding-bottom: 40px !important;
}

.trade-price2.mt-3.py-3 {
    background: #f9f9f9;
    text-align: center;
    padding: 25px;
    
}
.trade-price2.mt-3.py-3 h3 {        
    color: #f18c20 ;
    font-weight: 600;
    font-size: 45px;
}
.trade-price2.mt-3.py-3 span {
    font-size: 18px;
    font-weight: 600;
}
.trade-price2.mt-3.py-3 p {
    font-size: 14px;
    padding: 5px;

}
.trade-price2.mt-3.py-3:hover {
    background: #f4f4f4;
}


.trade-stock2.py-3.text-white.d-flex img {
    width: 100px;
    padding: 20px;
    position: relative;
    bottom: 6px;
}

/*yuva style get started */

.trade .box1get h4 {
    font-size: 17px;
    font-weight: 600;
}

.trade .box1get p {
    color: gray;
}

.trade .getsbtn {
    gap: 104px;
}

.trade .getimg img.img-fluid {

    position: relative;
    right: -65px;
}

.trade .openlivebtn button.btn.btn-primary {

    font-weight: 0;
    font-size: 14px;
    color: #fff;
    background-color: #f18c20;
    padding: 9px 50px;
    border: solid #f18c20 2px;
    box-shadow: rgb(0, 0, 0) 0px 0px 0px 0px;
    border-radius:8px;
    transition : 1000ms;
    transform: translateY(0);
    /* display: flex; */
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    text-align: center;
}
.trade .openlivebtn button.btn.btn-primary:hover{
    transition : 1000ms;
    padding: 10px 50px;
    transform : translateY(-0px);
    background-color: #fff;
    color: #f18c20;
    border: solid 2px #f18c20;
}



.trade .opendemobtn button.btn.btn-primary {
    font-weight: 0;
    font-size: 14px;
    color: #fff;
    background-color: #f18c20;
    padding: 10px 30px;
    border: solid solid #7c7c7c 2px;
    box-shadow: rgb(0, 0, 0) 0px 0px 0px 0px;
    border-radius:8px;
    transition : 1000ms;
    transform: translateY(0);
    /* display: flex; */
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding: 10px 50px;
}
.trade .opendemobtn button.btn.btn-primary:hover{
    transition : 1000ms;
    padding: 10px 50px;
    transform : translateY(-0px);
    background-color: #fff  !important;
    color: #f18c20;
    border: solid 2px #bababa;
}


.trade .box1get img.img-fluid {
    max-width: 50px;
    max-height: 50px;
    width: 100%;
}

section.trade.getmt5.mt-4.pb-5 {
    padding: 0;
}

/*get started end */

@media only screen and (max-width: 992px){
    .trading-image.p-4.px-5 img {
        width: 100%;
    }
    .trade-spread-content1.px-5 {
        padding: 0 !important;
        margin: 30px 0;
    }
    .trade-spread1-image {
        margin: 50px 0;
    }
    .trade-video-content.px-5.text-white {
        padding: 0 !important;
        text-align: center;
    }
    .trade-video.mt-3.py-5 iframe {
        width: 100%;
    }
    .trade-stock2.py-3.text-white.d-flex.align-items-center {
        display: block !important;
        text-align: center;
    }
    .trade-spread-image.mt-3 img {
        float: unset !important;
    }

  
}
@media only screen and (min-width: 768px) and (max-width: 1200px) {
    .trade-price2.mt-3.py-3 h3 {
        font-size: 28px;
    }
    .trade-price2.mt-3.py-3 span {
        font-size: 15px;
    }
    .trade-price2.mt-3.py-3 {
        /* min-height: 252px; */
        height: unset !important;
    }
 
}
@media only screen and (max-width: 768px){
    section.trade.getmt5.mt-4.pb-5 .box1get {
        text-align: center;
    }
    .trade .d-flex.justify-content-center.getsbtn.mt-5 {
        display: block !important;
        margin: 10px 0;
    }
    section.trade.getmt5.mt-4.pb-5 .openlivebtn {
        margin: 10px 0;
    }
    section.trade.getmt5.mt-4.pb-5 .getimg {
        text-align: center;
    }
    section.trade.getmt5.mt-4.pb-5 .getimg img.img-fluid {
        position: relative;
        right: 0px !important;
    }
 
}

@media only screen and (min-width: 768px) and (max-width: 992px){
    section.trade.getmt5.mt-4.pb-5 .d-flex.justify-content-center.getsbtn.mt-5 {
        justify-content: center !important;
    }
    section.trade.getmt5.mt-4.pb-5 .box1get {
        text-align: center;
    }
}
/* .trade .opendemobtn button.btn.btn-primary:hover{
    background-color: #909090 !important;
  } */
  @media only screen and (max-width: 992px){
  .trading-content2 {
    text-align: center;
}
.trade-spread-content1.px-5 {
    padding: 0 !important;
    text-align: center;
}
.trade-spread-image.mt-3 {
    text-align: center;
}
.trade-spread1-image {
    text-align: center;
}
.trade-spread-image {
    text-align: center;
}
.trade-video.mt-3.py-5 {
    text-align: center;
}
  }

  .trading-fees-value p{
    color: gray;
  }
  .trading-fees-value small{
    color: gray;
  }
  .trade-stock2.py-3.text-white.d-flex.align-items-center p {
    color: white !important;
}

.trade-price2.mt-3.py-3 {
    height: 100%;
}
.trade-video iframe {
    width: 100%;
}


.trade-spread-image img.img-fluid {
    float: right;
}


@media only screen and (max-width: 992px) {
.trade-price .col-lg-4.col-md-12.col-12.col-sm-12 {
    margin-top: 20px;
}
.trade-spread-image img{
    float: unset !important;
}
}