

/* ============================Mam trade============================== */
.Mam-trade .Mam-trade-banner .img-fluid {
  width: 100%;
  height: auto;
  max-width: 455px;
  float: right;
}
.Mam-trade h5 {
  font-size: 17px;
  font-weight: 600;
}
.Mam-trade p {
    color: grey;
 
  }
 .Mam-trade .Mam-trade-banner {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 350px;
    padding-top: 86px;
    background-color: #fcfcfc;
    background-image: url(../images/bgimgmt5.png);
  }
  .Mam-trade-banner img {
    width: 100%;
    max-width: 500px;
    float: right;
    height: 100%;
}
.Mam-trade img.img-fluid {
  max-width: 500px;
  width: 100%;
  
}
  
  
  .Mam-trade .Mam-trade-banner p {
    padding-left: 18px;
    font-size: 18px;
  
}
  
 .Mam-trade .Mam-trade-banner p{
    color: gray;
  }
 .Mam-trade button.btn.getmeta5 {
    background-color: #f29531;
    width: 100%;
    max-width: 200px;
    color: white;
    font-size: 16px;
    font-weight: 600;
  }

  /* ========================================mam-account================================ */
  .Mam-trade .mam-account h2 {
    margin-bottom: 20px;
}
 .Mam-trade .mam-account {
    padding-top: 40px;
    padding-bottom: 10px;
}
 /* ========================================mam-account-work================================ */
 .Mam-trade .mam-account-work {
  text-align: initial;
}
 .Mam-trade .mam-account-work h2 {
  margin-bottom: 20px;
}

.Mam-trade .mam-account {
  
  padding-bottom: 30px;
  text-align: center;
}
.account-work img {
  width: 100%;
}
/* =====================================why choose mam============================ */

/* ===================================8 reasons============================== */
.Mam-trade .mam-resons {
 
  background: #fbfbfb;

}

.Mam-trade .mam-reason {
 
  background: #fbfbfb;
  margin-top: 10px;
  padding: 10px;
}
.Mam-trade .mam-reason img {
  max-width: 60px;
  width: 100%;

}
.mam-reason.d-flex p {
  padding-left: 15px;
}
/* ==============================milliva mam========================= */
.Mam-trade .milliva-mam {
  /* padding-top: 35px; */
  padding-bottom: 20px;
}
.Mam-trade .Milliva-Mam h2 {
  margin-bottom: 15px;
}
/* ==========================join mam=============================== */
.Mam-trade .Mam-trade .join-mam {
  padding-top: 60px;
  padding-bottom: 40px;
}
.Mam-trade .join-mam h2 {
  margin-bottom: 30px;
}
.Mam-trade .mam-account-workbutton.btn.getmeta5 {
  background-color: #f29531;
  width: 100%;
  max-width: 200px;
  color: white;
  margin: 0 auto;
  font-size: 18px;
  display: block;
  font-weight: 600;
}
.Mam-trade .join-mam button.btn.getmeta5 {
    display: block;
    margin: 0 auto;
}
/* ===================================why choose us=================== */
.Mam-trade .why-choose.card {
  height: 100%;
  padding: 15px;
  text-align: initial;
  min-height: 150px;
}
.Mam-trade .mam-image.mt-2 img {
  width: 50px;
  margin-right: 12px;
  max-width: 60px;
}
.Mam-trade .join-mam img {
  margin: 0 auto;
  width: 100%;
  display: block;
  max-width: 60px;
}
.Mam-trade .join-our-mam {
  text-align: center;
  margin-top: 20px;
}
.Mam-trade .join-mam {
  margin-bottom: 50px;
  margin-top: 10px;
}
@media only screen and (max-width: 992px){
  .mambb{
    position: unset !important;
    top:unset !important;
  }
}

/* ==========================get-started media=========================== */

@media only screen and (max-width: 768px){
.Mam-trade section.getmt5.mt-4.pb-5 .box1get {
        text-align: center;
    }
    section.Mam-trade {
      text-align: center;
  }
  .Mam-trade .mam-account-work {
    text-align: center;
}
.Mam-trade .d-flex.justify-content-center.getsbtn.mt-5 {
        display: block !important;
        margin: 10px 0;
    }
.Mam-trade section.getmt5.mt-4.pb-5 .openlivebtn {
        margin: 10px 0;
    }
.Mam-trade section.getmt5.mt-4.pb-5 .getimg {
        text-align: center;
    }
.Mam-trade section.getmt5.mt-4.pb-5 .getimg img.img-fluid {
        position: relative;
        right: 0px !important;
    }
  }
  @media only screen and (max-width: 992px){
  .Mam-trade .Mam-trade-banner .img-fluid {
    margin: 0 auto;
    float: unset;
    display: block;
}
.why-Mam .col-lg-3.col-md-12.col-sm-12 {
  margin-top: 20px;
}
.Mam-trade img.img-fluid {
  margin: 0 auto;
  display: block;
}
.Mam-trade .mam-account-work {
  text-align: center !important;
}
section.Mam-trade {
  text-align: center;
}
.Mam-trade .mam-image.mt-2 img {
  margin: 0 auto;
  display: block;
  margin-bottom: 15px;
}
.mam-text {
  text-align: center;
}
.Mam-trade .mam-resons{
  display: unset !important;
}
.Mam-trade .mam-reason{
  display: block !important;
}
}
.mam-resons .row {
  width: 80%;
  margin: 0 auto;
}
  
  
  @media only screen and (min-width: 768px) and (max-width: 992px){
.Mam-trade section.getmt5.mt-4.pb-5 .d-flex.justify-content-center.getsbtn.mt-5 {
        justify-content: center !important;
    }
    .Mam-trade .d-flex {
      display: unset !important;
    
  }
  .Mam-trade .why-choose.card {
    height: 95%;
  }
  .Negativebalance .getsmt5 {
    text-align: center;
}
.Mam-trade .mam-reason {
  
  display: block !important;
}
}
 
.mambb{
  position: relative;
  top: 23px;
}

.transp {
  overflow-y: hidden;
  height: 100px;
}