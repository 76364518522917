/* ============================algo trade============================== */
.Algo-trade .algo-trade-banner.img-fluid {
  width: 100%;
  height: auto;
  max-width: 455px;
}
.Algo-trade p {
  color: gray;
  display: flex;
}
.algotrading p {
  display: flex;
}
.why-use p{
  display: flex;
}
.How-to-do p{
  display: flex;
}
/* .benifits p{
  display: flex;
} */
.Algo-trade h5 {
  font-size: 17px;
  font-weight: 600;

}

.Algo-trade .algo-trade-banner {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 350px;
  padding-top: 86px;
  background-color: #fcfcfc;
  background-image: url(../images/bgimgmt5.png);
}

.Algo-trade .algo-trade-banner img.img-fluid {

  max-width: 455px;
  float: right;
  width: 100%;

}

.Algo-trade .algo-trade-banner p {
  color: gray;
  font-size: 18px;
  text-indent: unset;
}

.Algo-trade button.btn.getmeta5 {
  background-color: #f29531;
  width: 100%;
  max-width: 200px;
  color: white;
  font-size: 18px;
  font-weight: 600;
}

/* ======================================banner-next======================== */
.Algo-trade .algorithem img {
  width: 100%;
  max-width: 500px;
  float: right;
}




.Algo-trade .algotrading p i {
  color: #f29531;
  font-size: 15px;
  padding-right: 15px;
}

/* =====================why-use======================= */
.Algo-trade .why-use p i {
  color: #f29531;
  font-size: 15px;
  padding-right: 15px;
}

.Algo-trade .why-use h5 {
  margin-bottom: 20px;
}

/* ==================================how to do===================== */
.Algo-trade .How-to-do p i {
  color: #f18c1f;
  font-size: 15px;
  padding-right: 15px;
}

.Algo-trade .How-to-do h5 {
  margin-bottom: 20px;
  margin-top: 40px;
}


/* =============================benifits==================== */
.Algo-trade .benifits p i {
  color: #f29531;
  font-size: 15px;
  padding-right: 10px;
}


.Algo-trade .benifits img {
  max-width: 500px;
  width: 100%;
}

/* =======================get startted==================== */
.Algo-trade .box1get h5 {
  font-size: 17px;
  font-weight: 600;
}

.Algo-trade .box1get p {
  color: gray;
  text-indent: unset;
}
.Algo-trade .getsbtn {
  gap: 104px;
}

.Algo-trade .getimg img.img-fluid {
  right: -65px;
  position: relative;

}

.Algo-trade .openlivebtn button.btn.btn-primary {

  background-color: #f29531;
  border: none;
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

.Algo-trade .opendemobtn button.btn.btn-primary {
  background-color: #909090;
  border: none;
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

.Algo-trade .box1get img.img-fluid {
  max-width: 50px;
  max-height: 50px;
  width: 100%;
}


/* ==========================get-started media=========================== */

@media only screen and (max-width: 768px) {
  .Algo-trade section.getmt5.mt-4.pb-5 .box1get {
    text-align: center;
  }
 
  /* section.Algo-trade {
  text-align: center;
} */
  .Algo-trade .d-flex.justify-content-center.getsbtn.mt-5 {
    display: block !important;
    margin: 10px 0;
  }

  .Algo-trade section.getmt5.mt-4.pb-5 .openlivebtn {
    margin: 10px 0;
  }

  .Algo-trade section.getmt5.mt-4.pb-5 .getimg {
    text-align: center;
  }

  .Algo-trade section.getmt5.mt-4.pb-5 .getimg img.img-fluid {
    position: relative;
    right: 0px !important;
  }

  .Algo-trade .algorithem img {

    float: unset !important;
  }
}


@media only screen and (max-width: 992px) {
  section.algo-banner {
    text-align: center !important;
  }
  .Algo-trade .getimg img.img-fluid{
    position: unset !important;
    right:unset !important;
    margin: 0 auto;
    display: block;
  }
  


  .Algo-trade button.btn.getmeta5 {
    margin: 0 auto !important;
  }

  .Algo-trade .algo-trade-banner img.img-fluid {
    margin-top: 14px !important;
    margin: 0 auto;
    display: block;
    float: unset;
  }
 
  
  .Algo-trade h2,
  h5 {
    text-align: center;
  }



  .Algo-trade .algorithem img {
    float: unset !important;
    margin: 0 auto;
    display: block;

  }

  .Algo-trade button.btn.getmeta5 {

    margin: 0 auto;
    display: block;

  }

  .Algo-trade .benifits img {
    margin: 0 auto;
    display: block;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .Algo-trade section.getmt5.mt-4.pb-5 .d-flex.justify-content-center.getsbtn.mt-5 {
    justify-content: center !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1400px) {
  .Algo-trade .getimg img.img-fluid {
    display: block;
    margin: 0 auto;
    right: unset !important;
  }
}

@media screen and (max-width: 1020px) {

  .Algo-trade .box1get {
    margin: 0 auto;
    display: block;
    text-align: center;
  }
}

@media only screen and (max-width: 768px) {
section.algo-banner p {
  display: block;
}
}

@media only screen and (max-width: 992px) {
.Algo-trade .box1get p {
  display: block;
}
}