

/*--------------------------------------------------------------
# white-banner-mohana
--------------------------------------------------------------*/
.white-lable .white-lable-banner img {
  width: 100%;
  max-width: 455px;
 float: right;
}
.white-lable h5{
  font-size: 17px;
}
.white-lable .white-lable p {
    color: gray;

  }

  .white-lable .white-lable-banner {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 350px;
    padding-top: 86px;
    background-color: #fcfcfc;
    background-image: url(../images/bgimgmt5.png);
  }
  
  
  .white-lable .white-lable-banner p{
    color: gray;
    font-size: 18px;

  }
  .white-lable button.btn.getmeta5 {
    background-color: #f18c1f;
    width: 100%;
    max-width: 200px;
    color: white;
    font-size: 18px;
    font-weight: 600;
  }
  /* ========================what is white====================== */
 
  /* ==========================partenship======================== */
  .white-lable .partnership {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .white-lable .benifits h2 {
    margin-bottom: 30px;
    text-align: center;
   
  }
  .white-lable .white.card {
    width: 100%;
    height: 100%;
    min-height: 190px;
    text-align: center;
    padding: 10px;
    padding-top: 40px;
    box-shadow: 4px 4px 11px 1px #ebebeb;
    border: unset;
  }
  .white-lable .white.card:hover {
    box-shadow: 0px 1px 11px 1px #f6c946;
  }
  .white-lable .white.card img {
    width: 50px;
    display: flex;
    margin: 0 auto;
    padding-bottom: 15px;
  }
  .white-lable section#white-benifits {
    padding-top: 60px;
    padding-bottom: 50px;
  }
  .white-lable .card-line1 {
    margin-bottom: 25px;
  }
  .white-lable button.btn.getmeta5 {
    background-color:#f18c1f;
    width: 100%;
    max-width: 200px;
    color: white;
    font-size: 18px;
    font-weight: 600;
  }
  .white-lable section#partnership-program {
    background:#fafafa;
   
    color:black;
    
  }
  .white-lable section#partnership-program h2 {
    text-align: center;
    margin-bottom: 40px;
  }
  .white-program h1 {
    color: #f29531;
  }
  .white-lable .line-one {
    margin: 0 auto;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .white-lable .line-two {
    margin: 0 auto;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  
  
  /* =======================get startted==================== */
  .white-lable .box1get h5{
    font-size: 17px;
    font-weight: 600;
  }
  .white-lable .box1get p{
    color: gray;
  }
  
  .white-lable .getsbtn {
    gap: 104px;
  }
  .white-lable .getimg img.img-fluid {
    right: -65px;
    position: relative;
  }
  .white-lable .openlivebtn button.btn.btn-primary {
  
    background-color: #f18c1f;
    border: none;
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
  }
  .white-lable .opendemobtn button.btn.btn-primary {
    background-color:#909090;
    border: none;
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
  }
  .white-lable .box1get img.img-fluid {
    max-width: 50px;
    max-height: 50px;
    width: 100%;
  }
  
  .white-lable section.request {
    text-align: center;
    /* background: #efefef; */
    margin-top: 25px;
    padding: 20px;
    /* border: 1px solid #cbcbcb; */
  }
  
  .white-lable .form-floating>.form-select {
    padding-top:unset !important;
    padding-bottom: unset !important;
  }
  .white-lable section.input-filed .form-floating {
    width: 95%;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .white-lable section.input-filed {
    padding-top: 12px;
    /* margin-bottom: 52px; */
    border: 1px solid #cbcbcb;
    padding-bottom:0;
}
  .white-lable button.btn.btn-requestcall {
    background: #f29531;
    color: white;
    width: 100%;
    margin: 0 auto;
    font-size: 16px;
    height: 40px;
    border-radius: 5px;
    display: block;
    max-width: 250px;
    font-weight: 600;
}
  .white-lable .form-check .form-check-input {
    float: none !important;
    margin-bottom: 20px;
    margin-left: -1.5em;
  }
  .white-lable section.call-back img {
    width: 100%;
    height: auto;
    /* max-height: 505px; */
    
}
  .white-lable section.call-back-man{
  
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
  
  
  .white-lable .flip-box-back p {
    color: white;
    margin-top: 45px;
  }
  /* The flip box container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
  .white-lable .flip-box {
    background-color: transparent;
    width: 100%;
    margin: 0 auto;
    height: 200px;
    border: 1px solid #f1f1f1;
    perspective: 1000px; /* Remove this if you don't want the 3D effect */
  }
  
  /* This container is needed to position the front and back side */
  .white-lable .flip-box-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  
  /* Do an horizontal flip when you move the mouse over the flip box container */
  .white-lable .flip-box:hover .flip-box-inner {
    transform: rotateY(180deg);
  }
  
  /* Position the front and back side */
  .white-lable .flip-box-front,
  .flip-box-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
  }
  .white-lable .flip-box-front img {
    width: 100%;
    max-width: 60px;
  }
  .white-lable .flip-box-front img {
    margin-top: 50px;
  }
  /* .white-lable .flip-box:hover {
    box-shadow: 8px 3px 10px 4px #fbbc0545;
  } */
  /* Style the back side */
  .white-lable .flip-box-back {
    background-color: #ffffff;
    transform: rotateY(180deg);
  }
  .white-lable .flip-box-back p {
    color: gray;
  }
  .white-lable p{
    color: gray;
    
  }
  /* ==========================get-started media=========================== */
  
  @media only screen and (max-width: 768px){
    .white-lable  section.getmt5.mt-4.pb-5 .box1get {
        text-align: center;
    }
    .white-lable {
      text-align: center;
  }
  
    .white-lable .image-partner {
      margin-top: 20px;
  }
    .white-lable .d-flex.justify-content-center.getsbtn.mt-5 {
        display: block !important;
        margin: 10px 0;
    }
    .white-lable section.getmt5.mt-4.pb-5 .openlivebtn {
        margin: 10px 0;
    }
    .white-lable section.getmt5.mt-4.pb-5 .getimg {
        text-align: center;
    }
    .white-lable section.getmt5.mt-4.pb-5 .getimg img.img-fluid {
        position: relative;
        right: 0px !important;
    }
  }
  @media only screen and (max-width: 786px){

    .what-is-white img {
  
      margin-top: unset !important; 
   }
   .white-lable .partnership {
    padding-top: 30px;
   }
  }
 
  
  .white-lable .what-is-white img {
    width: 100%;
    max-width: 500px;
     /* margin-top: 80px;  */
  }
  
  
  .white-lable-banner img {
      max-width: 455px;
      width: 100%;
      
  }

  @media only screen and (max-width: 992px){
  .white-lable button.btn.getmeta5 {
    margin: 0 auto;
}
.whit-banner-img {
  position: relative !important;
  top: 18px !important;
}
.white-lable p{
  color: gray;
  text-align: center;
}
.white-lable .form-check.mb-3 input {
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding: 7px;
}
.white-lable .form-check.mb-3{
  padding: 5px !important;
}
.white-lable .form-check.mb-3 label{
  padding: 0 3px;
}
.white-lable .getimg img.img-fluid {
  
  margin: 0 auto;
  display: block;
  width: 100%;
 
  right: unset;

}
.white-lable .flip-box{
  margin-top:12px;
}
.white-lable .what-is-white img {
  width: 100%;
  max-width: 500px;
  margin-top: 80px;
  margin: 0 auto;
  display: block;
  float: unset !important;
}
.white-lable .what-is-white img {
  
  margin: 0 auto;
  display: block; 
}
.white-lable {
  text-align: center !important;
}
section#white-benifits .col-lg-4.col-md-4.col-sm-4 {
    margin-top: 10px;
}
.white-lable .card-line1 {
  margin-bottom: unset !important;
}
.white-lable section.input-filed {
  padding-bottom: 15px;
}
.white-lable button.btn.btn-requestcall {

  margin-top: 10px;
}
.white-lable .white-lable-banner img {
 
 
  float: unset !important;
  margin: 20px auto;
  display: block;
}
  }
  
 
 

  @media only screen and (min-width: 768px) and (max-width: 992px){
    .white-lable section.getmt5.mt-4.pb-5 .d-flex.justify-content-center.getsbtn.mt-5 {
        justify-content: center !important;
    }
 
    .white-lable .image-partner {
      padding-top: 20px;
      margin: 0 auto;
      display: block;
  }
    .white-lable .getsmt5 {
      text-align: center;
  }
  }
  
  @media only screen and (min-width: 769px) and (max-width: 1400px){
    .white-lable .getimg img.img-fluid {
      position: relative;
      right: unset !important;
     }
  }
  
  @media screen and (max-width: 1020px){
  
    .white-lable .box1get {
      margin: 0 auto;
      display: block;
      text-align: center;
    }
  }
  
  
  
  
  
  
  .white-lable .line-one strong{
    font-size: 30px;
    color: #f18c20;
  }
  
  .white-lable  .line-two strong {
    font-size: 30px;
    color: #f18c20;
}
  
  
.white-lable  .form-check{
  display: flex;
} 
  
.whit-banner-img {
  position: relative;
  top: 35px;
}
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  