.mt5cont {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 350px;
  /* padding-top: 86px; */
  background-color: #fcfcfc;
  background-image: url(../images/bgimgmt5.png);
}
.mt5cont img.img-fluid {
  width: 100%;
  max-width: 455px;
  float: right;
  height: 100%;
}
section.mt5trade {
  padding-top: 0;
  padding-bottom: 15px;
}
.mt5cont p {
  color: gray;
}

button.btn.getmeta5 {
  background-color: #f18c20;
  width: 100%;
  max-width: 200px;
  color: white;
  font-size: 18px;
  font-weight: 600;
}
button.btn.getmeta5:hover {
  color: white !important;
}

section.milliva-trading-content {
  padding-top: 0;
  padding-bottom: 30px !important;
}

.trade-conclusion1.d-flex img {
  width: 100%;
  max-width: 35px;
}
.milli-trade1.mt-5 {
  margin: 0 !important;
}
.trade-conclusion1.d-flex.justify-content-between span {
  padding: 0 5px;
  color: black;
  font-size: 14px;
}
.milli-trade-image.mt-3 {
  margin: 0 !important;
}

.trade-conclusion1.d-flex.justify-content-between {
  background: white;
  padding: 30px;
  padding-top: 30px;
  padding-right: 30px;
  padding-bottom: 20px;
  padding-left: 30px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px #ececec;
  margin: 0 !important;
}

section.trading-conclusion {
  padding-top: 10px !important;
  padding-bottom: 0px !important;
}

section.milliva-trading-work {
  padding: 0;
  margin: 0 !important;
}

.milli-trade1 i {
  padding: 3px 15px;
  color: #f18c20;
  font-size: 15px;
}

.milli-trade1 small {
  text-indent: 0px;
}

.milli-trade1 p {
  display: flex;
}
.work-content2 i {
  padding: 3px 15px;
  color: #f18c20;
  font-size: 15px;
}

.work-content2 small {
  text-indent: 0px;
}

.work-content2 p {
  display: flex;
}

.milliva-trading-work i {
  padding: 3px 15px;
  color: #f18c20;
  font-size: 15px;
}

.milliva-trading-work small {
  text-indent: 0px;
  /* display: flex; */
}

.milliva-trading-work p {
  display: flex;
}

.working-image1 img {
  width: 100%;
}

.exchange-rates1.mt-3.py-3.text-center p small {
  font-size: 16px;
}

section.exchange-page {
  padding: 0;
}

.rates2.d-flex img {
  width: 60px;
}

.exchange-rates1.mt-3.py-3.text-center {
  background: white;
  border-radius: 4px;
  box-shadow: 0px 0px 10px 0px #ececec;
  margin: 50px 0 !important;
}

.exchange-rates1.mt-3.text-center {
  padding: 30px;
  background: white;
  box-shadow: 0px 0px 10px 0px #ececec;
  border-radius: 4px;
}

.rate1 img {
  width: 100%;
  max-width: 60px;
}

.rate3.d-flex.align-items-center img {
  width: 100%;
  max-width: 60px;
}

.rate5.d-flex.align-items-center img {
  width: 100%;
  max-width: 60px;
}
.rate-5.mt-3 {
  padding: 0 20px;
}
.real1.d-flex.align-items-center img {
  width: 100%;
  max-width: 60px;
}
.real.d-flex.align-items-center img {
  width: 100%;
  max-width: 60px;
}
.earn-2.mt-3.py-3.text-center img {
  width: 100%;
  max-width: 60px;
}

.earn-2.mt-3.py-3.text-center .earn-image2 {
  width: 100px !important;
  padding: 20px;
}

.earn-2.mt-3.py-3.text-center .earn-image0 {
  width: 100px !important;
  padding: 20px;
}

.earn-2.mt-3.py-3.text-center .earn-image1 {
  width: 100px !important;
  padding: 13px;
}

.earn-2.mt-3.py-3.text-center .earn-image3 {
  width: 100px !important;
  padding: 24px;
}

.earn-2.mt-3.py-3.text-center .earn-image4 {
  width: 100px !important;
  padding: 18px;
}

.earn-2.mt-3.py-3.text-center .earn-image5 {
  width: 100px !important;
  padding: 15px;
}

.earn-2 {
  background: white;
  border-radius: 4px;
  box-shadow: 0px 0px 10px 0px #ececec;

  min-height: 50px;
  height: 100%;
  padding: 10px 10px;
}

.earn-2.cn1 {
  min-height: 210px;
  height: 100%;
}
.strategy-image img {
  border-radius: 10px;
}
.novoice-trader2 img {
  width: 100%;
  margin: 0 auto;
  max-width: 60px;
}

.novoice-trader2 .card.text-center.mt-4.p-3 {
  height: auto;
  min-height: 228px;
  border: 2px solid #fbbc05;
}

.novoice-trader2 .card.text-center.mt-4.p-3:hover {
  background: rgb(245, 245, 245);
}
.work-path.mt-5.pt-5 {
  margin: 0 !important;
  padding: 10px 0px !important;
}
.work-content2.mt-5 {
  margin: 0 !important;
}
.novoice-trader2 p {
  font-size: 20px;
}

.limit-deposit-image img {
  border-radius: 10px;
  width: 100%;
  max-width: 500px;
}
.work-image1 {
  max-width: 60px;
}

.work-image2 {
  width: 100%;
  max-width: 60px;
}

.work-image3 {
  max-width: 60px;
}

.work-image4 {
  width: 100%;
  max-width: 60px;
}

.work-image5 {
  max-width: 60px;
  padding: 0 !important;
}

.work-image6 {
  max-width: 60px;
}

.exchange-rates i {
  color: #f18c20;
  font-size: 15px;
}

.exchange-rates small {
  text-indent: 0px;
  /* display: flex; */
}

.exchange-rates p {
  display: flex;
}

.exchange-rates.mt-3 h2 {
  padding: 10px 10px;
}

.more-currency i {
  color: #f18c20;
  font-size: 15px;
}

.more-currency small {
  text-indent: 0px;
  /* display: flex; */
}

.more-currency p {
  display: flex;
}

.miliva-earn i {
  color: #f18c20;
  font-size: 15px;
}

.miliva-earn small {
  text-indent: 0px;
  /* display: flex; */
}

.miliva-earn p {
  display: flex;
}

.miliva-earn h2 {
  padding: 10px 15px;
}

.efficiency i {
  color: #f18c20;
  font-size: 15px;
}

.efficiency small {
  text-indent: 0px;
  /* display: flex; */
}

.efficiency p {
  display: flex;
}

.trade-knowledge i {
  color: #f18c20;
  font-size: 15px;
}

.trade-knowledge small {
  text-indent: 0px;
  /* display: flex; */
}

.trade-knowledge p {
  display: flex;
}

.strategy-loss i {
  color: #f18c20;
  font-size: 15px;
}

.strategy-loss small {
  text-indent: 0px;
  /* display: flex; */
}

.strategy-loss p {
  display: flex;
}

.strategy-loss h4 {
  padding: 10px 15px;
}

.novoice-traders i {
  color: #f18c20;
  font-size: 15px;
}

.novoice-traders small {
  text-indent: 0px;
  /* display: flex; */
}

.novoice-traders p {
  display: flex;
}

.novoice-traders h4 {
  padding: 10px 15px;
}

.limit-deposit i {
  color: #f18c20;
  font-size: 15px;
}

.limit-deposit small {
  text-indent: 0px;
  /* display: flex; */
}

.limit-deposit p {
  display: flex;
}

.limit-deposit h4 {
  padding: 10px 15px;
}

.limit-content i {
  color: #f18c20;
  font-size: 15px;
}

.limit-content small {
  text-indent: 0px;
  /* display: flex; */
}

.limit-content p {
  display: flex;
}

.trad-works i {
  color: #f18c20;
  font-size: 15px;
}

.trad-works small {
  text-indent: 0px;
  /* display: flex; */
}

.trad-works p {
  display: flex;
}

.trad-works h2 {
  padding: 10px 10px;
}

.real-deposit h4 {
  padding: 0 17px;
}

.trade-theory i {
  color: #f18c20;
  font-size: 15px;
}

.trade-theory small {
  text-indent: 0px;
  /* display: flex; */
}

.trade-theory p {
  display: flex;
}

.trade-conclusion i {
  color: #f18c20;
  font-size: 15px;
}

.trade-conclusion small {
  text-indent: 0px;
  /* display: flex; */
}

.trade-conclusion p {
  display: flex;
}

.trade-conclusion h2 {
  padding: 10px 10px;
}

/*yuva style get started */

.trade .box1get h4 {
  font-size: 17px;
  font-weight: 600;
}

.trade .box1get p {
  color: gray;
}

.trade .getsbtn {
  gap: 104px;
}

.trade .getimg img.img-fluid {
  position: relative;
  right: -65px;
}

.trade .openlivebtn button.btn.btn-primary {
  background-color: #f18c20;
  border: none;
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

.trade .opendemobtn button.btn.btn-primary {
  background-color: #909090;
  border: none;
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

.trade .box1get img.img-fluid {
  max-width: 50px;
  max-height: 50px;
  width: 100%;
}

section.trade.getmt5.mt-4.pb-5 {
  padding: 0;
}

/*get started end */

.trade-product3 .marketanlysec4 i.fa-brands.fa-btc {
  color: orange;
}

.trade-product3 .traditems img.img-fluid {
  width: 100%;
  max-width: 25px;
}

.trade-product3 .tradingins li {
  list-style: none;
  font-size: 14px;
  padding: 12px 0;
  text-indent: -41px;
  white-space: break-spaces;
}
.trade-product3 .btcnew {
  color: #f18c20;
}
.trade-product3 .tradingins p {
  color: gray;
  font-size: 14px;
}

.trade-product3 .marketanlysec4 table tr td {
  padding: 2px 16px !important;
  text-align: center;
  border-bottom: 1px solid rgb(215, 214, 214) !important;
  font-size: 12px;
}

.trade-product3 .exchnage1 h6 {
  font-size: 14px;
}
section.milliva-trading-work-box {
  padding-top: 0;
  padding-bottom: 10px;
}

@media only screen and (max-width: 992px) {
  .trade-conclusion1.d-flex img {
    width: 20%;
    align-items: center;
    display: flex;
    margin: 10px auto;
  }
  .condiprofit .col-lg-4.col-md-12.col-12.col-sm-12 {
    margin-top: 20px;
  }
  .earn-2 {
    height: 100% !important;
    min-height: 100px !important;
  }
  .work-content2 i {
    padding: unset;
  }
  .trading-value h2 {
    text-align: center;
  }
  .trade-conclusion1.d-flex.justify-content-between.mt-4 {
    display: block !important;
    text-align: center !important;
  }
  .trade-product3 .marketanlysec4.mt-4 table {
    text-align: center !important;
  }
  .work-content1 {
    margin: 15px 0;
  }
  .rate3.d-flex.align-items-center img {
    margin: 0 auto;
  }
  .real-deposit1.d-flex {
    padding: 0 !important;
    display: block !important;
  }
  .real1.d-flex.align-items-center img {
    margin: 0 auto;
  }
  .real.d-flex.align-items-center img {
    margin: 0 auto;
  }
  .rate-market2.d-flex {
    display: block !important;
  }
  .rate1.d-flex.align-items-center {
    text-align: center;
  }
  .real2.mt-3.px-3 {
    padding: 0 !important;
    text-align: center;
  }
  .trade-work1.d-flex {
    display: block !important;
  }
  .trade-work2.d-flex.align-items-center img {
    margin: 0 auto;
  }
  .trade-work3.mt-3.px-3 {
    padding: 0 !important;
    text-align: center;
  }
  .limit-deposit-image.mt-3 img {
    border-radius: 10px;
    width: 100%;
  }
  .rate1 img {
    width: 100%;
    margin: 0 auto;
  }
  .rate5.d-flex.align-items-center img {
    width: 100%;
    margin: 0 auto;
  }
  .rate-market2.d-flex {
    display: block !important;
    text-align: center;
  }
  .rate-market.mt-3 {
    text-align: center;
  }
  .trade-wrok1.d-flex {
    display: block !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1050px) {
  /* .trade-product3 .d-flex.exchnage.justify-content-between {
=======

.riskfact .col-lg-4.col-md-12.col-12.col-sm-12{
    margin-top: 20px;
}

}
@media only screen and (min-width: 768px) and (max-width: 1050px){
    /* .trade-product3 .d-flex.exchnage.justify-content-between {
>>>>>>> 2bbb1a28f986a10cbdd20c7c9362fc85af5990f7
        display: block !important;
    } */
  .trade-conclusion1.d-flex.justify-content-between.mt-4 {
    display: flex !important;
    text-align: start !important;
  }
  .trade-conclusion1.d-flex.justify-content-between span {
    text-indent: 0;
    align-items: center;
    display: flex;
  }
  .trade-conclusion1.d-flex.justify-content-between.mt-4 a {
    display: flex;
  }
  .trade-conclusion1.d-flex img {
    width: 50px;
    padding: 10px;
  }
}

@media only screen and (max-width: 768px) {
  section.trade.getmt5.mt-4.pb-5 .box1get {
    text-align: center;
  }
  .trade .d-flex.justify-content-center.getsbtn.mt-5 {
    display: block !important;
    margin: 10px 0;
  }
  section.trade.getmt5.mt-4.pb-5 .openlivebtn {
    margin: 10px 0;
  }
  section.trade.getmt5.mt-4.pb-5 .getimg {
    text-align: center;
  }
  section.trade.getmt5.mt-4.pb-5 .getimg img.img-fluid {
    position: relative;
    right: 0px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  section.trade.getmt5.mt-4.pb-5 .d-flex.justify-content-center.getsbtn.mt-5 {
    justify-content: center !important;
  }
  section.trade.getmt5.mt-4.pb-5 .box1get {
    text-align: center;
  }
}
.trade .opendemobtn button.btn.btn-primary:hover {
  background-color: #909090 !important;
}

@media only screen and (max-width: 992px) {
  section.milliva-trading-work
    .col-lg-6.col-md-12.col-12.col-sm-12.d-flex.align-items-center {
    display: block !important;
    text-align: center;
  }
  .strategy-image {
    margin: 0 auto !important;
    text-align: center;
  }
  .strategy-image img {
    border-radius: 10px;
    width: 100%;
  }
  .limit-deposit-image.mt-3 img {
    border-radius: 10px;
    width: 100%;
  }
  .work-image3 {
    width: 100%;
  }
}

.trading-value p {
  color: gray;
}
.trading-value small {
  color: gray;
}
.earn-2 img {
  width: 100%;
  max-width: 60px;
}


.milli-trade-image img.img-fluid {
  width: 100%;
  max-width: 500px;
}
.strategy-1 img {
  width: 100%;
  max-width: 60px;
}
.working-image1 img {
  width: 100%;
  max-width: 250px;
}