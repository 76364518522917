
/* ============================Pamm trade============================== */
.Pamm-trade h5{
  font-size: 17px;
    font-weight: 600;
}
.Pamm-trade-banner .img-fluid {
  width: 100%;
  height: auto;
  max-width: 455px;
}
.Pamm-trade p {
    color: gray;
   
  }
 .Pamm-trade .Pamm-trade-banner {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 350px;
    padding-top: 86px;
    background-color: #fcfcfc;
    background-image: url(../images/bgimgmt5.png);
  }
  .Pamm-trade .Pamm-trade-banner img.img-fluid {
    width: 100%;
    float: right;
    max-width: 455px;
}
  
.Pamm-trade .Pamm-trade-banner p {
  color: gray;
  font-size: 18px;
}
 .Pamm-trade button.btn.getmeta5 {
    background-color:#f29531;
    width: 100%;
    max-width: 200px;
    color: white;

    font-weight: 600;
  }

  /* ===============================what is pamm================================== */
  .Pamm-trade .Pamm-trade-banner p {
    padding-left: 17px;
}
  .Pamm-trade .what-is-pamm {
    /* padding-top: 50px; */
    padding-bottom: 30px;
    text-align: center;
}
.Pamm-trade .Pamm-image img {
  width: 50px;
}
.Pamm-trade .why-pamm h2{
  padding-top: 20px;

  padding-bottom: 40px;
}
.Pamm-trade .Pamm-image.mt-2 {
  padding-right: 10px;
  max-width: 60px;
  width: 100%;
}
.what-is-pamm img {
  width: 100%;
  max-width: 500px;
  float: right;

}
/* ===================================why choose us=================== */
.Pamm-trade .why-choose.card {
  height: 100%;
  padding: 15px;
  min-height: 150px;
}
   
.Pamm-trade .how-does img {
  width: 100%;
  max-width: 500px;
 
}

/*  =============================reasons============================== */
.Pamm-trade .reasons-image {
  /* width: 80px; */
  height: 80px;
  border-radius: 50%;
  padding: 9px;
  text-align: center;

}
.Pamm-trade .reasons-image img {
  width: 100%;
  max-width: 60px;
  /* margin-top: -25px; */
}
.Pamm-trade .resons-text p {

  text-align: center;
}
.Pamm-trade .reasons {

  background: #fcfcfc;
}
.Pamm-trade .reasons.card {
  padding: 20px;
  border: unset;
}
.Pamm-trade h2.text-center.mt-5 {
  padding-top: 35px;
  padding-bottom: 10px;
}


/* ==========================join pamm=============================== */
.Pamm-trade .join-pamm {
  
  padding-bottom: 40px;
}

.Pamm-trade .join-pamm button.btn.getmeta5 {
  background-color:#f29531;
  width: 100%;
  max-width: 200px;
  color: white;
  display: block;
  font-size: 18px;
  font-weight: 600;
  margin: 0 auto;
}


.Pamm-trade .join-pamm img {
  margin: 0 auto;
  display: block;
  width: 100%;
  max-width: 60px;
}
.Pamm-trade .join-our-pamm {
  text-align: center;
  margin-top: 20px;
}

/* ==========================get-started media=========================== */

@media only screen and (max-width: 768px){
 .Pamm-trade section.getmt5.mt-4.pb-5 .box1get {
         text-align: center;
     }
     section.Pamm-trade {
      text-align: center;
  }
 
 .Pamm-trade .d-flex.justify-content-center.getsbtn.mt-5 {
         display: block !important;
         margin: 10px 0;
     }
 .Pamm-trade section.getmt5.mt-4.pb-5 .openlivebtn {
         margin: 10px 0;
     }
 .Pamm-trade section.getmt5.mt-4.pb-5 .getimg {
         text-align: center;
     }
 .Pamm-trade section.getmt5.mt-4.pb-5 .getimg img.img-fluid {
         position: relative;
         right: 0px !important;
     }

   }
   @media only screen and (max-width: 992px){
    section.Pamm-trade {
      text-align: center;
  }
  .Pamm-trade .Pamm-image.mt-2 {
    max-width: 60px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 10px;
}
.Pamm-trade .Pamm-trade-banner img.img-fluid {

  float: unset !important;

}
   }

   
   
   @media only screen and (min-width: 768px) and (max-width: 992px){
 .Pamm-trade section.getmt5.mt-4.pb-5 .d-flex.justify-content-center.getsbtn.mt-5 {
         justify-content: center !important;
     }
     .Pamm-trade .d-flex {
      display: unset !important;
    
  }
  .what-is-pamm img {
    float: unset;
 
}
  .Pamm-trade .getsmt5 {
    text-align: center;
}


.Pamm-trade .reasons.card {
    border-radius: 20px;
    padding: 20px;
    margin-top: 20px;
    /* height: 80%; */
}

}
