
/* -------------------------------choose-------------------- */
.why.choose.us .choosebanner {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 350px;
    padding-top: 86px;
    background-color: #fcfcfc;
    background-image: url(../images/bgimgmt5.png);
    }
    .why.choose.us .choosebanner img.img-fluid {
        width: 100%;
        max-width: 356px;
        float: right;
        height: 100%;
        /* margin-right: 118px; */
        /* margin-top: -28px; */
    }
    .why.choose.us  .choosebanner p{
    color: gray;
    font-size: 18px;
    }
    .why.choose.us button.btn.getmeta5 {
    background-color: #f29531;
    width: 100%;
    max-width: 200px;
    color: white;
    font-size: 18px;
    font-weight: 600;
    }
    .why.choose.us .iconpage {
        line-height: 5rem;
    }
    .why.choose.us .choose-text.text-center p {
        color: gray;
    }
    .why.choose.us section.Benifit img {
        width: 56%;
        margin-top: 10px;
    }
    .why.choose.us  section.Benifit h4 {
        font-size:14px;
        margin-top: 31px;
    }
    .why.choose.us  section.Benifit.text-center.mt-4 {
        background: #fafafa;
        padding-top: 39px;
        padding-bottom: 37px;
    }
    .why.choose.us .deposit-images img {
        width: 100%;
        max-width: 237px;
        margin-left: 31px;
    }
    .why.choose.us .withdrawl_text.mt-5 {
        padding-top: 51px;
    }
    .why.choose.us .winningimage img {
        margin: 0 auto;
        display: flex;
        padding: 20px;
    }
    .why.choose.us section.award {
        margin-top: 43px;
        /* margin-bottom: 46px; */
        background: #fafafa;
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .why.choose.us button.btn.btn-yellow {
        background: #f29531;
        color: white;
    }
    .why.choose.us button.btn.btn-blue.mx-4{
        background: #4285f4;
        color: white;
    }
    .why.choose.us .hovercard {
        padding: 10px;
        height: 100%;
    }
    .why.choose.us .hovercard:hover {
        background: #fafafa;
        /* padding: 5px; */
    }
    .why.choose.us section.support img {
        width: 100%;
        max-width: 60px;
    }
    .why.choose.us .pamm.mt-4 img {
        width: 100%;
        max-width: 60px;
    }
  
  /* .why.choose.us  .pamm.mt-4 h5 {
    margin-left: -27px;
} */
.why.choose.us  section.Benefits {
  background: #fafafa;
  padding-top: 6px;
  padding-bottom: 20px;
}
.why.choose.us .pamm.mt-4 p {
    text-align: justify;
    color: gray;
}

.why.choose.us  h5 {
    font-size: 17px;
    font-weight: 600;
}
.customer p{
    color: gray;
    
}
.award p{
    color: gray;
}
.why.choose.us .openlivebtn button.btn.btn-primary {
    background-color: #f29531;

    border: none;
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
  }
  .why.choose.us .opendemobtn button.btn.btn-primary:hover {
    background: #909090 !important;
}
  .why.choose.us .opendemobtn button.btn.btn-primary {
    background-color: #909090;
    border: none;
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
  }
  .why.choose.us .getsbtn {
    gap: 104px;
  }
  
@media (max-width: 768px) {
    .why.choose.us .choosebanner img.img-fluid{
        /* margin-right: unset !important; */
        margin-top: unset !important;
    }
    .why.choose.us .getsbtn {
        display: unset !important;
       
    }
    .why.choose.us .opendemobtn {
        margin-top: 12px;
    }
    .why.choose.us .openlivebtn {
        margin-top: 22px;
    }
    .why.choose.us .winningimage img {
        margin: 13px auto;
 
    }
}
.box{width:200px; height:50px; background: #ccc; margin: 0 auto; text-align:center; line-height:50px;}
.fadeInUp{animation:fadeInUp 2s ease backwards;}
@keyframes fadeInUp{
  0%{transform:translate(0px, 100px); opacity: 0;}
  100%{transform:translate(0px, 0); opacity: 1;}
}

/* -------------------------------------------------------------- */
@media only screen and (max-width: 992px) {
.why.choose.us {
    text-align: center;
}
.why.choose.us button.btn.getmeta5{
    display: block;
    margin: 0 auto;
}
.why.choose.us .pamm.mt-4 p {
    text-align: unset;
}
.why.choose.us .pamm.mt-4 h5 {
    text-align: center;
}
.why.choose.us .pamm.d-flex {
    display: unset !important;
}
.why.choose.us .fidlies.d-flex{
    display: unset !important;
}
.why.choose.us .choosebanner img.img-fluid{
    float: unset;
}
}


.why-banner-img img {
    max-width: 100%  !important;
}

