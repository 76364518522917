/* ============================Copy trade============================== */
.Copy-trade h5 {
  font-size: 17px;
}
.copy-trade-banner.img-fluid {
  width: 100%;
  height: auto;
  max-width: 455px;
}
.Copy-trade p {
  color: grey;
}
.start-copy p {
  text-align: inherit;
}
.Copy-trade .Professional-image img {
  width: 100%;
  max-width: 500px;
  float: right;
}

.copy-trade-banner img {
  max-width: 455px;
  height: auto;
  width: 100%;
  margin-top: 25px;
  float: right;
}

.Copy-trade .copy-trade-banner {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 350px;
  padding-top: 86px;
  background-color: #fcfcfc;
  background-image: url(../images/bgimgmt5.png);
}

.copy-trade-banner p {
  padding-left: 22px;
  
}

.Copy-trade .copy-trade-banner p {
  color: gray;
  font-size: 18px;
}

.Copy-trade .copy-trade-banner button.btn.getmeta5 {
  background-color: #f29531;
  width: 100%;
  max-width: 293px;
  color: white;
  font-size: 10px;
  font-weight: 600;
  margin-right: 7px;
}

.Copy-trade .banner-buttons {
  display: inline-flex;
  margin: 0 auto;
  float: right;
  margin-right: -12px;

}

/* .Copy-trade .banner-buttons button.btn.getmeta5 {
  margin-top: 10px;
} */

/* ==============================trade-market===================== */
.Copy-trade .Trade-market {
  margin-top: 10px;
  padding: 20px;
}

/* ===========================how-start============================ */
.Copy-trade .How-start {
  background: #fbfbfb;;
  color: black;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 30px;

}


.Copy-trade .How-start h5 {
  margin-bottom: 20px;
}

/* ==============================start-copy======================== */
.Copy-trade .start-copy {
  /* background-image: url(../img/partner1.png); */
  width: 100%;
  height: 100%;
  min-height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.Copy-trade .start-copy {
  padding-top: 50px;
  color: black;
  text-align: initial;
}

/* =============================why-join======================== */
.Copy-trade .why-join {
  margin-top: 25px;
}

.Copy-trade .why-join h2 {
  margin-bottom: 15px;
  margin-top: 20px;
}


/*  =============================Key features===============================  */
.Copy-trade .Key-features {
  padding-bottom: 10px;
  background: #fbfbfb;
  padding-top: 50px;
  margin-top: 20px;
}
.Copy-trade .key-img img {
  display: block;
  margin: 0 auto;
  padding-bottom: 15px;
}

.Copy-trade .key-img img {
  width: 100%;
  max-width: 60px;
}

/* =======================get startted==================== */
.Copy-trade .copy-trade {
  padding-top: 40px;
  padding-bottom: 40px;
}

.Copy-trade .copy-trade button.btn.getmeta5 {
  background-color:#f29531;
  width: 100%;
  max-width: 200px;
  color: white;
  display: block;
  font-size: 18px;
  font-weight: 600;
  margin: 0 auto;
}
  /* =======================================card style======================================= */
  .Copy-trade .service-fold {
  position: relative;
}
.Copy-trade .service-box img.img-fluid{
  width:100%;
  max-width: 60px;
}

.Copy-trade .service-box h5 {
  text-transform: none;

  padding: 20px;
  text-align: center;
  font-weight: 600;
  font-size: 17px;
  margin: 0;
  /* white-space: nowrap; */
}
.Copy-trade .service-box p {
  color: gray;
}
.Copy-trade .service-box {
  height: 100%;
  padding: 20px;
  margin: 0;
  background-position: 100% -20px, 20px 100%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px 0px;
  background-color: white;
  color: #000;
  text-align: center;
  display: block;
  min-height: 250px;
}

.Copy-trade .service-fold:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0;
  border-top: 20px solid rgb(66 133 244);
  border-left: 20px solid transparent;
  border-top: 20px solid rgb(241 140 32);
}

.Copy-trade .service-fold:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 20px;
  width: 0;
  height: 0;
  border-bottom: 20px solid rgba(0, 0, 0, .15);
  border-right: 20px solid transparent;
}

.Copy-trade .copy-trade img {
  margin: 0 auto;
  display: block;
  width: 100%;
  max-width: 60px;
}
.Copy-trade .join-our-copy {
  text-align: center;
  margin-top: 20px;
}
.join-copy-trade {
  text-align: center;
  margin-top: 20px;
  /* padding-top: 20px; */
  margin-bottom: 50px;
}
/* ==========================get-started media=========================== */

@media only screen and (max-width: 768px) {

  section.Copy-trade {
    text-align: center !important;
  }

  .copy-trade-banner img {
    margin-left: unset !important;
  }
}

@media only screen  and (max-width: 992px) {
section.Copy-trade {
  text-align: center;
}
.How-start .col-lg-4.col-md-4.col-sm-4 {
  margin-top: 10px;
}
.Copy-trade .service-box h5{
  white-space: unset  !important;
}

.Copy-trade .copy-trade-banner button.btn.getmeta5{
  height: 50px;
}
.Copy-trade .start-copy {

  text-align: center !important;
}
.copy-trade-banner img {

  float: unset;
}
.Copy-trade .banner-buttons {
  float: unset !important;

}
.Copy-trade .Professional-image img {
  float: unset;
  margin: 0 auto;
  display: block;
}
.How-start .col-lg-4.col-md-4.col-sm-12 {
  margin-top: 20px;
}
.Copy-trade .How-start .col-lg-4.col-md-12.col-sm-12 {
  margin-top: 20px;
}
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .Copy-trade section.getmt5.mt-4.pb-5 .d-flex.justify-content-center.getsbtn.mt-5 {
    justify-content: center !important;
  }
   .Copy-trade .getsmt5 {
    text-align: center;
}
}
@media only screen and (min-width: 769px) and (max-width: 2000px){
  .Copy-trade .getimg img.img-fluid {
    position: relative;
    right: unset !important;
   }
}

@media screen and (max-width: 1020px){

  .Copy-trade .box1get {
    margin: 0 auto;
    display: block;
    text-align: center;
  }
}

.join-copy-trade img.img-fluid {
  width: 100%;
  max-width: 60px;
}