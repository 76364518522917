
.open-account-path1.row {

    border-radius: 4px;
    /* padding: 30px; */
}
.open-account-path1.row .card.open-demo-form.p-3 {
    width: 55%;
    margin: 0 auto;
    background: #fafafa;
    border-radius: 4px !important;
    border: 1px solid #d8d8d8;

}
section.demo-page-content {
    background: url(../images/mani/map1.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.open-account.mt-3 button {
    background: #f18c20;
    border: none;
    height: 40px;
}
.open-account.mt-3 button:hover{
    color: white !important;
}
.open-account.mt-3 button:focus{
    background: #f18c20 !important;
}
.open-demo-form input:focus {
    box-shadow: none;
    border: 1px solid #f18c20;
}
.open-demo-form select:focus {
    box-shadow: none;
    border: 1px solid #f18c20;
}
.open-demo-form h2 {
    text-align: center;
    padding: 5px;
}


section.demo-page-content {
    padding-bottom: 50px !important;
    padding-top: 0 !important;
    /* background: url(../img/Open-demo-images/back2.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; */
}

@media only screen and (max-width: 768px){
section.demo-page-content .card.open-demo-form.p-3 {
    width: 100% !important;
}
.live-page1.mt-5.py-3 {
    width: 100%;
}
}



