.intorbrobanner {
    padding-top: 86px;
}
.inbrosub p {
    text-align: justify;
    color: gray;
   
    /* width: 80%; */
    margin: 0 auto;
    display: block;
}

.inbrosubicons img.img-fluid {
    width: 100%;
    max-width: 60px;
}
.inbrosubicons p {
   
    font-weight: 500;
}
.iconintro1{
    border-right: 2px solid #d7d1d1;
}

.intsubwk p {
  
    color: gray;
    text-align: justify;
}
.subbrokerdesign p{
   
    color: gray;
    /* text-align: justify; */
    word-wrap: break-word;
    /* width: 86%; */
}

ul.partnerslist li {
    list-style: none;
    line-height: 75px;
}

.partnerslist img.img-fluid {
    width: 100%;
    max-width: 60px;
}

.seckey1 span {
    display: block;
    font-size: 17px;
    font-weight: 500;
    color: #000;
}
.seckey1 {
    margin: 0 auto;
    width: 25%;
}

.keybene{
    background-color: #fafafa;
    padding: 20px;
   
}

.seckey1 span strong{
    font-weight: 600;
    color: #f18c20;
    font-size: 35px;
} 
.ibvintage p{
    color: gray;
    
    text-align: justify;
}
.becomeibinsub p {
    
    color: gray;
    
    text-align: left;
    overflow-wrap: break-word;
    width: 90%;
}

.sign1{
  gap:13px;  
}


section.insbrkey.mt-5 .container-fluid {
    padding-left: 0;
    padding-right: 0;
}

.becom1 img.img-fluid {
    max-width: 60px;
 /* width: 100%;
     */
}
button.btn.btn-become {
    background-color: #f18c20;
    color: white;
    font-size: 16px;
    font-weight: 600;
    
    text-align: center;
    text-transform: uppercase;
    border: none;
   
    width: 100%;
    margin: 0 auto;
    max-width: 235px;
    height: 44px;
    display: block;
}

button.btn.btn-become:focus{
    color: #fff;
}
.intsubwk img.img-fluid {
    width: 100%;
    max-width: 500px;
}

.ibvintage img.img-fluid {
    width: 100%;
    max-width: 455px;
    float: right;
    height: 100%;
}
/* 
bannersec */
.intorbrobanner {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 350px;
    padding-top: 86px;
    background-color: #fcfcfc;
      background-image: url(../images/bgimgmt5.png);
  }
  .intorbrobanner img.img-fluid {
    width: 100%;
    max-width:455px;
    float: right;
    height: 100%;
    object-fit: cover;
}
  .intorbrobanner p{
    color: gray;
    font-size: 18px;
  }
  button.btn.getmeta5 {
    background-color: #f18c20;
    width: 100%;
    max-width: 200px;
    color: white;
    font-size: 18px;
    font-weight: 600;
    border: none;
    height: 40px;
  }


  .parentsecli{
    margin: 0 auto;
    width: 70%;
}
  
  @media screen and (min-width: 769px) {
    
    ul.partnerslist li{
        white-space: nowrap;
    }
   
    
  }
  @media screen and (max-width: 425px) {
    .parentsecli {
      margin: unset;
      width: unset ;
    }
  }
  
  @media screen and (min-width: 992px){
    .marklis p{
        display: flex;
        align-items: center;
    }
  }


  @media screen and (max-width: 992px){
    .intorbrobanner{
        text-align:center;
    }
    .intorbrobanner p{
        text-align: center;
    }
    .introbroker h2{
text-align: center;
    }
    .introbroker p {
        text-align: center;
    }
    .subbrokerdesign p{
        width: unset !important;
    }
    .introbroker h4{
        text-align:center;
    }
    .intorbrobanner img.img-fluid {
        float: unset !important;
        margin: 0 auto;
        display: block;
       margin-top: 30px;
     }
     .ibvintage img.img-fluid{
        float: unset !important;
        margin: 0 auto;
        display: block;
     }
     
     .iconintro1{
        display: block !important;
        text-align: center;
     }
     .iconintro2{
        display: block !important;
        text-align: center;
     }

     .intsubwk img.img-fluid {
  float: unset !important;
  margin:  0 auto;
  display: block;
    }

    ul.partnerslist {
        padding-left: 0;
        margin-left: 0;
    }

    ul.partnerslist {
        padding-left: 0;
       
        margin-left: 0;
    }
    .seckey1 {
        margin: 11px auto;
        width: 100%;
        display: block;
        text-align: center;
    }
    .introbroker h5{
        text-align:center;
    }
    .becomeibinsub p{
        width: unset !important;
    }
    .sign1 {
     
        display: block !important;
        margin: 5px auto;
       
        text-align: center;
    }
    .marklis img {
       
        margin: 0 auto !important;
        display: block;
        text-align: center;
    }
    .marklis p {
    text-align: center;
  }
  .becom1 img.img-fluid {
   
    margin-bottom: 14px;
}
}
  .marklis p {
    
    border: 1px solid #dcdfe3;
    border-radius: 10px;
    padding: 10px 10px;
  
    
    height: 100%;
    min-height: 93px;
    
}

.marklisitem1 p{

    transition: all 0.25s;
}
.marklisitem1 p:hover{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    
}

.marklisitem1 :hover img{
    transition: all 0.65s;
    transform: rotateY(360deg);
}

.marklisitem2 p{

    transition: all 0.25s;
}
.marklisitem2 p:hover{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    
}

.marklisitem2 :hover img{
    transition: all 0.65s;
    transform: rotateY(360deg);
}
.marklis img{
    width: 100%;
    max-width: 60px;
}


  
@media only screen and (min-width: 992px) and (max-width: 1200px){
    .inbrosubicons p {
      
        font-size: 13px;
       
    }
}
@media only screen and (min-width: 768px) and (max-width: 992px){
    .parentsecli {
        margin: 0 auto;
        width: 40%;
    }
}

